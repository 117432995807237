import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {scPage} from '../../stockcars-utils-bundle';
import {patternSc} from '../pattern-matching/pattern-sc';
import {vehiclesController} from '../vehicles/vehicles-controller';
import {deepCopy} from '../../stockcars-utils/utils/object-utils';

export class BaseButtonLogic extends HTMLElement {
	constructor() {
		super();
	}

	async connectedCallback() {
		await this._initialize();
	}

	async _initialize() { // eslint-disable-line max-statements
		const data = {};
		data.id = BaseButtonLogic._getVehicleId(this.dataset);
		data.ctaSpecialLinks = deepCopy(SETUPS.get('stockcar.ctaSpecialLinks') || {});
		data.infoIconLinks = deepCopy(SETUPS.get('stockcar.infoIconLinks') || {});
		data.vehicleData = !!SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state).get(data.id) ? SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state).get(data.id) : await BaseButtonLogic._getVehicleData(data.id).catch((err) => {
			console.log(err);
			return {};
		});
		data.pdfUrl = BaseButtonLogic._getPdfUrl(data.vehicleData);
		data.phone = BaseButtonLogic._getPhoneNumber(data.vehicleData);
		data.dealerName = BaseButtonLogic._getDealerName(data.vehicleData);
		data.hasFinancing = BaseButtonLogic._hasFinancing(data.vehicleData);
		data.nws = BaseButtonLogic._isNwsVehicle(data.vehicleData);
		data.aoz = BaseButtonLogic._hasAozItems(data.vehicleData);
		data.leasing = BaseButtonLogic._isLeasingVehicle(data.vehicleData, data.ctaSpecialLinks);
		data.ecom = BaseButtonLogic._isEcomVehicle(data.vehicleData, data.ctaSpecialLinks);
		data.isv = BaseButtonLogic._isInvestorSharedVehicle(data.vehicleData);
		data.liveDemo = BaseButtonLogic._isLiveDemoVehicle(data.vehicleData);
		data.liteReservation = BaseButtonLogic._canBeLiteReservedVehicle(data.vehicleData, data.ctaSpecialLinks);
		data.reservation = BaseButtonLogic._canBeReservedVehicle(data.ctaSpecialLinks);
		this.data = data;
		await this._prePatchPatternUrl(this.data.ctaSpecialLinks);
		this.render();
	}

	/**
	 * pre patch pattern url
	 * @private
	 * @param {object} specialLinks_ - special links
	 * @returns {promise} promiseArray
	 */
	async _prePatchPatternUrl(specialLinks_ = {}) {
		const promisArray = [];
		Object.keys(specialLinks_).forEach(async key => {
			promisArray.push(this._patchPatternInUrl(key, specialLinks_));
		});
		return Promise.all(promisArray);
	}

	/**
	 * patch pattern in url
	 * @private
	 * @param {string} key_ - special links key
	 * @param {object} specialLinks_ - special links
	 * @returns {promise} promise of replaced pattern url
	 */
	async _patchPatternInUrl(key_ = '', specialLinks_ = {}) {
		return this._patchPatternInSingleUrl(specialLinks_[key_]);
	}

	/**
	 * patch pattern in url
	 * @private
	 * @param {object} specialLink - special links
	 * @returns {promise} promise of replaced pattern url
	 */
	async _patchPatternInSingleUrl(specialLink) {
		if (!specialLink.hasOwnProperty('patternUrl')) {
			return;
		}
		let patternUrl = specialLink.patternUrl;
		if (!patternUrl || patternUrl === '') {
			return;
		}
		if (patternUrl.includes('{{')) {
			let pattern = this._extractUrlPattern(patternUrl),
				promiseInner = [];
			pattern.forEach(patternKey => {
				promiseInner.push(patternSc._getPatternData(patternKey, this.data.id).then(replacedPatternKey => {
					specialLink.patternUrl = specialLink.patternUrl.replace('{{' + patternKey + '}}', replacedPatternKey);
				}));
			});
			return Promise.all(promiseInner);
		}
	}

	/**
	 * extract url pattern
	 * @private
	 * @param {string} url_ - pattern url
	 * @returns {array} pattern - array of url pattern
	 */
	_extractUrlPattern = function (url_) {
		let pattern = [];
		if (url_ && url_.indexOf('{{') > 0) {
			pattern = url_.match(/{{\s*(=)?[(\w|_)\.|:]+\s*}}/g).map(function (x) { // eslint-disable-line no-useless-escape
				return x.match(/(=)?[\w\.|:]+/)[0]; // eslint-disable-line no-useless-escape
			});
		}
		return pattern;
	};

	/**
	 * get vehicle id
	 * @private
	 * @param {object} dataset_ - dataset with html attributes
	 * @returns {string} vehicleId
	 */
	static _getVehicleId(dataset_) {
		let vehicleId = '';
		if (!!dataset_ && !!dataset_.vehicleId) {
			vehicleId = dataset_.vehicleId;
		}
		else {
			vehicleId = scPage.getVehicleIdFromUrl();
		}
		return vehicleId;
	}

	/**
	 * get vehicle data
	 * @private
	 * @param {string} id_ vehicleId
	 * @returns {object} vehicleData json
	 */
	static async _getVehicleData(id_) {
		try {
			return await vehiclesController.loadVehicleById(id_);
		}
		catch (err) {
			console.warn(err);
			throw err;
		}
	}

	/**
	 * get pdf url
	 * @private
	 * @param {object} vehicleData_ - data of vehicle
	 * @returns {string} url - return pdf url
	 */
	static _getPdfUrl(vehicleData_ = {}) {
		let url = '';
		if (vehicleData_ && vehicleData_.audiCode && SETUPS.get('nemo.pdf.url') !== '') {
			url = `${SETUPS.get('nemo.pdf.url')}/stck/${vehicleData_.audiCode}`;
		}
		return url;
	}

	/**
	 * get phone number
	 * @private
	 * @param {object} vehicleData_ - data of vehicle
	 * @returns {string} phone - dealer phone number
	 */
	static _getPhoneNumber(vehicleData_ = {}) {
		let phone_ = {};
		if (vehicleData_ && vehicleData_.dealer && vehicleData_.dealer.phoneNumbers) {
			let phoneObject = vehicleData_.dealer.phoneNumbers.find(phone => phone.type === 'phone');
			phone_ = !!phoneObject && phoneObject.number ? {
				'display': (window.i18n['sc.details.dealer-phone'] + ': ' + phoneObject.number),
				'number': phoneObject.number.replace(/ /g, '')
			} : {};
		}
		return phone_;
	}

	/**
	 * get dealer name
	 * @private
	 * @param {object} vehicleData_ - data of vehicle
	 * @returns {string} phone - dealer name
	 */
	static _getDealerName(vehicleData_ = {}) {
		return (vehicleData_.dealer && vehicleData_.dealer.name) ? vehicleData_.dealer.name : '';
	}

	/**
	 * has financing
	 * @private
	 * @param {object} vehicleData_ - data of vehicle
	 * @returns {Boolean} true || false
	 */
	static _hasFinancing(vehicleData_) {
		return (vehicleData_ && vehicleData_.financing && Object.keys(vehicleData_.financing).length > 0);
	}

	/**
	 * is NWS vehicle
	 * @private
	 * @param {object} vehicleData_ - data of vehicle
	 * @returns {Boolean} true || false
	 */
	static _isNwsVehicle(vehicleData_) {
		return (vehicleData_ && vehicleData_.nationWideSelling);
	}

	/**
	 * is live Demo vehicle
	 * @private
	 * @param {object} vehicleData_ - data of vehicle
	 * @returns {Boolean} true || false
	 */
	static _isLiveDemoVehicle(vehicleData_) {
		return (vehicleData_ && vehicleData_.liveConsulting);
	}

	/**
	 * is leasing vehicle
	 * @private
	 * @param {object} vehicleData_ - vehicle data
	 * @param {object} specialLinks_ - links
	 * @returns {Boolean} true || false
	 */
	static _isLeasingVehicle(vehicleData_, specialLinks_) {
		return (BaseButtonLogic._hasFinancing(vehicleData_) && vehicleData_.leasingCar && specialLinks_ && specialLinks_.leasing);
	}

	/**
	 * is ecom vehicle
	 * @private
	 * @param {object} vehicleData_ - vehicle data
	 * @param {object} specialLinks_ - links
	 * @returns {Boolean} true || false
	 */
	static _isEcomVehicle(vehicleData_, specialLinks_) {
		return (vehicleData_ && vehicleData_.buyableOnline === true && !SETUPS.get('stockcar.hide.ecom') && specialLinks_ && specialLinks_.ecom);
	}

	/**
	 * is investor shared vehicle
	 * @private
	 * @param {object} vehicleData_ - vehicle data
	 * @returns {Boolean} true || false
	 */
	static _isInvestorSharedVehicle(vehicleData_) {
		return (vehicleData_ && vehicleData_.investorSharedVehicle === true);
	}

	/**
	 * _canBeLiteReservedVehicle
	 * @private
	 * @static
	 * @param {object} vehicleData_ - vehicle data
	 * @param {object} specialLinks_ - links
	 * @returns {Boolean} true || false
	 */
	static _canBeLiteReservedVehicle(vehicleData_, specialLinks_) {
		const {dealer: {email = ''} = {}} = vehicleData_;
		const validEmail = this._validateEmail(email);
		return !!(!!validEmail && SETUPS.get('stockcar.show.reservation') && specialLinks_ && specialLinks_.liteReservation);
	}

	/**
	 * _canBeReservedVehicle
	 * @private
	 * @static
	 * @param {object} specialLinks_ - links
	 * @returns {boolean} true || false
	 */
	static _canBeReservedVehicle(specialLinks_) {
		return false;
	}

	/**
	 * _validateEmail
	 * @private
	 * @static
	 * @param {string} email_ - email string
	 * @returns {Boolean} true || false
	 */
	static _validateEmail(email_) {
		const re = /\S+@\S+\.\S+/;
		return re.test(String(email_).toLowerCase());
	}

	/**
	 * has aoz items
	 * @private
	 * @param {object} vehicleData_ - vehicle data
	 * @returns {Boolean} true || false
	 */
	static _hasAozItems(vehicleData_) {
		if (!!vehicleData_ && !!vehicleData_.id) {
			const vehicleAozState = SELECTORS.AOZ.getAddedProductsByVehicleId(STCK_STORE.state, vehicleData_.id);
			return !!vehicleAozState.length;
		}
		return false;
	}

	render() {
		console.warn('replace render() function');
	}
}

if (window.customElements.get('base-button-logic') === undefined) {
	window.customElements.define('base-button-logic', BaseButtonLogic);
}
