const exports = `
	{{? item.url && item.text}}
		{{? item.method === 'POST'}}
			<button class="sc-cta-button {{? item.primary}}audi-button{{??}}audi-button audi-button--ghost{{?}} js-modal-layer-link{{? item.css && item.css !== ''}} {{= item.css}}{{?}}"
					data-target-type="{{=item.target}}"
					data-layer-type="IframePostModalLayerElement"
					data-button-type="custom"
					data-url="{{=item.url}}"
					data-pattern-url="{{=item.patternUrl}}">
				<span class="audi-button__text sc-hyphenate">{{=item.text}}</span>
			</button>
		{{??}}
			{{? item.type === 'ecom-link'}}
				<a 	x-cq-linkchecker="skip"
					href="{{=item.url}}"
					data-pattern="{{=item.patternUrl}}"
					class="audi-link-s audi-link-s--arrow {{? item.css && item.css !== ''}} {{= item.css}}{{?}}"
					target="_blank"
					data-button-type="ecom">

						<span class="audi-link-s__text sc-hyphenate">{{=item.text}}</span>
						<svg class="audi-link-s__icon">
							<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
						</svg>
				</a>
			{{??}}
				<a 	x-cq-linkchecker="skip"
					href="{{=item.url}}"
					data-pattern="{{=item.patternUrl}}"
					class="sc-cta-button {{? item.primary}}audi-button{{??}}audi-button--ghost{{?}} {{? item.css && item.css !== ''}} {{= item.css}}{{?}}"
					target="_blank"
					data-button-type="ecom"
						<span class="audi-button__text sc-hyphenate">{{=item.text}}</span>
				</a>
			{{?}}
		{{?}}
	{{?}}
`;
export {exports as ecomButtonTemplate};
