export class PopoverTrigger extends HTMLElement {
	constructor() {
		super();
	}

	static get defaults() {
		return {
			popoverContentOpened: '.sc-popover-content--opened'
		};
	}

	async connectedCallback() {
		this._addEvents();
	}

	_addEvents() {
		this.addEventListener('click', PopoverTrigger._openPopoverContent.bind(this));
		document.addEventListener('click', this._closePopoverContents);
	}

	static _openPopoverContent(event) {
		const openedPopoverContents = document.querySelectorAll(PopoverTrigger.defaults.popoverContentOpened);
		openedPopoverContents.forEach((content) => {
			if (content !== this.nextElementSibling) {
				content.classList.remove('sc-popover-content--opened');
			}
		});
		this.nextElementSibling.classList.toggle('sc-popover-content--opened');
		event.stopPropagation();
	}

	_closePopoverContents() {
		const openedPopoverContents = document.querySelectorAll(PopoverTrigger.defaults.popoverContentOpened);
		openedPopoverContents.forEach((content) => content.classList.remove('sc-popover-content--opened'));
	}
}

if (window.customElements.get('popover-trigger') === undefined) {
	window.customElements.define('popover-trigger', PopoverTrigger);
}
