import {BaseButtonLogic} from './sc-base-button-logic';
import {template} from 'core-utils';
import {OBJECT_UTILS} from '../../stockcars-utils-bundle';
import {compareIsvInfoTemplate} from './templates/compare-isv-info-template';
import {dealerIsvInfoTemplate} from './templates/dealer-isv-info-template';
import {dealerNwsInfoTemplate} from './templates/dealer-nws-info-template';
import {compareNwsInfoTemplate} from './templates/compare-nws-info-template';

export class InfoButtonsElement extends BaseButtonLogic {
	constructor() {
		super();
	}

	_initializeData() {
		this.data.buttonType = this.dataset.buttonType;
		this.data.moduleType = this.dataset.moduleType;
	}

	render() {
		this._initializeData();
		const buttonData = this._getInfoButtonData(),
			templateData = this._getInfoButtonTemplate(buttonData);
		if (templateData !== '') {
			this.innerHTML = template.render(templateData, buttonData);
		}
	}

	_getInfoButtonTemplate(buttonData_) {
		if (!!buttonData_ && !!buttonData_.type) {
			switch (buttonData_.type) {
				case 'dealer-nws-info-i':
					return dealerNwsInfoTemplate;
				case 'dealer-isv-info-i':
					return dealerIsvInfoTemplate;
				case 'compare-nws-info-i':
					return compareNwsInfoTemplate;
				case 'compare-isv-info-i':
					return compareIsvInfoTemplate;
				default:
					return '';
			}
		}
		else {
			return '';
		}
	}

	_getButtonType() {
		return `${this.data.moduleType}-${this.data.buttonType}`;
	}

	_getInfoButtonData() {
		let infoIconLinks = OBJECT_UTILS.deepCopy(this.data.infoIconLinks),
			buttonData = {};

		if (this.data.buttonType.indexOf('nws') >= 0 && this.data.nws && infoIconLinks && infoIconLinks.nws) {
			buttonData = Object.assign(infoIconLinks.nws, {"infoText": window.i18n['sc.nationwideselling.linktext'] || ' ', 'type': this._getButtonType()});
		}
		else if (this.data.buttonType.indexOf('isv') >= 0 && this.data.isv && infoIconLinks.isv) {
			buttonData = Object.assign(infoIconLinks.isv, {'type': this._getButtonType()});
		}
		return buttonData;
	}
}
if (window.customElements.get('info-buttons-element') === undefined) {
	window.customElements.define('info-buttons-element', InfoButtonsElement);
}
