/* global SETUPS */
import {svdModel} from './svd-model';
import RequestController from './scs-request-controller';
import {content} from 'core-application';
import {scModelMandatoryAreaSearch} from '../presets/mandatory-area-search/model-mandatory-area-search';
import {FilterDTO} from '../../stockcars-filter-bundle';
import {STCK_STORE, SELECTORS, ACTIONS} from '@oneaudi/stck-store';
import {vehiclesController} from '../vehicles/vehicles-controller';

class ScsControllerClass {
	static get defaults() {
		return {
			INCREMENTAL_OFFSET: 12
		};
	}

	/**
	 * generateFilterRequestParam
	 * @param {Map} activeFilterItems_ active/selected filters map
	 * @param {Map} presetFiltersMap preselected filters Map
	 * @return {Map} filterParamObject Map with all 'filters' and 'presets' get params (filter =>'filterName,filterName2')
	 */
	_generateFilterRequestParam(activeFilterItems_ = new Map(), presetFiltersMap = new Map()) {
		const filterParams = [...activeFilterItems_.values()].map((filterDTO) => filterDTO.toString());
		const presetFilterParams = [...presetFiltersMap.values()].map((filterDTO) => filterDTO.toString());

		let filterRequestParam = new Map();
		if (presetFilterParams.length) {
			filterRequestParam.set('preset', presetFilterParams.join(','));
		}
		if (filterParams.length) {
			filterRequestParam.set('filter', filterParams.join(','));
		}

		return filterRequestParam;
	}

	/**
	 * handle response object
	 * @param {object} response raw fetch reponse object
	 * @returns {void} nothing
	 */
	_handleResponse(response = {}) {
		STCK_STORE.dispatch(ACTIONS.FILTERS.handleFilterResponse({response}));
	}

	/**
	 * append default partams (like size, from, to and sort
	 * @return {Map<string, string>} key value map with url params
	 */
	async _getDefaultRequestParamsMap() {
		const requestParamsMap = new Map();
		const svd = await svdModel.getSvdVersion();
		requestParamsMap.set('svd', svd);
		requestParamsMap.set('from', SELECTORS.UI.getResultIndex(STCK_STORE.state));
		requestParamsMap.set('size', ScsControllerClass.defaults.INCREMENTAL_OFFSET);
		const sortParam = STCK_STORE.state.sorting.results;
		requestParamsMap.set('sort', sortParam);
		return requestParamsMap;
	}

	/**
	 * fetchFilters
	 * @param {Map} filterParam_ get filter params
	 * @returns {Promise<void>} returns nothing
	 */
	async _fetchFilters(filterParam_ = new Map()) {
		const defaultsMap = await this._getDefaultRequestParamsMap();

		this.resetSortingIfNecessary(filterParam_);
		defaultsMap.set('sort', STCK_STORE.state.sorting.results);

		const mergedRequestMap = new Map([...defaultsMap, ...filterParam_]);
		return RequestController.fetchFilters(mergedRequestMap);
	}

	resetSortingIfNecessary(filterAndPresets) {
		const isDistanceSortingActive = (STCK_STORE.state.sorting.results || '').toLowerCase().includes('distance');
		const filter = filterAndPresets.get('filter') || [];
		const presets = filterAndPresets.get('preset') || [];
		const isGeoFilterOrPresetActive = filter.includes('geo') || presets.includes('geo');

		if (isDistanceSortingActive && !isGeoFilterOrPresetActive) {
			STCK_STORE.dispatch(ACTIONS.SORTING.resetSorting());
		}
	}

	/**
	 * fetch compare vehicle data
	 * @param {array} audiCodes array of audicodes to be compared
	 * @returns {Promise} error or response wrapped in a promise
	 */
	async fetchCompareVehicles(audiCodes = []) {
		try {
			if (!audiCodes.length || audiCodes.length === 0) {
				throw new Error('error fetching compare vehicles: missing audicodes');
			}
			const filterParam = new Map();
			filterParam.set('audicodes', audiCodes);

			const response = await RequestController.fetchCompareVehicles(filterParam);
			return response;

		}
		catch (error) {
			// @TODO handle errors
			console.error(error);
			throw error;
		}
	}

	/**
	 * fetch matching vehicle data by it´s dealer (array)
	 * @param {string} vehicleId_ array with vehcile Ids
	 * @param {number} size_ number of results
	 * @param {string} dealerId_ dealer´s id
	 * @returns {Promise} error or response wrapped in a promise
	 */
	async fetchMatchingVehicleData(vehicleId_ = '', size_ = 0, dealerId_ = '') {
		try {
			const dealerFilter = new Map();
			if (!!dealerId_) {
				dealerFilter.set('filter', `dealer.${dealerId_}`);
			}
			const activePresets = SELECTORS.FILTERS.getPersistedPresetsMap(STCK_STORE.state);
			const filterParam = this._generateFilterRequestParam(dealerFilter, activePresets);

			const svd = await svdModel.getSvdVersion();
			filterParam.set('svd', svd);
			filterParam.set('from', 0);
			filterParam.set('size', size_);
			const response = await RequestController.fetchMatchingVehicles(vehicleId_, filterParam);
			return response;
		}
		catch (err) {
			// @TODO handle errors
			console.error(err);
			throw err;
		}
	}

	/**
	 * fetch vehicle data by given IDs (array)
	 * @param {array} vehicleIds array with vehcile Ids
	 * @param {string} sortParam optional sort parram
	 * @returns {Promise} error or response wrapped in a promise
	 */
	async fetchVehiclesByIds(vehicleIds = [], sortParam = '') {
		try {
			const filterParam = new Map();

			const svd = await svdModel.getSvdVersion();
			filterParam.set('svd', svd);

			filterParam.set('from', 0);
			filterParam.set('size', vehicleIds.length);
			if (!!sortParam) {
				filterParam.set('sort', sortParam);
			}
			const vehiclesArray = vehicleIds.map((id) => `vehicle.${id}`);
			filterParam.set('filter', vehiclesArray.join(','));


			const response = await RequestController.fetchFilters(filterParam);
			return response;
		}
		catch (err) {
			// @TODO handle errors
			console.error(err);
			throw err;
		}
	}

	/**
	 * incrementVehicleResults
	 * @returns {Promise<void>} returns nothing
	 */
	async incrementVehicleResults() {
		try {
			const activeFilters = SELECTORS.FILTERS.getSelectedFiltersMap(STCK_STORE.state);
			const activePresets = SELECTORS.FILTERS.getPersistedPresetsMap(STCK_STORE.state);
			const filterParam = this._generateFilterRequestParam(activeFilters, activePresets);
			vehiclesController.incrementIndex();
			filterParam.set('from', SELECTORS.UI.getResultIndex(STCK_STORE.state));
			const response = await this._fetchFilters(filterParam);
			STCK_STORE.dispatch(ACTIONS.VEHICLES.handleIncrementalResponse({response}));
			return response;
		}
		catch (err) {
			// @TODO handle errors
			console.warn(err);
			throw err;
		}
	}

	/**
	 * deselectItems removes filters from active filters list by setting
	 * their 'active' attribute to false
	 * @param {array<string>} filterItemIdsArray array of ids to be removed
	 * @return {Map} active filter items
	 */
	_deselectItems(filterItemIdsArray = []) {
		const activeFiltersMap = new Map(SELECTORS.FILTERS.getSelectedFiltersMap(STCK_STORE.state));// new Map([...filterModel.activeFilters]);
		filterItemIdsArray.forEach(filterId => {
			activeFiltersMap.delete(filterId);
		});

		return activeFiltersMap;// FilterModelClass.getActiveFilters(activeFilters);
	}

	/**
	 * removes filters if they have been currently active
	 * calls scs request with active filters
	 * @param {array} filterItemIdsArray array og itemIds to be removed/deactivated
	 * @returns {Promise} response of the fetch request wrapped in a promise
	 */
	async removeFiltersByIds(filterItemIdsArray = []) {
		if (filterItemIdsArray.length === 1 && ScsControllerClass.isMinMaxRangePseudoId(filterItemIdsArray[0])) {
			return this._removeRangeValue(filterItemIdsArray[0]);
		}
		else {

			const activeFilters = this._deselectItems(filterItemIdsArray);
			const activePresets = SELECTORS.FILTERS.getPersistedPresetsMap(STCK_STORE.state);
			const filterParam = this._generateFilterRequestParam(activeFilters, activePresets);
			vehiclesController.resetIndex();
			try {
				const response = await this._fetchFilters(filterParam);
				this._handleResponse(response);
				return response;
			}
			catch (err) {
				this._handleFilterErrors(err);
				throw err;
			}
		}
	}

	/**
	 * check for min_max range filter with pseudoId
	 * @param {string} pseudoFilterId filterId with prefix ":min" or ":max"
	 * @return {boolean} is minmax range filter
	 */
	static isMinMaxRangePseudoId(pseudoFilterId = '') {
		return !!(pseudoFilterId.split(':').length === 2);
	}

	/**
	 * remove a min or max value from range filter
	 * @param {string} pseydoFilterId filter id with 'min' or 'max' suffix
	 * @return {void} nothing
	 */
	async _removeRangeValue(pseydoFilterId = '') {
		const activeFiltersMap = new Map(SELECTORS.FILTERS.getSelectedFiltersMap(STCK_STORE.state)); // new Map([...filterModel.activeFilters]);
		const [filterId, valueToRemove] = pseydoFilterId.split(':');
		const previousFilter = activeFiltersMap.get(filterId);
		const activePresets = SELECTORS.FILTERS.getPersistedPresetsMap(STCK_STORE.state);
		const filterToChange = FilterDTO.initFromFilterString(previousFilter.toString(), previousFilter._active, previousFilter._preset);
		filterToChange.removeRangeValue(valueToRemove);
		activeFiltersMap.set(filterToChange.id, filterToChange);
		const filterParam = this._generateFilterRequestParam(activeFiltersMap, activePresets);
		try {
			const response = await this._fetchFilters(filterParam);
			this._handleResponse(response);
			return response;
		}
		catch (err) {
			this._handleFilterErrors(err);
			throw err;
		}
	}

	/**
	 * remove all currently active filters (not presets!)
	 * @returns {Promise<void>} init with filters promise chain
	 */
	async clearAllFilters() {
		STCK_STORE.dispatch(ACTIONS.FILTERS.triggerFilterReset());
		const previousPresetsMap = SELECTORS.FILTERS.getPersistedPresetsMap(STCK_STORE.state);
		return this.initWithFilters(new Map(), previousPresetsMap);
	}

	_handleFilterErrors(err) {
		console.error(err);
	}

	/**
	 * load filters on initialization (enter with restored previous filters from storage,
	 * enter with filterHash or with empty filters array)
	 * @param {Map<FilterDTO>} previousFiltersMap map with previous filterDTO filter items
	 * @param {Map<FilterDTO>} previousPresetsMap map with previous filterDTO preset items
	 * @returns {Promise} response or error
	 */
	async initWithFilters(previousFiltersMap = new Map(), previousPresetsMap = new Map()) {
		if (scModelMandatoryAreaSearch.activeMas) {
			const masPresetLocation = scModelMandatoryAreaSearch.presetLocation;
			if (masPresetLocation && masPresetLocation.filter) {
				const masLocationFilter = new FilterDTO({
					id: masPresetLocation.filter.$id,
					preset: masPresetLocation.filter.$preset,
					active: masPresetLocation.filter.active,
					values: masPresetLocation.filter.$values
				});
				previousPresetsMap.set('geo', masLocationFilter);
			}
		}

		vehiclesController.resetIndex();
		const filterParam = this._generateFilterRequestParam(previousFiltersMap, previousPresetsMap);
		try {
			const response = await this._fetchFilters(filterParam);
			this._handleResponse(response);
			return response;
		}
		catch (err) {
			this._handleFilterErrors(err);
			throw err;
		}
	}


	async getFilterResponse(previousFiltersMap = new Map(), previousPresetsMap = new Map()) {
		const filterParam = this._generateFilterRequestParam(previousFiltersMap, previousPresetsMap);
		try {
			const response = await this._fetchFilters(filterParam);
			return response;
		}
		catch (err) {
			throw err;
		}
	}


	/**
	 * update filters with changedFiltersArray (one or many filters at once) with current filters
	 * then fetch filters with modified/active filters
	 * @param {array} changedFiltersArray array with filterDTO items
	 * @returns {void} nothing
	 */
	async updateFilters({changedFiltersArray = []} = []) {
		let currentFilters = new Map(SELECTORS.FILTERS.getSelectedFiltersMap(STCK_STORE.state));
		const currentPresets = new Map(SELECTORS.FILTERS.getPresetFiltersMap(STCK_STORE.state));
		changedFiltersArray.forEach(filterDTO => {
			if (filterDTO.isPreset()) {
				currentPresets.set(filterDTO.id, filterDTO);
			}
			else {
				if (!filterDTO.isActive()) {
					currentFilters.delete(filterDTO.id);
				}
				else {
					currentFilters.set(filterDTO.id, filterDTO);
				}
			}
		});
		vehiclesController.resetIndex();
		const filterParam = this._generateFilterRequestParam(currentFilters, currentPresets);
		try {
			const response = await this._fetchFilters(filterParam);
			this._handleResponse(response);
			return response;
		}
		catch (err) {
			this._handleFilterErrors(err);
		}
	}

	/**
	 * get marker for ajax calls
	 * @returns {Promise} with marker as resolve
	 */
	async getAjaxMarker() {
		const svdVersion = await svdModel.getSvdVersion().catch(err => console.error(err));
		return {
			marker: this._getScsVersionMarker(svdVersion),
			overwrites: true
		};
	}

	/**
	 * gets the required XHR-marker (SVD / Overlay)
	 * @param {string} svdVersion - the SVD version
	 * @return {string} version string
	 */
	_getScsVersionMarker(svdVersion) {
		let version = svdVersion,
			staticVersion = SETUPS.get('nemo.staticversion'),
			buildTimestamp = SETUPS.get('nemo.build.time');
		// add staticVersion, if overlay (== staticVersion)
		if (
			!!buildTimestamp &&
			!!staticVersion &&
			buildTimestamp !== staticVersion
		) {
			version += '.' + staticVersion;
		}
		// delete leading .
		if (!!version && version.toString().indexOf('.') === 0) {
			version = version.substring(1);
		}
		return version;
	}
}

const scsController = new ScsControllerClass();
content.registerMarkerProvider(scsController.getAjaxMarker.bind(scsController));
export {scsController, ScsControllerClass};
