import {template} from 'core-utils';
import {contactFormElementTemplate} from './contactform-element-template';

export default class ContactFormElement extends HTMLElement {
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		this._initializeProperties();
		this._render();
	}

	/**
	 * initialized dom properties
	 * @private
	 * @returns {void} void
	 */
	_initializeProperties() {
		const data = Object.assign({}, this.dataset);
		this.contactForms = SETUPS.get('stockcar.ctaSpecialLinks') || {};
		this._data = {};
		this._data.type = (data.type) || 'contact';
		this._data.css = (data.cssClass) || 'nm-layerLink audi-link-s audi-link-s--arrow';
		this._data.vehicleId = (data.vehicleid) || '';
		this._data.url = this._getContactUrl();
		this._data.text = this._getContactText();
	}

	/**
	 * get contact url
	 * @private
	 * @returns {string} returns url
	 */
	_getContactUrl() {
		let url = this.contactForms[this._data.type] ? this.contactForms[this._data.type].url : '';
		if (this._data.vehicleId) {
			url += '?vehicleid=' + this._data.vehicleId;
		}
		else {
			console.info('Vehicla Data URL Patch Missmatch, for URL: ', url);
		}
		return url;
	}

	/**
	 * get contact text
	 * @private
	 * @returns {string} returns text string
	 */
	_getContactText() {
		return this.contactForms[this._data.type] ? this.contactForms[this._data.type].text : '';
	}

	/**
	 * render
	 * @private
	 * @returns {void} return nothing
	 */
	_render () {
		if (this._data.url !== '' && this._data.text !== '') {
			const financeElelementHtmlString = template.render(contactFormElementTemplate, this._data);
			this.innerHTML = financeElelementHtmlString;
		}
	}
}

if (window.customElements.get('contact-form-element') === undefined) {
	window.customElements.define('contact-form-element', ContactFormElement);
}
