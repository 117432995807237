import {tyreLabelTemplate} from './tyre-label-template';

const exports = `
	<article class="sc-module sc-md-mediathek-infolayer {{? !it.image }}sc-mediathek-infolayer-text-only{{?}}">
	<div class="sc-md-mediathek-infolayer-item">
		{{? it.video || it.image }}
			<div class="sc-md-mediathek-infolayer-media">
				{{? it.video}}
					<div class="nm-mediaplayer nm-video">
						<video src="{{=it.video.src}}" poster="{{=it.video.poster}}"></video>
						<div class="nm-prevent-video-click"></div>
						<div class="nm-controls-wrap-video">
							<div class="nm-video-loader">
								<div class="nm-loader-played"></div>
								<div class="nm-loader-loaded"></div>
							</div>
							<div class="nm-controls-video clearfix">
								<div class="nm-button-play-pause nm-play nm-j-play-btn">
									<button class="nm-video-button-play" type="button" title="{{=window.i18n['nemo.ui.play-button']}}"></button>
									<button class="nm-video-button-pause" type="button" title="{{=window.i18n['nemo.ui.pause-button']}}"></button>
								</div>
								<div class="nm-title-wrap">
									<div class="nm-title"><span class="nm-el-lbl nm-el-lbl-01 nm-at-lbl-inv"></span></div>
									<div class="nm-time"><span class="nm-time-played ">00:00</span><span class="nm-time-break"> | </span><span class="nm-time-duration">00:00</span></div>
								</div>
								<div class="nm-button-fullscreen nm-j-fullscreen-btn">
									<button class="" type="button" title="{{=window.i18n['nemo.ui.fullscreen-button']}}"></button>
								</div>
								<div class="nm-button-mute nm-mute nm-j-mute-btn">
									<button class="" type="button" title="{{=window.i18n['nemo.ui.mute-button']}}"></button>
								</div>
							</div>
						</div>
						<div class="nm-button-play nm-button-overlay nm-j-play-btn">
							<button class="nm-video-button-play" type="button" title="{{=window.i18n['nemo.ui.play-button']}}"><span class="nm-image-replace">Play</span></button>
						</div>
					</div>
				{{?? it.image }}
					<img class="sc-md-mediathek-infolayer-img" src="{{=it.image}})"/>
				{{?}}
			</div>
		{{?}}
		<div class="nm-content">
			<div class="sc-md-mediathek-infolayer-desc">
				{{? it.headline }}<h2 class="sc-md-mediathek-infolayer-headline audi-headline-order-2">{{=it.headline}}</h2>{{?}}
				{{? it.intro }}<div class="sc-md-mediathek-infolayer-intro audi-headline-order-3">{{=it.intro}}</div>{{?}}
				{{? it.description }}<div class="sc-md-mediathek-infolayer-description audi-copy-s">{{=it.description}}</div>{{?}}
				{{? it.benefits }}<div class="sc-md-mediathek-infolayer-benefits-headline audi-headline-order-3">{{=window.i18n['sc.details.mediathek.benefits.headline']||'sc.details.mediathek.benefits.headline'}}</div><div class="sc-md-mediathek-infolayer-benefits audi-copy-s">{{=it.benefits}}</div>{{?}}
				{{? it.packageContent }}
					<div class="sc-md-mediathek-infolayer-package-content-headline audi-headline-order-3">{{=window.i18n['sc.details.mediathek.package.headline']||'sc.details.mediathek.package.headline'}}</div>
					<div class="sc-md-mediathek-infolayer-package-content-media">
						{{ for(var item in it.packageContent) { }}
							<div class="sc-md-mediathek-infolayer-package-content-media-item">
								{{? it.packageContent[item].small_hd_image}}<img src="{{=it.packageContent[item].small_hd_image}}">{{?}}
								{{? it.packageContent[item].ak_headline}}
									<div class="sc-md-mediathek-infolayer-package-content-media-item-head">
										<div class="sc-mediathek-infolayer-package-content-media-item-head-text audi-copy-m">{{=it.packageContent[item].ak_headline}}
											{{? it.packageContent[item].small_hd_image || it.packageContent[item].ak_text || it.packageContent[item].intro}}
											<a data-href="/js-link/sc-mediathek-infolayer/vehicle-id/{{=it.id}}/prnumber/{{=item}}" class="sc-mediathek-infolayer-info-icon nm-layerLink" title="{{=window.i18n['more-information']||'more-information'}}" x-cq-linkchecker="skip">
												<svg class="nm-icon-system-info-small">
													<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-info-small.svg#nm-icon-system-info-small"}}></use>
												</svg>
											</a>
											{{?}}
										</div>
									</div>
								{{?}}
							</div>
						{{ } }}
					</div>
				{{?}}
				{{? (SETUPS.get('stockcar.eu.tyre.label') && ((it.mainTyres && it.mainTyres.length) || (it.additionalTyres && it.additionalTyres.length)))}}
					${tyreLabelTemplate}
				{{?}}
			</div>
		</div>
	</div>
</article>
`;

export {exports as mediathekInfolayerTemplate};
