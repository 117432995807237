import '../../runtimePublicPath';
import {STCK_STORE, ACTIONS, SELECTORS} from '@oneaudi/stck-store';
import UrlSetups from '../filter/scs-url-setups';
import RequestWorker from 'workerize-loader!./RequestWorker';

const IS_IE_11 = !!navigator.userAgent.match(/Trident\/7\./);

const workerInstance = new RequestWorker();

export default class RequestController {
	static get defaults() {
		return {
			REQUEST_OPTIONS: {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			}
		};
	}

	/**
	 * generic fetch with error handling and parsing
	 * @param {string} url url to fetch data from
	 * @param {object} options_ request options
	 * @returns {Promise<*>} parsed result or handled error
	 */
	static async _fetchData(url, options_ = {}) {
		const requestOptions = Object.assign({}, RequestController.defaults.REQUEST_OPTIONS, options_);
		RequestController._setLoaderState(url, true);
		if (!IS_IE_11) {
			const res = await workerInstance.workerFetch(url, requestOptions);
			RequestController.removeLoadingStateOnIdle(url);
			return res;
		}
		const rawResponse = await fetch(url, requestOptions);
		RequestController.removeLoadingStateOnIdle(url);
		return RequestController._handleResponse(rawResponse);
	}

	static _setLoaderState(url = '', state = false) {
		if (new RegExp(UrlSetups.filterRequestUrl, 'gi').test(url)) {
			const feature = 'filter';
			STCK_STORE.dispatch(ACTIONS.UI.setLoader({state, feature}));
		}
		if (new RegExp(UrlSetups.versionUrl, 'gi').test(url)) {
			const feature = 'version';
			if (state === false) {
				// loader to be disabled after totalCount is set
				const unsubscribeCB = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getTotalCountState, () => {
					unsubscribeCB();
					STCK_STORE.dispatch(ACTIONS.UI.setLoader({state, feature}));
				});
			}
			else {
				STCK_STORE.dispatch(ACTIONS.UI.setLoader({state, feature}));
			}
		}
	}

	static removeLoadingStateOnIdle(url) {
		const cbFn = () => RequestController._setLoaderState(url, false);
		cbFn();
	}

	/**
	 * middleware to preflight response/errors returnung parse response or custom Error
	 * @param {object} rawResponse response object
	 * @returns {Promise<*>} parsed response or custom Error Object
	 */
	static async _handleResponse(rawResponse) {
		try {
			const status = rawResponse.status;
			const response = await RequestController._extractResponse(rawResponse);
			const error = new Error();

			if (status === 200) {
				return response;
			}
			else {
				error.message = 'unhandled error';
				throw error;
			}
		}
		catch (error) {
			return RequestController._handleErrors(error);
		}
	}

	/**
	 * extract response
	 * @param {object} rawResponse_ response object
	 * @returns {Promise<*>} parsed response or custom Error Object
	 */

	static async _extractResponse(rawResponse_) {
		try {
			const response = await rawResponse_.json();
			return response;
		}
		catch (error) {
			return rawResponse_;
		}
	}

	/**
	 * handle response error middleware
	 * @param {Error} error_ custom Error
	 * @returns {Promise<*>} Error
	 */
	static async _handleErrors(error_) {
		if (error_ && error_.message === 'unhandled error') {
			// ToDo: emit error event (SC_ERROR or connect modal layer controller)
		}
		throw error_;
	}

	/**
	 * fetch svd version from service
	 * @returns {Promise<string>} svd version string
	 */
	static async fetchSvdHeader() {
		const url = UrlSetups.versionUrl;
		return await RequestController._fetchData(url);
	}

	static async fetchCampaigns(svd_) {
		const url = `${UrlSetups.campaignsStructureUrl}?svd=${svd_}`;
		try {
			return await RequestController._fetchData(url);
		}
		catch (error) {
			throw error;
		}
	}

	/**
	 *
	 * @param {string} url request url
	 * @param {Map} params get request params as map
	 * @returns {string} complete request url with get params etc.
	 */
	static _generateRequestParamString(url, params = new Map()) {
		let getParams = [];

		for (let [parameterKey, parameter] of params.entries()) {
			if (!!parameter) {
				const encodedParameter = encodeURIComponent(parameter);
				getParams.push(`${parameterKey}=${encodedParameter}`);
			}
		}
		return `${url}${getParams.length ? '?' + getParams.join('&') : ''}`;
	}

	static async fetchVehicleById(vehicleId_, svd_) {
		const url = `${UrlSetups.vehicleUrl}${vehicleId_}?svd=${svd_}`;
		try {
			return await RequestController._fetchData(url);
		}
		catch (error) {
			throw error;
		}
	}

	static async fetchVehicleMedia(vehicleId_, prNumber_, svd_) {
		const url = `${UrlSetups.mediaUrl}/${vehicleId_}/${prNumber_}?svd=${svd_}`;
		try {
			return await RequestController._fetchData(url);
		}
		catch (error) {
			throw error;
		}
	}

	/**
	 * fetchFilters
	 * @param {Map} filterOptions active filters map
	 * @returns {Promise<*>} returns response json
	 */
	static async fetchFilters(filterOptions = new Map()) {
		const url = RequestController._generateRequestParamString(
			UrlSetups.filterRequestUrl,
			filterOptions
		);

		try {
			return await RequestController._fetchData(url);
		}
		catch (error) {
			throw error;
		}
	}

	static async fetchMatchingVehicles(vehicleId = '', filterOptions = new Map()) {
		const url = RequestController._generateRequestParamString(
			`${UrlSetups.matchUrl}/${vehicleId}`,
			filterOptions
		);

		try {
			return await RequestController._fetchData(url);
		}
		catch (error) {
			throw error;
		}
	}

	static async fetchCompareVehicles(options = new Map()) {
		const url = RequestController._generateRequestParamString(
			UrlSetups.compareUrl,
			options
		);

		try {
			return await RequestController._fetchData(url);
		}
		catch (error) {
			throw error;
		}
	}

	/**
	 * fetchReserveOption
	 * @param {string} vehicleId ID of one vehicle
	 * @param {string} apiKey apiKey for the reserve API
	 * @returns {Promise<*>} returns response json
	 */
	static async fetchReserveOption(vehicleId = '', apiKey = '') {
		try {
			// TODO: get URL from AEM
			return await RequestController._fetchData(`https://edeposit.api.dev.emea.acp.cloud.audi/api/reservation/eligibility/${vehicleId}`, {
				headers: {
					'x-api-key': apiKey
				}
			});
		}
		catch (error) {
			throw error;
		}
	}
}
