function supportsNoModule() {
	const script = document.createElement('script');
	return 'noModule' in script;
}

const suffix = supportsNoModule() ? 'modern' : 'legacy';
const STOCKCARS_BUILD_FOLDER = `/cms4i-stockcars/build/${suffix}/`;


if (!!SETUPS.get('nemo.url.static') && !!SETUPS.get('nemo.staticversion')) {
	// eslint-disable-next-line no-undef
	__webpack_public_path__ = `${location.origin}${SETUPS.get('nemo.url.static')}.${SETUPS.get('nemo.staticversion')}${STOCKCARS_BUILD_FOLDER}`;
}
else {
	// eslint-disable-next-line no-undef
	__webpack_public_path__ = STOCKCARS_BUILD_FOLDER;
}
