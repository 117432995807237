/* global HTMLElement, window */
import {dom} from 'core-utils';
import {searchAgentController} from './search-agent-controller';
import {scPage} from '../../stockcars-utils-bundle';
import {searchAgentAuthController} from './search-agent-auth-controller';

/**
 * selectors - dom selectors for multiple usages
 * @type {{dialogueWrapper: string, dialogue: string, closeLayerButton: string, loginButton: string}}
 */
const selectors = {
	closeLayerButton: '.sc-search-agent-login-navigation-close',
	dialogue: '.sc-dialogue',
	dialogueWrapper: '.sc-dialogue-wrapper',
	loginButton: '.sc-search-agent-login-button a',
	logoutSearchAgent: '.sc-j-search-agent-logout',
	openSearchAgents: '.sc-j-search-agents-open',
	saveSearchAgent: '.sc-j-search-agent-save',
	searchAgentLogin: '.sc-j-search-agent-login',
	classSearchAgentSaving: 'search-agent-saving'
};

export default class SearchAgentLogin extends HTMLElement {
	/**
	 * constructor
	 */
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this.domDelegate = dom.getEventDelegate('body');
		this._setElementDomProperties();
		this._bindContextToFunctions();
		this.initializeHandler();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	 * set element properties
	 * @returns {void} returns nothing
	 */
	_setElementDomProperties() {
		this.dialogueWrapper = dom.getElement(selectors.dialogueWrapper, this);
		this.dialogue = dom.getElement(selectors.dialogue, this);
		this.closeButton = dom.getElement(selectors.closeLayerButton, this);
		this.loginButton = dom.getElement(selectors.loginButton, this);
	}

	/**
	 * _bindContextToFunctions - bind context to necessary functions
	 * @private
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this.initializeHandler = this._initializeModule.bind(this);
		this.hideLayer = this._hideLayer.bind(this);
		this._handleLoginClick = this._handleLoginClick.bind(this);
		this._handleLogoutClick = this._handleLogoutClick.bind(this);
		this._handleOpenSearchAgents = this._handleOpenSearchAgents.bind(this);
		this._handleSaveSearchAgent = this._handleSaveSearchAgent.bind(this);
		this._handleDialogueClick = this._handleDialogueClick.bind(this);
	}

	/**
	 * initialize
	 * @private
	 * @returns {void} returns nothing
	 */
	_initializeModule() {
		this._addEvents();
	}

	/**
	 * _addEvents - adds events when element is connected
	 * @private
	 * @returns {void} returns nothing
	 */
	_addEvents() {
		this.closeButton.addEventListener('click', this.hideLayer);
		this.dialogueWrapper.addEventListener('click', this._handleDialogueClick);
		this.domDelegate.on(
			'click',
			selectors.searchAgentLogin,
			this._handleLoginClick
		);
		this.domDelegate.on(
			'click',
			selectors.openSearchAgents,
			this._handleOpenSearchAgents
		);
		this.domDelegate.on(
			'click',
			selectors.saveSearchAgent,
			this._handleSaveSearchAgent
		);
		this.domDelegate.on(
			'click',
			selectors.logoutSearchAgent,
			this._handleLogoutClick
		);
		this.domDelegate.on(
			'click',
			selectors.loginButton,
			this._handleLoginLayerClick
		);
	}

	/**
	 * _removeEvents - removes events when element is disconnected
	 * @private
	 * @returns {void} returns nothing
	 */
	_removeEvents() {
		this.closeButton.removeEventListener('click', this.hideLayer);
		this.dialogueWrapper.removeEventListener('click', this._handleDialogueClick);
		this.domDelegate.off(
			'click',
			selectors.searchAgentLogin,
			this._handleLoginClick
		);
		this.domDelegate.off(
			'click',
			selectors.openSearchAgents,
			this._handleOpenSearchAgents
		);
		this.domDelegate.off(
			'click',
			selectors.saveSearchAgent,
			this._handleSaveSearchAgent
		);
		this.domDelegate.off(
			'click',
			selectors.logoutSearchAgent,
			this._handleLogoutClick
		);
	}

	/**
	 * _handleOpenSearchAgents - open search agent dashboard with the current stack of all search agents
	 * @private
	 * @param {Event} event - open search agents click event
	 * @returns {void} returns nothing
	 */
	async _handleOpenSearchAgents(event) {
		const patchedTargetUrl = this._getTargetUrl(event);
		searchAgentController.getAllSearchAgents().then(() => {
			scPage.simulateNmPageOpen(patchedTargetUrl);
		}).catch(() => {
			// if status 401, patch login button in layer with patchedTargetUrl
			this.setButtonTargetUrl(patchedTargetUrl);
		});
	}

	/**
	 * _handleLoginClick - handles the login click
	 * @private
	 * @param {Event} event - login click event
	 * @returns {void} returns nothing
	 */
	async _handleLoginClick(event) {
		const login = event.target.closest('.search-agent-authentication-login');
		const target = login ? login.getAttribute('data-target-url') : event;
		const redirectUrl = typeof target === 'string' ? location.origin + target : this._getTargetUrl(event);
		try {
			await searchAgentAuthController.login(redirectUrl);
		}
		catch (e) {
			console.error('Login failed: ', e);
		}
	}

	/**
	 * _handleLogoutClick - handles the logout click
	 * @private
	 * @returns {void} returns nothing
	 */
	async _handleLogoutClick() {
		try {
			const redirectUrl = SETUPS.get('stockcar.url.startpage') ? location.origin + SETUPS.get('stockcar.url.startpage') : location.href;
			await searchAgentAuthController.logout(redirectUrl);
		}
		catch (error) {
			console.error('Logout failed: ', error);
		}
	}

	/**
	 * _handleSaveSearchAgent - save current search agent
	 * @private
	 * @param {Event} event - open search agents click event
	 * @returns {void} returns nothing
	 */
	async _handleSaveSearchAgent(event) {
		event.target.classList.add(selectors.classSearchAgentSaving);
		let patchedTargetUrl = this._getTargetUrl(event);
		const {isAuthenticated} = await searchAgentAuthController.getAuthenticationState().catch(err => {
			console.log(err);
			this.showLoginLayer();
		});
		if (isAuthenticated) {
			searchAgentController.saveSearchAgent(isAuthenticated).then(() => {
				scPage.simulateNmPageOpen(patchedTargetUrl);
			});
		}
		else {
			const loginButtonRedirectUrl = this._getTargetUrl(event);
			this.loginButton.setAttribute('data-target-url', loginButtonRedirectUrl);
			searchAgentController.saveSearchStorageState = true;
			this.showLayer();
		}
	}

	/**
	 * _handleLoginLayerClick - login layer link click
	 * @private
	 * @param {Event} event - login click event
	 * @returns {String} returns patched target url
	 */
	async _handleLoginLayerClick(event) {
		event.preventDefault();
		const targetUrl = event.target.parentElement.dataset.targetUrl;
		try {
			await searchAgentAuthController.login(targetUrl);
		}
		catch (e) {
			console.error('Login failed: ', e);
		}
	}

	/**
     * _handleDialogueClick
     * @param {event} event event
     * @returns {void} void
     */
	_handleDialogueClick(event) {

		const isEventFromWrapper = event.target === this.dialogueWrapper;
		const isEventFromCloseButton = event.target === this.closeLayerButton;

		if (isEventFromWrapper || isEventFromCloseButton) {
			this.hideLayer();
		}
	}

	/**
	 * _getTargetUrl - getter for target url
	 * @private
	 * @param {Event} event - event
	 * @returns {String} returns target url
	 */
	_getTargetUrl(event) {
		return event &&
		event.target &&
		event.target.getAttribute('data-target-url')
			? location.origin + event.target.getAttribute('data-target-url')
			: location.origin + location.pathname;
	}

	/**
	 * setButtonTargetUrl
	 * @private
	 * @param {string} targetUrl targetUrl
	 * @returns {void} nothing
	 */
	setButtonTargetUrl(targetUrl) {
		this.loginButton.setAttribute('data-target-url', targetUrl);
	}

	/**
	 * _hideLayer - hide layer when closed button was clicked
	 * @private
	 * @returns {void} returns nothing
	 */
	_hideLayer() {
		const saveButtons = document.querySelectorAll(selectors.saveSearchAgent);
		if (saveButtons) {
			[...saveButtons].forEach(button => {
				button.classList.remove(selectors.classSearchAgentSaving);
			});
		}
		this.classList.add('sc-hidden');
		this.dialogueWrapper.setAttribute('data-active', 'false');
		this.dialogue.style.opacity = 0;
	}

	/**
	 * showLayer - show the login layer when necessary
	 * @private
	 * @returns {void} returns nothing
	 */
	showLayer() {
		this.classList.remove('sc-hidden');
		this.dialogueWrapper.setAttribute('data-active', 'true');
		this.dialogue.style.opacity = 1;
	}
}

if (!window.customElements.get('search-agent-login', SearchAgentLogin)) {
	window.customElements.define('search-agent-login', SearchAgentLogin);
}
