import {svdModel} from "../filter/svd-model";
import RequestController from '../filter/scs-request-controller';
import {STCK_STORE, SELECTORS, ACTIONS} from '@oneaudi/stck-store';
import {OBJECT_UTILS} from "../../stockcars-utils-bundle";

class VehiclesControllerClass {
	constructor() {
		this._startIndex = 0;
		this._vehiclesType = null;
		this._sorting = null;
	}

	static get defaults() {
		return {INCREMENTAL_OFFSET: 12};
	}

	/**
	 * get vehicle data by id
	 * @param {string} vehicleId vehicle id
	 * @returns {object} returns vehicle object or null
	 */
	getVehicleById(vehicleId) {
		const foundVehicles = SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state).get(vehicleId);
		return foundVehicles || null;
	}

	/**
	 * increment startindex with incremetakOffset and return new startindex
	 * @returns {number} next index
	 */
	incrementIndex() {
		STCK_STORE.dispatch(ACTIONS.UI.incrementResultsCount());
	}

	resetIndex() {
		STCK_STORE.dispatch(ACTIONS.UI.resetResultsCount());
	}

	get sorting() {
		return this._sorting || STCK_STORE.state.sorting.results;
	}

	set sorting(name) {
		this._sorting = name;
	}

	/**
	 * static getVehicleIdFromUrl method
	 * @param {string} url_ url string
	 * @returns {string} vehicle ID
	 */
	static getVehicleIdFromUrl(url_ = location.href) {
		let regex = /(sc_detail.*?\.)(([^.\s])+)/gi,
			matchesArr,
			vehicleId,
			vehicleIds = [];
		// loop all matches and push id into vehicleIds array
		while ((matchesArr = regex.exec(location.href))) {
			vehicleIds.push(matchesArr[2]);
		}
		// return only the last id - needed for detail page in layer on top of detail page
		vehicleId = vehicleIds[vehicleIds.length - 1] || '';
		// if url has param vehicleid, use this vehicleid instead of sc_detail vehicleid
		let match = url_.match(/(vehicleid=)(\D|\w)+&*/gi);
		if (!!match && match.length) {
			match = (match[0] || '').replace(/vehicleid=/, '');
			match = match.split('&').shift();
			vehicleId = match;
		}
		return vehicleId;
	}

	vehicleIsPending(vehicleId) {
		return (OBJECT_UTILS.getNestedObject(STCK_STORE.state, "ui.pending".split(".")) || []).indexOf(vehicleId) > -1;
	}

	markVehiclesAsPending(vehicleId) {
		STCK_STORE.dispatch(ACTIONS.UI.setPendingVehicles({vehicleId}));
	}

	markVehicleAsLoaded(vehicleId) {
		STCK_STORE.dispatch(ACTIONS.UI.removePendingVehciles({vehicleId}));
	}

	/**
	 * load one vehicle by its id from the SCS Service
	 * @param {string} vehicleId id of the requested vehicle
	 * @returns {Promise<object|null>} vehicle object
	 */
	async loadVehicleById(vehicleId) { // eslint-disable-line max-statements
		let vehicle = this.getVehicleById(vehicleId);

		if (!vehicle) {
			if (this.vehicleIsPending(vehicleId)) {
				this.markVehiclesAsPending(vehicleId);
				return STCK_STORE.waitForStateFromStorePromise({
					conditionFn: (state) => (state.ui.pending.indexOf(vehicleId) < 0),
					selectorToReturn: () => this.loadVehicleById(vehicleId)
				});
			}
			else {
				try {
					this.markVehiclesAsPending(vehicleId);
					const svd = await svdModel.getSvdVersion();
					vehicle = await RequestController.fetchVehicleById(vehicleId, svd);
					vehicle = (vehicle && vehicle.vehicle && vehicle.vehicle.basic) ? vehicle.vehicle.basic : vehicle;
					this.markVehicleAsLoaded(vehicleId);
					STCK_STORE.dispatch(ACTIONS.VEHICLES.setVehicles({vehicles: [vehicle]}));
				}
				catch (err) {
					this.markVehicleAsLoaded(vehicleId);
					console.warn('could not load vehicle ' + vehicleId, err);
					vehicle = null;
				}
			}
		}
		return vehicle;
	}

}

const vehiclesController = new VehiclesControllerClass('vehiclesController');

export {VehiclesControllerClass, vehiclesController};
