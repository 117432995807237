const exports = `
	<div class="sc-car-tile-tabs">
		{{var randomNumberForids = Math.floor(Math.random() * 10000) + 1;}}
		{{var randomNumberForName = Math.floor(Math.random() * 10000) + 1;}}
		{{var amountVisibleTabs = 0;}}

		{{? it.tileTabs.tabList.length > 1}}
			{{~it.tileTabs.tabList :tab}}
				{{? tab.available}}
					<input {{? tab.checked}} checked {{?}} class="sc-car-tile-tab-selector sc-car-tile-tab-{{=tab.id}}" type="radio" id="tab-{{=tab.id}}-{{=it.id}}-{{=randomNumberForids}}" name="tabs-selection-{{=it.id}}-{{=randomNumberForName}}">
					{{amountVisibleTabs++;}}
				{{?}}
			{{~}}
		{{?}}
		<div class="sc-car-tile-tab-selector-labels sc-car-tile-tabs-{{=amountVisibleTabs}} sc-hyphenate">
			{{? it.tileTabs.tabList.length > 1}}
				{{~it.tileTabs.tabList :tab}}
					{{? tab.available}}
						<label class="sc-car-tile-tab-selector-label tab-{{=tab.id}} tab-{{=tab.type}} audi-copy-s{{? amountVisibleTabs < 2}} sc-car-tile-tab-selector-label__inactive{{?}}" for="tab-{{=tab.id}}-{{=it.id}}-{{=randomNumberForids}}">
							{{=window.i18n['sc.tiles.tabs.'+tab.type]||'sc.tiles.tabs.'+tab.type}}
							{{? it.type === 'U' && tab.type ==='emissions'}}
								<a href="#fn_original_state" class="audi-j-footnote-reference" target="_self"><sup class="audi-footnote-anchor__text"></sup></a>
							{{?}}
						</label>
						<span class="sc-car-tile-tab-selector-label-spacer"></span>
					{{?}}
				{{~}}
			{{?}}
		</div>
		<div class="sc-car-tile-tabs-content sc-car-tile-tabs-content-custom">
			{{? it.tileTabs.consumptionEmission.available}}
			<div class="sc-car-tile-tab-elements" data-tabId="{{=it.tileTabs.consumptionEmission.tabId}}">
					<sc-generic-feature-app-element data-id="consumption-tiles-element-{{=randomNumberForids}}-{{=it.id}}" data-config='{"componentName": "ConsumptionTileElement","props": {"vehicleId": "{{=it.id}}", "order": {{=JSON.stringify(it.tileTabs.consumptionEmission.order)}}, "displayedItems": {{=JSON.stringify(it.tileTabs.consumptionEmission.displayedItems)}}}}'>
					</sc-generic-feature-app-element>
				</div>
			{{?}}
			{{? it.tileTabs.warranty.available}}
				<div class="sc-car-tile-tab-elements" data-tabId="{{=it.tileTabs.warranty.tabId}}">
					{{? it.tileTabs.useWarrantyFA }}
						<sc-generic-feature-app-element data-id="warranty-tiles-element-{{=randomNumberForids}}-{{=it.id}}" data-config='{"compactDisplay": true, "vehicleId": "{{=it.id}}", "assetUrl": "https://fa-vtp-warranty.cdn.prod.arcade.apps.one.audi/2.10.0/static/"}' data-base="https://fa-vtp-warranty.cdn.prod.arcade.apps.one.audi/2.10.0/">
						</sc-generic-feature-app-element>
					{{??}}
						{{~it.tileTabs.warranty.items :warrantyItem}}
							<div class="sc-tile-tab-element sc-tile-tab-element--warranty sc-tile-advantages-info {{? warrantyItem.customIcon }} {{=warrantyItem.cssClass}}{{?}}">
								{{? !warrantyItem.customIcon }}
									<svg class="sc-tile-tab-element-icon {{=warrantyItem.cssClass}}">
										<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/highlight/"+warrantyItem.cssKey+"-large.svg#"+warrantyItem.cssClass}}></use>
									</svg>
								{{?}}
								{{? warrantyItem.warrantyFormattedText != "" }}
									<p class="sc-tile-tab-element--warranty-label audi-copy-s">{{=warrantyItem.warrantyFormattedText}}</p>
								{{?}}
								{{? warrantyItem.showInfoIcon}}
									<a class="sc-tile-tab-element-info sc-info-hover-icon nm-layerLink" href="{{=warrantyItem.infoIconUrl}}">
										<svg class="nm-icon-system-info-small">
											<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-info-small.svg#nm-icon-system-info-small"}}></use>
										</svg>
									</a>
								{{?}}
							</div>
						{{~}}
					{{?}}
				</div>
			{{?}}
			{{? it.tileTabs.advantages.available}}
				<div class="sc-car-tile-tab-elements" data-tabId="{{=it.tileTabs.advantages.tabId}}">
					{{~it.tileTabs.advantages.items :advantage :index}}
						{{? index < 4}}
							{{? advantage.filterText}}
								<div class="sc-tile-tab-element sc-tile-tab-element--advantage audi-copy-s">
									<span>{{=advantage.filterText}}</span>
									{{? advantage.layerUrl}}
										<a class="sc-tile-tab-element-info sc-info-hover-icon nm-layerLink" href="{{=advantage.layerUrl}}">
											<svg class="nm-icon-system-info-small">
												<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-info-small.svg#nm-icon-system-info-small"}}></use>
											</svg>
										</a>
									{{?}}
								</div>
							{{?}}
						{{?? index == 4}}
							<div class="sc-tile-tab-newline"></div>
							<button class="audi-link-s audi-link-s--arrow" href="{{=it.vehicleUrl}}">
								<span class="audi-link-s__text">{{=window.i18n['sc.tiles.tabs.advantages.more']||'sc.tiles.tabs.advantages.more'}}</span>
								<svg class="audi-link-s__icon">
									<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
								</svg>
							</button>
						{{?}}
					{{~}}
			{{?}}

			{{? it.tileTabs.environmental.available}}
				<div class="sc-car-tile-tab-elements sc-car-tile-tab-elements-env" data-tabId="{{=it.tileTabs.environmental.tabId}}">
					{{~it.tileTabs.environmental.items :envItem}}
						<div class="sc-tile-tab-element--environmental-label audi-copy-s">
							<img class="sc-tile-tab-element--environmental-label-icon" src={{=envItem.imageUrl}} alt="{{=envItem.altImageText}}"/>
							<popover-trigger class="sc-popover-trigger" role="tooltip">
								<svg class="nm-audi-system-info-small" viewBox="0 0 24 24">
									<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/audi-system-info-small.svg#audi-icon-system-info-small"}}></use>
								</svg>
							</popover-trigger>
							<div class="sc-popover-content">
								{{=envItem.infoIconText}}
							</div>
						</div>
					{{~}}
				</div>
			{{?}}
		</div>
	</div>
`;
export {exports as vehicleTabTemplate};
