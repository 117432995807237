import {vehicleTilesTop} from './vehicle-tiles-top';
import {vehicleTilesBottom} from './vehicle-tiles-bottom';
import {vehicleTilesDealerContact} from './vehicle-tiles-dealer-contact';
import {vehicleTilesDealerLabel} from './vehicle-tiles-dealer-label';
import {vehicleTilesOrderStatus} from './vehicle-tiles-order-status';
import {vehicleExtIntColors} from './vehicle-ext-int-color';
import {TECHDATA_UTILS} from '../../../stockcars-utils-bundle';


const exports = `
	${vehicleTilesTop}
			<div class="sc-details-list">
				${vehicleTilesDealerLabel}
				${vehicleTilesDealerContact}
				{{? vehicle && vehicle.powerDisplay && !!vehicle.powerDisplay }}
					<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-power-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/engine-data/power-small.svg#power-small"}}></use></svg> {{=window['stockcars-utils-bundle'].TECHDATA_UTILS.checkForEnginePowerBoostFootnote({vehicle,techDataKey:"powerDisplay", techDataValue:vehicle.powerDisplay})}}
					<a href="#fn_technicaldata_max-output" class="audi-j-footnote-reference" target="_self"><sup class="audi-footnote-anchor__text"></sup></a>
				</p>
				{{?}}
				{{? vehicle && vehicle.fuelTypes}}
					<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-fuel-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/engine-data/fuel-small.svg#fuel-small"}}></use></svg> {{=vehicle.fuelTypes}}</p>
				{{?}}
				{{? vehicle && vehicle.gearboxDisplay && !!vehicle.gearboxDisplay }}
					<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-transmission-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/engine-data/transmission-small.svg#transmission-small"}}></use></svg> {{=vehicle.gearboxDisplay}}</p>
				{{?}}
				${vehicleExtIntColors}
				${vehicleTilesOrderStatus}
			</div>

			{{? vehicle}}
				{{? vehicle.tileTabs}}
					<vehicle-tab-element class="sc-vehicle-tab-element" data-tile-tabs-options='{{=vehicle.tileTabs}}' data-vehicle-id="{{=vehicle.id}}" data-vehicle-url="{{=vehicle.url}}" data-consumption-options='{"consumption":true, "emission":true, "efficiency":true, "isElectrical":{{=vehicle.isElectrical}}}'></vehicle-tab-element>
				{{?}}
			{{?}}
	${vehicleTilesBottom}
`;

export {exports as vehicleTilesTemplateNCOPT1};
