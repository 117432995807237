import {locationController} from './location-controller';
import {locationConfigurationModel} from './location-config-model';
import {dom} from 'core-utils';
import {googleController} from './google-controller';
import {locationModel} from './location-model';

export default class LocationFilterElement extends HTMLElement {
	constructor() {
		super();
		this._mapElement = null;
		this._map = null;
		this._circle = null;
	}

	/**
	 * static default values
	 * @returns {{selectorMapElement: string}} defaults
	 */
	static get defaults() {
		return {
			selectorMapElement: '.sc-lf .sc-lf-map-container .sc-lf-map'
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		this._mapElement = dom.getElement(LocationFilterElement.defaults.selectorMapElement, this);
		locationModel.addObserver(this);
		this._initialize();
		this.update();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} void
	 */
	disconnectedCallback() {
		googleController.removeResizeListener();
	}

	/**
	 * inital create map element
	 * @private
	 * @returns {void} void
	 */
	_createMap() {
		const {DefaultDistanceKm, DefaultMapCenter} = locationConfigurationModel.configuration;
		const zoom = this._getZoomLevel(DefaultDistanceKm);
		const defaultMapCenterLatLng = (DefaultMapCenter || '').split(',');
		this._map = googleController.getMap(this._mapElement, zoom, defaultMapCenterLatLng);
		googleController.addResizeHandler(this._googleResizeHandler.bind(this), this._map);
	}

	/**
	 * get zoom level
	 * @private
	 * @param {defaultDistanceKm_} defaultDistanceKm_ - distance in km
	 * @returns {string} zoom level
	 */
	_getZoomLevel(defaultDistanceKm_ = false) {
		let zoomLevel = parseInt(SETUPS.get('stockcar.location.zoomlevel'), 10);
		if (this._locationSelected()) {
			if (!defaultDistanceKm_) {
				zoomLevel = Math.round(14 - Math.log(Number(locationModel.location.distance || locationConfigurationModel.configuration.DefaultDistanceKm)) / Math.LN2);
			}
			else {
				zoomLevel = locationController.getCalculatedZoom(defaultDistanceKm_);
			}
		}
		return zoomLevel;
	}

	/**
	 * locationSelected
	 * @private
	 * @returns {Boolean} true if location is selected (input field not empty)
	 */
	_locationSelected() {
		const location = locationModel.location;
		return (Object.keys(location).length > 0 && location.address && location.address.length > 0);
	}

	/**
	 * google resize handler callback function
	 * @param {google.mao} _map map instance
	 * @private
	 * @returns {void} void
	 */
	_googleResizeHandler(_map) {
		const center = _map.getCenter();
		const _zoom = this._getZoomLevel();

		google.maps.event.trigger(_map, 'resize');
		_map.setCenter(center);
		_map.setZoom(_zoom);

		if (this._circle && this._circle.map) {
			const _circle = this._getCircleForMap(_map);
			_circle.setCenter(center);
			_circle.setRadius(Number(_zoom) * 1000);
			_map.fitBounds(_circle.getBounds());
		}
	}

	/**
	 * _initialize init location
	 * @returns {Promise<void>} void
	 * @private
	 */
	async _initialize() {
		if (!locationConfigurationModel.mapsApiLoaded) {
			this._setLocationConfig();
			if (!window.google) {
				await locationController.loadMapApi();
			}
			locationConfigurationModel.mapsApiLoaded = true;
		}
		this._createMap();
	}

	/**
	 * set initial location config
	 * @private
	 * @returns {void} void
	 */
	_setLocationConfig() {
		const locationProperties = {
			dshConfig: this.getAttribute('data-dsh-config'),
			dshDistanceUnit: this.getAttribute('data-dsh-distance-unit'),
			mapsApiUrl: this.getAttribute('data-url')
		};
		locationController.setLocationConfig(locationProperties);
	}

	/**
	 * _getCircleForMap - removes old circle instance and creates new
	 * @param {google.map} map_ map instance
	 * @returns {google.map.circle} circle
	 * @private
	 */
	_getCircleForMap(map_) {
		if (this._circle) {
			this._removeCircleFromMap();
		}
		this._circle = googleController.getCircle(map_); // Add circle overlay and bind to marker --> default no circle visible

		return this._circle;
	}

	/**
	 * remove circle
	 * @returns {void}
	 */
	_removeCircleFromMap() {
		if (this._circle) {
			this._circle.setMap(null);
		}
	}

	/**
	 * _updateMap
	 * @private
	 * @returns {void} void
	 */
	_updateMap() {
		const distance = locationModel.location.distance;

		if (distance) {
			if (locationModel.location.latLng) {
				const circle = this._getCircleForMap(this._map);
				const center = locationModel.location.latLng;
				circle.setCenter(center);
				circle.setRadius(Number(distance) * 1000);
				this._map.setCenter(center);
				this._map.fitBounds(circle.getBounds());
			}
			else {
				this._removeCircleFromMap();
			}
			const calculatedZoom = this._getZoomLevel(distance);
			this._map.setZoom(calculatedZoom);
		}
	}

	/**
	 * update function for location model changes
	 * @returns {void} void
	 */
	async update() {
		await googleController.isGoogleAvailablePolling().catch(error => console.error(error));
		this._updateMap();
	}
}

if (window.customElements.get('location-filter-element') === undefined) {
	window.customElements.define('location-filter-element', LocationFilterElement);
}
