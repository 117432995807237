const exports = `
{{? vehicle.isAvailableSoonVehicle && window.i18n['sc.tiles.available.from.abbreviation'] !== ''}}
	<span>{{=window.i18n['sc.tiles.available.from.abbreviation']}}
		<a href='#fn_expected_mileage' class='audi-j-footnote-reference' target='_self'><sup class='audi-footnote-anchor__text'></sup></a>
		&nbsp;
	</span>
{{?}}
`;

export {exports as vehicleTilesAvailableFrom};
