import {contactButtonTemplateSpain} from './contact-button-template-spain';
import {contactLinkTemplateSpain} from './contact-link-template-spain';
import {ecomButtonTemplateSpain} from './ecom-button-template-spain';
import {leasingButtonTemplateSpain} from './leasing-button-template-spain';

const exports = `
{{~ it.data.filter(item => item.type.startsWith('ecom')) :item}}
	{{? item.type === 'ecom' || item.type === "ecom-link"}}
		${ecomButtonTemplateSpain}
	{{?}}
{{~}}
{{~ it.data :item}}
	{{? item.type ==="contact" || item.type ==="nws"}}
		${contactButtonTemplateSpain}
	{{?? item.type === "link" }}
		${contactLinkTemplateSpain}
	{{?? item.type === "leasing"}}
		${leasingButtonTemplateSpain}
	{{?}}
{{~}}
`;

export {exports as vehicleTilesButtonsTemplateSpain};
