import {scsController} from '../filter/scs-controller';
import {FORMAT_UTILS, scPage} from '../../stockcars-utils-bundle';
import {FilterDTO} from '../../stockcars-filter-bundle';

export default class HitsReplacingButtonsElement extends HTMLElement {
	constructor() {
		super();
	}

	async connectedCallback() {
		this._initializeProperties();
		this._patchCountPatterns();
	}

	/**
	 * getter for defaults
	 */
	static get defaults() {
		return {
			elementName: 'hits-replacing-buttons-element',
			pattern: '{{filtered_count_vehicle}}',
			selectorButtonText: '.hits-replacing-button-text',
			selectorButton: '.hits-replacing-button'
		};
	}

	/**
	 * _patchCountPatterns
	 * @private
	 * @returns {void} void
	 */
	async _patchCountPatterns() {
		const {original = ''} = this.buttonText.dataset;
		if (original.includes(HitsReplacingButtonsElement.defaults.pattern)) {
			const totalCount = await this._getTotalCountForLink();
			this.buttonText.innerHTML = original.replace(HitsReplacingButtonsElement.defaults.pattern, totalCount);
			this.button.setAttribute('data-state', 'active');
		}
	}

	/**
	 * _getTotalCountForLink
	 * @returns {Promise<string>} totalCount wrapped in promise
	 * @private
	 */
	async _getTotalCountForLink() {
		const {linkUrl = ''} = this.dataset;
		const {filters, presets} = HitsReplacingButtonsElement.extractFiltersAndPresetsFromHash(linkUrl);
		const {totalCount = ''} = await scsController.getFilterResponse(filters, presets).catch(err => {
			console.log('Could not get total count', err);
		});
		return FORMAT_UTILS.formatCount(totalCount);
	}

	/**
	 * extract filters and presets from hash
	 * @param {string} hash_ location hash
	 * @returns {object} object with presets and filters map {{filters: Map<any, any>, presets: Map<any, any>}}
	 */
	static extractFiltersAndPresetsFromHash(hash_) {
		let filters = new Map();
		let presets = new Map();
		if (!!hash_) {
			const decodedHash = decodeURIComponent(hash_);
			const filtersHash = scPage.getFilterHash(decodedHash, 'filter');
			if (!!filtersHash) {
				filters = HitsReplacingButtonsElement._parseFilterStringToMap(filtersHash, false);
			}

			const presetsHash = scPage.getFilterHash(decodedHash, 'preset');
			if (!!presetsHash) {
				presets = HitsReplacingButtonsElement._parseFilterStringToMap(presetsHash, true);
			}
			// clear current url hash
			scPage.cleanupFilterHash();
		}
		return {filters, presets};
	}

	/**
	 * parse filters (or presets) from a string to a map of FilterDTOs
	 * @param {string} filterString_ - string with comma separated filters
	 * @param {boolean} preset_ flag if presets should be used
	 * @returns {Map<FilterDTO>} a map of filters (or presets)
	 */
	static _parseFilterStringToMap(filterString_, preset_ = false) {
		const currentFiltersMap = new Map();
		const filtersRawArray = filterString_.split(',');
		filtersRawArray.forEach(filter => {
			const filterDTO = FilterDTO.initFromFilterString(filter, true, preset_);
			if (filterDTO) {
				currentFiltersMap.set(filterDTO.id, filterDTO);
			}
		});
		return currentFiltersMap;
	}

	/**
	 * _initializeProperties
	 * @private
	 * @returns {void} void
	 */
	_initializeProperties() {
		this.button = this.querySelector(HitsReplacingButtonsElement.defaults.selectorButton);
		this.buttonText = this.querySelector(HitsReplacingButtonsElement.defaults.selectorButtonText);
	}

}

if (window.customElements.get(HitsReplacingButtonsElement.defaults.elementName) === undefined) {
	window.customElements.define(HitsReplacingButtonsElement.defaults.elementName, HitsReplacingButtonsElement);
}
