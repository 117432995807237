import {locationConfigurationModel} from './location-config-model';
import {dom} from 'core-utils';
import {googleController} from './google-controller';
import {locationController} from './location-controller';
import {locationModel} from './location-model';


export default class LocationFilterFormElement extends HTMLElement {
	constructor() {
		super();
		this._controlKeyCodes = [13, 38, 40]; // 13 Enter Key, 38 Key UP, 40 Key DOWN
	}

	/**
	 * static default values
	 * @returns {object} defaults
	 */
	static get defaults() {
		return {
			distanceOptionsId: '#sc-lf-search-options-distance',
			addressInputFieldId: '#sc-lf-search-input-address',
			resultsListSelector: '.sc-lf .sc-lf-search-result-list',
			errorElements: '.sc-lf .error p',
			errorElementsContainer: '.sc-lf .error',
			classSelectedSuggestion: 'selected-suggestion',
			searchListItem: '.sc-j-search-list-item',
			resetButtonId: '#sc-lf-search form',
			gpsButton: '.sc-lf .sc-lf-search-geo-location',
			submitButton: '#sc-lf-search-submit'
		};
	}

	/**
	 * connected callback function
	 * @returns {void} void
	 */
	connectedCallback() {
		this._initialize();
		this._domDelegate = dom.getEventDelegate('body');
		this._addEvents();
		locationModel.addObserver(this);
		this.update();
	}

	/**
	 * disconnectedCallback function
	 * @returns {void} void
	 */
	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	 * _initialize init form
	 * @private
	 * @returns {void} void
	 */
	_initialize() {
		this._initializeDomProperties();
		this._bindContext();
		const {DefaultDistanceKm} = locationConfigurationModel.configuration || '';
		this._setDistanceValue(DefaultDistanceKm);
		this.useShortZipCodes = SETUPS.get('scopes.use.short.zipcodes');
	}

	/**
	 * _initializeDomProperties
	 * @private
	 * @returns {void} void
	 */
	_initializeDomProperties() {
		this._distanceItem = dom.getElement(LocationFilterFormElement.defaults.distanceOptionsId, this);
		this._inputField = dom.getElement(LocationFilterFormElement.defaults.addressInputFieldId, this);
		this._resultsElement = dom.getElement(LocationFilterFormElement.defaults.resultsListSelector, this);
		this._errorElements = dom.getElementsArray(LocationFilterFormElement.defaults.errorElements, this);
		this._errorElementsContainer = dom.getElement(LocationFilterFormElement.defaults.errorElementsContainer, this);
		this._resetButton = dom.getElement(LocationFilterFormElement.defaults.resetButtonId, this);
		this._gpsButton = dom.getElement(LocationFilterFormElement.defaults.gpsButton, this);
		this._submitButton = dom.getElement(LocationFilterFormElement.defaults.submitButton, this);
	}

	/**
	 * _bindContext
	 * @private
	 * @returns {void} void
	 */
	_bindContext() {
		this._enterKeyUpHandler = this._enterKeyUpHandler.bind(this);
		this._handleKeyStroke = this._handleKeyStroke.bind(this);
		this._resultClickHandler = this._resultClickHandler.bind(this);
		this._changeDistanceHandler = this._changeDistanceHandler.bind(this);
		this._inputResetHandler = this._inputResetHandler.bind(this);
		this._handleGpsButtonClick = this._handleGpsButtonClick.bind(this);
		this._submitButtonClickHandler = this._submitButtonClickHandler.bind(this);
	}

	/**
	 * _setDistanceValue
	 * @param {string} distance distance value
	 * @private
	 * @returns {void} void
	 */
	_setDistanceValue(distance = '') {
		let newIndex = 0;
		const options = [].slice.call(this._distanceItem.options);
		options.forEach((option, index) => {
			if (option.value === distance) {
				newIndex = index;
			}
		});

		this._distanceItem.options.selectedIndex = newIndex;
	}

	/**
	 * _setAddressInputValue
	 * @param {string} address address value
	 * @private
	 * @returns {void} void
	 */
	_setAddressInputValue(address = '') {
		this._inputField.value = address;
	}

	/**
	 * _addEvents
	 * @private
	 * @returns {void} void
	 */
	_addEvents() {
		this._inputField.addEventListener('keyup', dom.debounce(this._enterKeyUpHandler, 250));
		this._inputField.addEventListener('keydown', this._handleKeyStroke);
		this._distanceItem.addEventListener('change', this._changeDistanceHandler);
		this._resetButton.addEventListener('reset', this._inputResetHandler);
		this._gpsButton.addEventListener('click', this._handleGpsButtonClick);
		this._submitButton.addEventListener('click', this._submitButtonClickHandler);
		this._domDelegate.on('click', LocationFilterFormElement.defaults.searchListItem, this._resultClickHandler);
	}

	/**
	 * _removeEvents
	 * @private
	 * @returns {void} void
	 */
	_removeEvents() {
		this._inputField.removeEventListener('keyup', this._enterKeyUpHandler);
		this._inputField.removeEventListener('keydown', this._handleKeyStroke);
		this._distanceItem.removeEventListener('change', this._changeDistanceHandler);
		this._resetButton.removeEventListener('reset', this._inputResetHandler);
		this._gpsButton.removeEventListener('click', this._handleGpsButtonClick);
		this._submitButton.removeEventListener('click', this._submitButtonClickHandler);
		this._domDelegate.off('click', LocationFilterFormElement.defaults.searchListItem, this._resultClickHandler);
	}

	/**
	 * _submitButtonClickHandler
	 * @private
	 * @returns {void} void
	 */
	_submitButtonClickHandler() {
		locationController.submitLocationFilter();
		this._setSubmitButtonInactive();
	}

	/**
	 * _setSubmitButtonActive
	 * @private
	 * @returns {void} void
	 */
	_setSubmitButtonActive() {
		this._submitButton.classList.remove('inactive');
	}

	/**
	 * _setSubmitButtonInactive
	 * @private
	 * @returns {void} void
	 */
	_setSubmitButtonInactive() {
		this._submitButton.classList.add('inactive');
	}

	/**
	 * gps button click handler
	 * @returns {Promise<void>} void
	 * @private
	 */
	async _handleGpsButtonClick() {
		this._showGetLocationError = this._showGetLocationError.bind(this);
		const position = await locationController.getGeoLocation().catch(this._showGetLocationError);
		const {latitude, longitude} = position.coords;
		const formattedAddress = await googleController.getAddressFromLatLng(latitude, longitude).catch(this._showGetLocationError);
		const latLng = googleController.getLatLng(latitude, longitude);

		const locationUpdateObject = {
			address: formattedAddress.replace(',', ''),
			latLng,
			formattedAddress
		};

		locationController.updateLocationModel(locationUpdateObject);
		this._setSubmitButtonActive();
	}

	_showGetLocationError() {
		this._hideError();
		this._showError('.sc-lf-search-error-3');
	}

	/**
	 * _inputResetHandler
	 * @private
	 * @returns {void} void
	 */
	_inputResetHandler() {
		this._hideResults();
		this._hideError();
		locationController.resetLocation();
		this._inputField.focus();
		this._setSubmitButtonInactive();
	}

	/**
	 * _changeDistanceHandler
	 * @private
	 * @returns {void} void
	 */
	_changeDistanceHandler() {
		const distance = this._distanceItem.value;
		locationController.updateLocationModel({distance});
		if (!!locationModel.location.address) {
			this._setSubmitButtonActive();
		}
	}

	/**
	 * _enterKeyUpHandler
	 * @private
	 * @param {event} event event
	 * @returns {void} void
	 */
	_enterKeyUpHandler(event) {
		event.preventDefault();
		if (this._controlKeyCodes.indexOf(event.keyCode) < 0) {
			if (event.target.value.length >= (this.useShortZipCodes ? 2 : 3)) {
				this._showResultsList(event.target.value);
			}
			else {
				this._hideResults();
			}
		}
	}

	/**
	 * _handleKeyStroke
	 * @private
	 * @param {event} event_ event
	 * @returns {void} void
	 */
	_handleKeyStroke(event_) {
		const isResultsListVisible = this._resultsElement.classList.contains('show');
		const resultListHasChildren = this._resultsElement.childElementCount > 0;

		if (isResultsListVisible && resultListHasChildren) {
			const suggestionsItems = dom.getElementsArray('.sc-j-search-list-item', this);
			const activeIndex = this._getActiveSearchListItemIndex(suggestionsItems);
			// HANDLE ARROW UP AND DOWN
			if ((event_.keyCode === 38 || event_.keyCode === 40)) {
				event_.preventDefault();

				if (event_.keyCode === 38) {
					this._handleArrowUpKey(suggestionsItems, activeIndex);
				}
				else {
					this._handleArrowDownKey(suggestionsItems, activeIndex);
				}
			}
			// HANDLE ENTER
			else if (event_.keyCode === 13) {
				this._handleEnterKey(suggestionsItems, activeIndex);
			}
			// HANDLE ESC
			else if (event_.keyCode === 27) {
				event_.preventDefault();
				this._hideResults();
			}
		}
	}

	/**
	 * _handleArrowUpKey
	 * @param {array} suggestionsItems suggestionsItems
	 * @param {number} activeIndex activeIndex
	 * @private
	 * @returns {void} void
	 */
	_handleArrowUpKey(suggestionsItems, activeIndex) {
		if (!activeIndex) {
			this._setItemActiveAtIndex(suggestionsItems, suggestionsItems.length - 1);
		}
		else {
			this._setItemActiveAtIndex(suggestionsItems, activeIndex - 1);
		}
	}

	/**
	 * _handleArrowDownKey
	 * @param {array} suggestionsItems suggestionsItems
	 * @param {number} activeIndex activeIndex
	 * @private
	 * @returns {void} void
	 */
	_handleArrowDownKey(suggestionsItems, activeIndex) {
		if ((activeIndex === null) || (activeIndex === suggestionsItems.length - 1)) {
			this._setItemActiveAtIndex(suggestionsItems, 0);
		}
		else {
			this._setItemActiveAtIndex(suggestionsItems, activeIndex + 1);
		}
	}

	/**
	 * _setItemActiveAtIndex
	 * @param {array} items items
	 * @param {number} index index
	 * @private
	 * @returns {void} void
	 */
	_setItemActiveAtIndex(items, index) {
		items[index].classList.add(LocationFilterFormElement.defaults.classSelectedSuggestion);
	}

	/**
	 * _getActiveSearchListItemIndex
	 * @param {array} suggestionsItems suggestionsItems
	 * @returns {number} active item
	 * @private
	 */
	_getActiveSearchListItemIndex(suggestionsItems) {
		let activeIndex = null;
		suggestionsItems.forEach((suggestionsItem, index) => {
			if (suggestionsItem.classList.contains(LocationFilterFormElement.defaults.classSelectedSuggestion)) {
				activeIndex = index;
				suggestionsItem.classList.remove(LocationFilterFormElement.defaults.classSelectedSuggestion);
			}
		});
		return activeIndex;
	}

	/**
	 * _resultClickHandler
	 * @param {event} event click event
	 * @returns {Promise<void>} void
	 * @private
	 */
	async _resultClickHandler(event) {
		event.preventDefault();
		await this._setLocationForResultElement(event.target);
	}

	/**
	 * _handleEnterKey
	 * @param {array} suggestionsItems suggestionsItems
	 * @param {number} activeIndex activeIndex
	 * @returns {Promise<void>} void
	 * @private
	 */
	async _handleEnterKey(suggestionsItems = [], activeIndex = 0) {
		if (suggestionsItems.length) {
			const suggestionItemAtActiveIndex = suggestionsItems[activeIndex || 0];
			await this._setLocationForResultElement(suggestionItemAtActiveIndex);
		}
	}

	/**
	 * _setLocationForResultElement
	 * @param {HTMLElement} element element
	 * @returns {Promise<void>} void
	 * @private
	 */
	async _setLocationForResultElement(element) {
		const address = element.getAttribute('data-search');
		const placeId = element.getAttribute('data-place');
		const place = await googleController.getPlaceDetail(placeId).catch(error => console.error(error));
		const latLng = googleController.getLatLng(place[0].geometry.location.lat(), place[0].geometry.location.lng());
		const formattedAddress = locationController.getCleanedFormattedAddress(place[0].formatted_address);

		const locationUpdateObject = {
			address: address.replace(',', ''),
			latLng,
			formattedAddress
		};

		locationController.updateLocationModel(locationUpdateObject);
		this._setSubmitButtonActive();
		this._hideResults();
	}

	/**
	 * _showResultsList
	 * @param {string} inputValue inputValue
	 * @returns {Promise<void>} void
	 * @private
	 */
	async _showResultsList(inputValue = '') {
		const {LocaleCountry} = locationConfigurationModel.configuration;
		let country = (LocaleCountry || '').toLowerCase();

		switch (country) {
			case 'fr':
				country = ['fr', 'mc', 'ad'];
				break;
			case 'ch':
				country = ['ch', 'li'];
				break;
			case 'za':
				country = ['za', 'bw', 'na'];
				break;
			default:
				country = LocaleCountry;
		}
		const results = await googleController.getCityNameSuggestions(inputValue, country).catch(error => {
			console.info(error);
			this._hideResults();
			this._hideError();
			this._showError('.sc-lf-search-error-2');
		});

		if (results && results.length) {
			this._hideResults();
			this._hideError();
			this._renderSearchListResults(results);
		}
	}

	/**
	 * _renderSearchListResults
	 * @param {array} results_ results_
	 * @private
	 * @returns {void} void
	 */
	_renderSearchListResults(results_ = []) {
		const searchResultList = dom.getElement('.sc-lf .sc-lf-search-result-list', this);
		results_.forEach(result => {
			const city = result.city ? result.city : '';
			const placeId = result.place_id ? result.place_id : '';
			const resultListItem = locationController.getGeneratedSearchListItem(city, placeId);
			searchResultList.appendChild(resultListItem);
		});
		searchResultList.classList.add('show');
	}

	/**
	 * _hideResults
	 * @private
	 * @returns {void} void
	 */
	_hideResults() {
		this._resultsElement.innerHTML = '';
		this._resultsElement.classList.remove('show');
	}

	/**
	 * _showError
	 * @param {string} errorClass_ error class
	 * @private
	 * @returns {void} void
	 */
	_showError(errorClass_) {
		this._errorElementsContainer.classList.add('show');
		const errorElement = dom.getElement(errorClass_, this._errorElementsContainer);
		errorElement.classList.add('show');
	}

	/**
	 * hideError
	 * @method hideError
	 * @return {void} returns nothing
	 */
	_hideError() {
		this._errorElementsContainer.classList.remove('show');
		this._errorElements.forEach((errorElement) => {
			errorElement.classList.remove('show');
		});
	}

	/**
	 * update
	 * update function for location model changes
	 * @returns {Promise<void>} void
	 */
	async update() {
		await googleController.isGoogleAvailablePolling().catch(error => console.error(error));
		const newLocation = locationModel.location;
		if (newLocation.formattedAddress && newLocation.distance) {
			this._setAddressInputValue(newLocation.formattedAddress);
			this._setDistanceValue(newLocation.distance);
		}
		else {
			const distance = newLocation && newLocation.distance ? newLocation.distance : locationConfigurationModel.configuration.DefaultDistanceKm;
			this._setAddressInputValue();
			this._setDistanceValue(distance);
		}
	}
}

if (window.customElements.get('location-filter-form-element') === undefined) {
	window.customElements.define('location-filter-form-element', LocationFilterFormElement);
}
