import {dom} from 'core-utils';
import {searchAgentAuthController} from './search-agent-auth-controller';

export default class SearchAgentAuthenticationElement extends HTMLElement {
	/**
	 * constructor
	 * @returns {void} returns nothing
	 */
	constructor() {
		super();
	}

	static get defaults() {
		return {
			login: 'sc-j-search-agent-login',
			logout: 'sc-j-search-agent-logout'
		};
	}
	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this.bindedUpdate = this.update.bind(this);
		this._addEvents();
		this._setTargetUrl();
		this._getAuthenticationState();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	 * _addEvents - adds events when element is connected
	 * @private
	 * @returns {void} returns nothing
	 */
	_addEvents() {
		window.microkernel.stateRegistry.subscribeToStore('dbadAuthServiceStore', this.bindedUpdate);
	}

	/**
	 * _removeEvents - removes events when element is disconnected
	 * @private
	 * @returns {void} returns nothing
	 */
	_removeEvents() {
		window.microkernel.stateRegistry.unsubscribeFromStore('dbadAuthServiceStore', this.bindedUpdate);
	}

	_setTargetUrl() {
		const dashboardUrl = SETUPS.get('stockcar.sca.dashboard.url');
		const login = dom.getElement('.search-agent-authentication-login', this);
		const logout = dom.getElement('.search-agent-authentication-logout', this);
		login.setAttribute('data-target-url', dashboardUrl);
		logout.setAttribute('data-target-url', location.pathname);
	}

	async _getAuthenticationState() {
		const {isAuthenticated} = await searchAgentAuthController.getAuthenticationState().catch(err => {
			console.log(err);
			this.setAttribute('data-skeleton', "false");
		});
		if (isAuthenticated) {
			this.classList.add(SearchAgentAuthenticationElement.defaults.logout);
			this.classList.remove(SearchAgentAuthenticationElement.defaults.login);
		}
		else {
			this.classList.add(SearchAgentAuthenticationElement.defaults.login);
			this.classList.remove(SearchAgentAuthenticationElement.defaults.logout);
		}
		this.setAttribute('data-skeleton', "false");
		this.setAttribute('data-authentication-state', isAuthenticated || false);
	}

	update() {
		this._getAuthenticationState();
	}
}

if (!window.customElements.get('search-agent-authentication', SearchAgentAuthenticationElement)) {
	window.customElements.define('search-agent-authentication', SearchAgentAuthenticationElement);
}
