const exports = `
	<div class="modal-layer__inner">
		<div class="modal-layer--close">
			<div class="modal-layer--close-button">
				<svg class="nm-icon-cancel">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel-newci-small.svg#audi-icon-cancel-newci-small"}}></use>
				</svg>
			</div>
		</div>
		<div class="modal-layer__inner-content" data-layer-options='{"addCssClass": "sc-modal-layer-wrapper"}'>
			<form id="sc-iframe-post-form" action="{{=it.iframeUrl}}" target="{{? it.targetType && it.targetType === '_blank'}}_blank{{??}}sc-post-iframe{{?}}" method="post" hidden></form>
			<iframe id="sc-post-form-iframe" name="sc-post-iframe" src="" width="100%"></iframe>
		</div>
	</div>
`;

export {exports as iframePostModalLayerTemplate};
