import ContactFormElement from './stockcars/contactform/contactform-element';
import {globalEventEmitter} from './stockcars/event-emitter-proxy';

// Filter
import FilterChipsElement from './stockcars/filter/filter-chips/filter-chips-element';
import ScFilterChipFactory from './stockcars/filter/filter-chips/filter-chip-factory';
import {scFilterOptionsChips} from './stockcars/filter/filter-chips/filter-chips-template';

import {scsController} from './stockcars/filter/scs-controller';
import RequestController from './stockcars/filter/scs-request-controller';
import UrlSetups from './stockcars/filter/scs-url-setups';
import {svdModel} from './stockcars/filter/svd-model';
import ScsModelBase from './stockcars/filter/scs-model-base';

import {googleController, GoogleControllerClass} from './stockcars/location/google-controller';
import {locationConfigurationModel} from './stockcars/location/location-config-model';
import {locationController} from './stockcars/location/location-controller';
// location
import LocationFilterElement from './stockcars/location/location-filter-element';
import LocationFilterFormElement from './stockcars/location/location-filter-form-element';
import {locationModel} from './stockcars/location/location-model';
import {mediathekInfolayerTemplate} from './stockcars/mediathek/mediathek-infolayer-template';
import {scMediathekInfolayer} from './stockcars/mediathek/sc-mediathek-infolayer';

import {patternSc} from './stockcars/pattern-matching/pattern-sc';
import {patternScVehicleType} from './stockcars/pattern-matching/pattern-sc-vehicle-type';
import {presetsTemplate} from './stockcars/presets/dealer-branding/dealer-branding-presets-template';
import ScDealerPresetElement from './stockcars/presets/dealer-branding/sc-dealer-preset-element';
import {mandatoryAreaSearchTemplate} from './stockcars/presets/mandatory-area-search/mandatory-area-search-template';
import {
	scModelMandatoryAreaSearch,
	ScModelMandatoryAreaSearchClass
} from './stockcars/presets/mandatory-area-search/model-mandatory-area-search';
import {scViewMandatoryAreaSearch} from './stockcars/presets/mandatory-area-search/view-mandatory-area-search';
import {scInfoLayer} from './stockcars/sc-info-layer';
import searchAgentAuthenticationElement from './stockcars/search-agent/search-agent-authentication-element';
import {searchAgentController} from './stockcars/search-agent/search-agent-controller';
import SearchAgentDashboard from './stockcars/search-agent/search-agent-dashboard';
import SearchAgentDashboardItem from './stockcars/search-agent/search-agent-dashboard-item';
// SearchAgents
import SearchAgentLogin from './stockcars/search-agent/search-agent-login';
import {searchAgentObserver} from './stockcars/search-agent/search-agent-observer';
import {searchAgentAuthController} from './stockcars/search-agent/search-agent-auth-controller';

import NoResultsResetButtonElement from './stockcars/tiles/templates/no-results-reset-button-element';
import VehicleElement from './stockcars/vehicles/vehicles-element';
import VehicleTabElement from './stockcars/vehicles/vehicle-tab-element';
import {vehiclesController} from './stockcars/vehicles/vehicles-controller';

import ModalMandatoryAreaSearchLayerElement from './stockcars/presets/mandatory-area-search/modal-mandatory-area-search-layer-element';
import {modalLayerMandatoryAreasSearchTemplate} from './stockcars/presets/mandatory-area-search/modal-layer-mandatory-area-search-template';
import MasLocationIndicatorElement from './stockcars/presets/mandatory-area-search/mas-location-indicator-element';

import {searchAgentEntry} from './stockcars/search-agent/search-agent-entry';
import {searchAgentState} from './stockcars/search-agent/search-agent-state';

import DealerAddressElement from './stockcars/dealer-address/dealer-address-element';

import StockcarsEditorialTeaserElement from './stockcars/stockcars-editorial-teaser-element/stockcars-editorial-teaser-element';
import HitsReplacingButtonsElement from './stockcars/hits-replacing-buttons-element/hits-replacing-buttons-element';
import HitsloadingElement from './stockcars/hits-loading/hits-loading-element';
import FilterBackButtonElement from './stockcars/filter-back-button/filter-back-button-element';
import ToTopButtonElement from './stockcars/to-top-button/to-top-button-element';
import SortingDropdownElement from './stockcars/sorting-dropdown/sorting-dropdown-element';
import ScViewTilesTemplateLookup from './stockcars/tiles/sc-view-tiles-template-lookup';

import {BaseButtonLogic} from './stockcars/buttons/sc-base-button-logic';
import {VehicleTilesButtonElement} from './stockcars/vehicles/vehicle-tiles-button-element';
import {InfoButtonsElement} from './stockcars/buttons/info-buttons-element';

import {contactButtonTemplate} from './stockcars/buttons/templates/contact-button-template';
import {contactLinkTemplate} from './stockcars/buttons/templates/contact-link-template';
import {customButtonTemplate} from './stockcars/buttons/templates/custom-button-template';
import {phoneButtonTemplate} from './stockcars/buttons/templates/phone-button-template';
import {ecomButtonTemplate} from './stockcars/buttons/templates/ecom-button-template';
import {liveDemoButtonTemplate} from './stockcars/buttons/templates/live-demo-button-template';
import {leasingButtonTemplate} from './stockcars/buttons/templates/leasing-button-template';
import {liteReservationButtonTemplate} from "./stockcars/buttons/templates/lite-reservation-button-template";
import {reservationButtonTemplate} from "./stockcars/buttons/templates/reserve-button-template";

import IframePostModalLayerElement from './stockcars/iframe/iframe-post-modal-layer-element';
import GenericFeatureAppElement from './stockcars/generic-feature-app/generic-feature-app-element';
import FeatureAppCacheKiller from "./stockcars/generic-feature-app/sc-fa-cache-killer";

import {PopoverTrigger} from './stockcars/popover/sc-popover-trigger';
/** stockcars/crs-finance/sc-finance-layer
 * create public API accessible via the console
 */
window.stockcars = window.stockcars || {};

if (SETUPS) {
	window.stockcars.setups = SETUPS.__.stored;
}

export {
	svdModel,
	presetsTemplate,
	scViewMandatoryAreaSearch,
	mandatoryAreaSearchTemplate,
	patternSc,
	patternScVehicleType,
	scMediathekInfolayer,
	mediathekInfolayerTemplate,
	FilterChipsElement,
	ScsModelBase,
	scFilterOptionsChips,
	scInfoLayer,
	SearchAgentLogin,
	SearchAgentDashboard,
	SearchAgentDashboardItem,
	searchAgentController,
	searchAgentAuthenticationElement,
	scsController,
	RequestController,
	UrlSetups,
	vehiclesController,
	LocationFilterElement,
	ScModelMandatoryAreaSearchClass,
	MasLocationIndicatorElement,
	scModelMandatoryAreaSearch,
	GoogleControllerClass,
	googleController,
	ScDealerPresetElement,
	VehicleElement,
	VehicleTabElement,
	locationController,
	locationConfigurationModel,
	locationModel,
	LocationFilterFormElement,
	ScFilterChipFactory,
	globalEventEmitter,
	searchAgentObserver,
	searchAgentAuthController,
	NoResultsResetButtonElement,
	ContactFormElement,
	DealerAddressElement,
	searchAgentEntry,
	searchAgentState,
	StockcarsEditorialTeaserElement,
	HitsloadingElement,
	HitsReplacingButtonsElement,
	ToTopButtonElement,
	SortingDropdownElement,
	ScViewTilesTemplateLookup,
	ModalMandatoryAreaSearchLayerElement,
	modalLayerMandatoryAreasSearchTemplate,
	BaseButtonLogic,
	VehicleTilesButtonElement,
	FilterBackButtonElement,
	InfoButtonsElement,
	contactButtonTemplate,
	contactLinkTemplate,
	customButtonTemplate,
	phoneButtonTemplate,
	ecomButtonTemplate,
	liveDemoButtonTemplate,
	leasingButtonTemplate,
	liteReservationButtonTemplate,
	reservationButtonTemplate,
	IframePostModalLayerElement,
	GenericFeatureAppElement,
	FeatureAppCacheKiller,
	PopoverTrigger
};
