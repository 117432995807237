const exports = `
	{{? item.url && item.text}}
		{{? item.method === 'POST'}}
			<button class="sc-cta-button {{? item.primary}}audi-button{{??}}audi-button audi-button--ghost{{?}} js-modal-layer-link{{? item.css && item.css !== ''}} {{= item.css}}{{?}}" data-target-type="{{=item.target}}" data-layer-type="IframePostModalLayerElement" data-button-type="liveDemo" data-url="{{=item.url}}" data-pattern-url="{{=item.patternUrl}}">
				<span class="audi-button__text">{{=item.text}}</span>
			</button>
		{{??}}
			<a x-cq-linkchecker="skip" href="{{=item.url}}" data-pattern="{{=item.patternUrl}}" class="sc-cta-button {{? item.primary}}audi-button{{??}}audi-button audi-button--ghost{{?}}{{? item.layer}} nm-layerLink{{?}}{{? item.css && item.css !== ''}} {{= item.css}}{{?}}" target="{{=item.target}}" data-button-type="liveDemo">
				<span class="sc-hyphenate audi-copy-m">{{=item.text}}</span>
			</a>
		{{?}}
	{{?}}
`;
export {exports as liveDemoButtonTemplate};
