import {jsLoader} from 'core-utils';
import {FilterDTO} from '../../stockcars-filter-bundle';
import {scsController} from '../filter/scs-controller';
import {googleController} from './google-controller';
import {locationConfigurationModel} from './location-config-model';
import {locationModel} from './location-model';
import {STCK_STORE, SELECTORS, ACTIONS} from '@oneaudi/stck-store';

class LocationControllerClass {
	constructor() {
		STCK_STORE.observeStateFromStore(SELECTORS.FILTERS.getFilterItemsRaw, this.update.bind(this));
	}

	/**
	 * submitLocationFilter - submit location filter to scsController
	 * @returns {void} void
	 */
	submitLocationFilter() {
		const geoFilter = SELECTORS.FILTERS.getFilterDTOsMap(STCK_STORE.state).get('geo');
		const filter = geoFilter ? geoFilter : new FilterDTO({id: 'geo'});

		const locationFromModel = locationModel.location;
		const location = locationFromModel.address;
		const latitude = locationFromModel.latLng.lat();
		const longitude = locationFromModel.latLng.lng();
		const distance = locationFromModel.distance;
		const distanceUnit = locationFromModel.distanceUnit;

		if (location && latitude && longitude && distance && distanceUnit) {
			filter.setLocation({latitude, longitude, distance, distanceUnit, location});
			filter.activateFilter();
			this._setDistanceSorting();
			scsController.updateFilters({changedFiltersArray: [filter]});
		}
		else {
			console.warn('Missing value for set location');
		}
	}

	_setDistanceSorting() {
		const distanceSorting = SETUPS.get('stockcar.scs.distance.sort.param');
		if (!!distanceSorting) {
			STCK_STORE.dispatch(ACTIONS.SORTING.setSorting({sorting: {results: distanceSorting}}));
		}
	}

	/**
	 * loadMapApi
	 * @returns {Promise} jsLoader
	 */
	async loadMapApi() {
		const {mapApiUrl} = locationConfigurationModel.configuration || '';
		return jsLoader.loadURL(mapApiUrl);
	}

	/**
	 * setLocationConfig - set initial configuration in location configuration model
	 * @param {void} locationProperties locationProperties
	 * @returns {void} void}
	 */
	setLocationConfig(locationProperties) {
		try {
			const dshConfig = JSON.parse(locationProperties.dshConfig || '{}');
			const config = {
				mapApiUrl: locationProperties.mapsApiUrl,
				dshDistanceUnit: locationProperties.dshDistanceUnit
			};
			locationConfigurationModel.configuration = Object.assign({}, dshConfig, config);
		}
		catch (error) {
			console.error('Could not read config', error);
			throw error;
		}
	}

	/**
	 * updateLocationModel only changes needed
	 * @param {object} location_ object to merge in location
	 * @returns {void} void
	 */
	updateLocationModel(location_) {
		const standardOptions = {
			distanceUnit: locationConfigurationModel.configuration.dshDistanceUnit,
			distance: locationConfigurationModel.configuration.DefaultDistanceKm
		};
		const location = Object.assign({}, standardOptions, locationModel.location);
		Object.keys(location_).forEach(locationKey => {
			location[locationKey] = location_[locationKey];
		});
		this._validateMergedLocation(location, standardOptions);
		locationModel.location = location;
	}

	_validateMergedLocation(location, standardOptions) {
		location.distanceUnit = !!location.distanceUnit ? location.distanceUnit : standardOptions.distanceUnit;
		location.distance = !!location.distance ? location.distance : standardOptions.distance;
	}

	/**
	 * resetLocation - sets location model to initial values
	 * @returns {void} void
	 */
	resetLocation() {
		const location = {
			distanceUnit: locationConfigurationModel.configuration.dshDistanceUnit,
			distance: locationConfigurationModel.configuration.DefaultDistanceKm
		};
		locationModel.location = location;
	}

	/**
	 * getGeoLocation returns html5 geolocation
	 * @params {object} options html5 geolocation options
	 * @returns {Promise<any>} position
	 */
	getGeoLocation() {
		return new Promise((resolve, reject) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(resolve, reject);
			}
			else {
				reject('Browser doesnt support Geolocation');
			}
		});
	}

	/**
	 * getCalculatedZoom get calculated zoom for map
	 * @param {number} zoom zoom
	 * @returns {number} calculated zoom
	 */
	getCalculatedZoom(zoom) {
		return Math.round(14 - Math.log(Number(zoom)) / Math.LN2);
	}

	/**
	 * getCleanedFormattedAddress - get formatted address without country
	 * @param {string} address address
	 * @returns {string} cleaned address without country
	 */
	getCleanedFormattedAddress(address = '') {
		const addressComponents = address.split(',');
		addressComponents.pop();
		return addressComponents.join(',');
	}

	/**
	 * getGeneratedSearchListItem
	 * @param {string} city city
	 * @param {string} placeId placeId
	 * @returns {HTMLAnchorElement} search list item
	 */
	getGeneratedSearchListItem(city, placeId) {
		const searchListItem = document.createElement('a');
		searchListItem.setAttribute('data-search', city);
		searchListItem.setAttribute('data-place', placeId);
		searchListItem.classList.add('sc-j-search-list-item');
		searchListItem.classList.add('nm-el-lk');
		searchListItem.classList.add('nm-el-lk-01');
		searchListItem.classList.add('nm-el-lk-ast');
		searchListItem.innerHTML = city;
		return searchListItem;
	}

	/**
	 * update function for filtermodel changes
	 * @returns {void} void
	 */
	async update() {
		const filter = SELECTORS.FILTERS.getFilterDTOsMap(STCK_STORE.state).get('geo');
		const values = filter ? filter.values : [];
		if (values.length === 5) {
			await googleController.isGoogleAvailablePolling().catch(error => console.error(error));
			const latLng = googleController.getLatLng(values[0], values[1]);
			locationModel.location = {latLng, address: values[4], distance: values[2], formattedAddress: values[4]};
		}
		else {
			this.resetLocation();
		}
	}
}

/**
 * LocationControllerClass instance
 * @type {LocationControllerClass}
 */
const locationController = new LocationControllerClass();

export {LocationControllerClass, locationController};
