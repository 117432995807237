const template = `
	{{? it.url && it.text}}
		<a x-cq-linkchecker="skip" class="{{=it.css}}" href="{{=it.url}}" data-button-type="{{=it.type}}">
			<span class="audi-link-s__text">{{=it.text}}</span>
			<svg class="audi-link-s__icon">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
			</svg>
		</a>	
	{{?}}
`;
export {template as contactFormElementTemplate};
