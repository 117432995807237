import {dom} from 'core-utils';
import {template} from "core-utils";
import {scFilterOptionsChips} from './filter-chips-template';
import {scsController} from "../scs-controller";
import ScFilterChipFactory from "./filter-chip-factory";
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';

export default class FilterChipsElement extends HTMLElement {
	constructor() {
		super();
		this.unsubscribeFilterOptionsFromStore = null;
	}

	/**
	 * default Values used several times
	 * @returns {object} static default constants
	 */
	static get defaults() {
		return {
			sRemoveItemBtn: '.sc-filter-options-item-filter',
			sContainer: '.sc-md-filter-options',
			sShowMoreBtn: 'j-sc-filter-options-show-more',
			classTemporaryOrder: 'j-sc-filter-options-temporary-order',
			sOptionsAmount: '.j-sc-filter-options-amount',
			sResetButton: '.sc-j-filter-options-reset'
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this.bindContextToFunction();
		// register callBack "update" to store as observer
		this.unsubscribeFilterOptionsFromStore = STCK_STORE.observeStateFromStore(SELECTORS.FILTERS.getFilterItemsRaw, this.update);
		this._addEvents();
		this.update();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		// unregister callBack "update" from store
		this.unsubscribeFilterOptionsFromStore();
		this._removeEvents();
	}


	// public CallBack function to be called on observed model changes
	update() {
		this._generateBreadCrumb();
	}

	bindContextToFunction() {
		// bind context to functions
		this.update = this.update.bind(this);
		this._handleFilterRemovalClick = this._handleFilterRemovalClick.bind(this);
		this._resetFilters = this._resetFilters.bind(this);
		this._showMoreBtn = dom.debounce(this._showMoreBtn.bind(this), 100);
	}

	/**
	 * get data and render breadcrumbMenu
	 * @returns {void} nothing
	 */
	_generateBreadCrumb() {
		const filterOptions = SELECTORS.FILTERS.getFilterOptionsState(STCK_STORE.state);
		const tplData = ScFilterChipFactory.getBreadCrumbTemplateData(filterOptions);

		this._renderBreadCrumbMenu(tplData);
	}

	/**
	 * add events
	 * @returns {void} nothing
	 */
	_addEvents() {
		// add dom eventhandlers
		const domDelegate = dom.getEventDelegate('body');
		domDelegate.on('click', FilterChipsElement.defaults.sRemoveItemBtn, this._handleFilterRemovalClick);
		domDelegate.on('click', FilterChipsElement.defaults.sResetButton, this._resetFilters);
		window.addEventListener('resize', this._showMoreBtn);
	}

	/**
	 * add events
	 * @returns {void} nothing
	 */
	_removeEvents() {
		const domDelegate = dom.getEventDelegate('body');
		domDelegate.off('click', FilterChipsElement.defaults.sRemoveItemBtn, this._handleFilterRemovalClick);
		domDelegate.off('click', FilterChipsElement.defaults.sResetButton, this._resetFilters);
		window.removeEventListener('resize', this._showMoreBtn);
	}

	/**
	 * render filterchips (tplString) to the dom
	 * @param {string} tplData_ - html string
	 * @returns {void} nothing
	 */
	_renderBreadCrumbMenu(tplData_ = []) {
		const htmlString = template.render(scFilterOptionsChips, {
			data: tplData_
		});
		this.innerHTML = htmlString;
		this._showMoreBtn();
	}

	/**
	 * resetFilters @calls scsController.clearAllFilters
	 * @return {void} returns nothing
	 */
	_resetFilters() {
		scsController.clearAllFilters();
	}

	/**
	 * set class if more btn is necessary
	 * @returns {void} nothing
	 */
	_showMoreBtn() { // eslint-disable-line max-statements
		// TODO showMoreButton logic???
		const context = this;
		let filterItemsArray = dom.getElementsArray('.sc-filter-options-item-filter', context),
			counter, i, morePos, count, lineHeight, offsetToHide, moreButtonArea;
		this.classList.remove(FilterChipsElement.defaults.sShowMoreBtn);
		if (filterItemsArray.length > 0) {
			lineHeight = filterItemsArray[0].offsetHeight + parseInt(window.getComputedStyle(filterItemsArray[0]).marginBottom, 10);
			offsetToHide = this._returnFilterchipLines() * lineHeight;
			if (filterItemsArray.length && filterItemsArray[filterItemsArray.length - 1].offsetTop >= offsetToHide) {
				counter = 0;
				for (i = filterItemsArray.length - 1; i >= 0; i--) {
					filterItemsArray[i].classList.remove('sc-filter-item-hide');
					filterItemsArray[i].classList.remove('sc-filter-order');

					if (filterItemsArray[i].offsetTop >= offsetToHide) {
						counter++;
					}
				}
				morePos = filterItemsArray.length - counter;
				for (i = 0; i < filterItemsArray.length; i++) {
					if (i < morePos) {
						filterItemsArray[i].classList.add('sc-filter-order');
					}
					else {
						filterItemsArray[i].classList.add('sc-filter-item-hide');
					}
				}
				this.classList.add(FilterChipsElement.defaults.sShowMoreBtn);
				count = dom.getElementsArray('.sc-filter-item-hide', context).length;
				dom.getElement(FilterChipsElement.defaults.sOptionsAmount, context).innerHTML = count;
				moreButtonArea = Math.max(dom.getElement('.sc-filter-options-item:last-child', context).offsetTop - dom.getElement('.sc-filter-options-item-more', context).offsetTop, 0);
				dom.getElement('.sc-filter-options-container', context).style.height = (offsetToHide + lineHeight + moreButtonArea) + "px";
			}
			else {
				this.classList.remove(FilterChipsElement.defaults.sShowMoreBtn);
			}
		}
	}

	/**
	 * checks browser width and returns number of showing lines as int
	 * @returns {int} 1 line for mobile view, 2 lines for desktop view
	 */
	_returnFilterchipLines() {
		if (window.innerWidth < 768) {
			return 1;
		}
		else {
			return 2;
		}
	}

	/**
	 * @param {Event} event_ - click event
	 * @returns {void} nothing
	 */
	_handleFilterRemovalClick(event_) {
		let item, removalsArray;
		item = dom.closest(event_.target, '[data-remove-ids]');
		removalsArray = dom.getDataAttribute(item, 'data-remove-ids');
		if (removalsArray && removalsArray.length) {
			scsController.removeFiltersByIds(removalsArray);
		}
	}
}
if (window.customElements.get('sc-filter-chips-element') === undefined) {
	window.customElements.define('sc-filter-chips-element', FilterChipsElement);
}
