import {dom, template} from 'core-utils';
import {scsController} from '../filter/scs-controller';
import {searchAgentController} from './search-agent-controller';
import ScFilterChipFactory from '../filter/filter-chips/filter-chip-factory';
import {searchAgentDashboardItemTemplate} from './templates/search-agent-dashboard-item-template';
import {FORMAT_UTILS} from "../../stockcars-utils-bundle";

/**
 * selectors
 * @type {{dialogueWrapper: string, dialogue: string, deleteLayerButton: string, closeDeleteLayerButton: string, loginLayer: string, updateSwitch: string, dashboard: string, showResultsButton: string, removeButton: string, hiddenClass: string}}
 */
const selectors = {
	searchAgentItem: '.sc-search-agent-dashboard-item',
	dialogueWrapper: '.sc-dialogue-wrapper',
	dialogue: '.sc-dialogue',
	deleteLayerButton: '.sc-search-agent-item-show-delete-layer',
	closeDeleteLayerButton: '.sc-search-agent-item-close-delete-layer',
	loginLayer: '.sc-search-agent-login',
	updateSwitch: '.sc-j-search-agent-change-notification-status',
	extendDurationButton: '.sc-j-search-agent-duration-extend',
	dashboard: '.sc-search-agent-dashboard',
	showResultsButton: '.sc-search-agent-show-results-button',
	removeButton: '.sc-search-agent-remove-button',
	hiddenClass: 'sc-hidden',
	searchAgentItemEditButton: '.sc-search-agent-dashboard-item-edit-button',
	searchAgentItemCloseButton: '.sc-search-agent-dashboard-item-close-button',
	headline: '.sc-j-search-agent-title',
	classHeadline: 'audi-headline-order-3',
	classExtendedHeadline: 'audi-headline-order-2'
};

/**
 * attributes
 * @type {{notification: string, endDate: string, createDate: string, amountCars: string, url: string, id: string, filterOptions: string}}
 */
const attributes = {
	notification: 'search-agent-notification',
	endDate: 'search-agent-enddate',
	extendUntilDate: 'search-agent-extendUntilDate',
	extendableToday: 'search-agent-extendableToday',
	createDate: 'search-agent-createdate',
	amountCars: 'search-agent-amountcars',
	url: 'search-agent-url',
	id: 'search-agent-id',
	filterOptions: 'search-agent-filter-options',
	statusExtended: 'search-agent-status-extended'
};

export default class SearchAgentDashboardItem extends HTMLElement {
	// Specify observed attributes so that
	// attributeChangedCallback will work
	static get observedAttributes() {
		return ['search-agent-notification', 'search-agent-status-extended'];
	}

	/**
	 * constructor
	 * @returns {void} returns nothing
	 */
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this._bindContextToFunctions();
		this._render();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this._removeEvents();
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (oldValue && oldValue !== newValue) {
			switch (name) {
				case 'search-agent-notification':
					this._render();
					break;
				case 'search-agent-status-extended':
					this._changeHeadlineSize();
					break;
				default:
					console.log(`attribute ${name} has changed`);
					break;
			}
		}
	}

	/**
	 * _render
	 * @private
	 * @returns {void} returns nothing
	 */
	_render() {
		const domAttributes = this._getAttributes();
		this.innerHTML = template.render(searchAgentDashboardItemTemplate, domAttributes);
		this._getDomProperties();
		this._addEvents();
	}

	/**
	 * _getAttributes
	 * @returns {object} dom attributes
	 * @private
	 */
	_getAttributes() { // eslint-disable-line max-statements
		try {
			this.notification = this.getAttribute(attributes.notification) || '';
			this.extendUntilDate = this.getAttribute(attributes.extendUntilDate) || '';
			this.endDate = this.getAttribute(attributes.endDate) || '';
			this.createDate = this.getAttribute(attributes.createDate) || '';
			this.amountCars = this.getAttribute(attributes.amountCars) || '';
			this.url = this.getAttribute(attributes.url) || '';
			this.id = this.getAttribute(attributes.id) || '';
			this.filterOptions = JSON.parse(this.getAttribute(attributes.filterOptions) || '{}');
			this.statusExtended = this.getAttribute(attributes.statusExtended) || '';
			this.filterChipData = ScFilterChipFactory.getBreadCrumbTemplateData(this.filterOptions, true);
			this.title = this._generateSearchAgentTitle();
			this.showNotification = SETUPS.get('scopes.search-agent.notification');

			this.showExtendLink = this.getAttribute(attributes.extendableToday) || ''; // (SearchAgentDashboardItem.getDaysBetweenCreateAndEndDate(new Date(this.createDate), new Date(this.endDate)) <= EXPRIRING_DAYS);

			return {
				title: this.title,
				notification: this.notification,
				endDate: this.endDate,
				extendUntilDate: this.extendUntilDate,
				createDate: this.createDate,
				amountCars: FORMAT_UTILS.formatCount(this.amountCars),
				url: this.url,
				filterOptions: this.filterChipData,
				id: this.id,
				showExtendLink: this.showExtendLink,
				showNotification: this.showNotification
			};
		}
		catch (err) {
			console.error(err);
		}
	}

	_generateSearchAgentTitle() {
		let filterNameArray = [],
			sortedfilterNameArray = [];
		const packageFilterContent = SearchAgentDashboardItem._getAllIncludedPackages(this.filterOptions);
		Object.entries(this.filterOptions).forEach(([key, value]) => {
			value.selectedFilter.forEach(filter => {
				if (filter.position && packageFilterContent.indexOf(filter.id) === -1) {
					if (!!filter.text) {
						let label = filter.text;
						if (filter.uniqueText === false && !!value.text) {
							label = value.text + ': ' + label;
						}
						filterNameArray[filter.position - 1] = label;
					}
					else {
						this._generateSpecialSearchAgentTitle(filterNameArray, filter, key);
					}
				}
			});
		});
		sortedfilterNameArray = filterNameArray.filter(function (item, pos) {
			return filterNameArray.indexOf(item) === pos;
		});
		return sortedfilterNameArray.join(', ');
	}

	_generateSpecialSearchAgentTitle(filterNameArray = [], filter = {}, key = "") {
		if (filter.id === 'geo') {
			filterNameArray[filter.position - 1] = filter.display[4];
		}
		else {
			if (key === 'ranges') {
				const fromData = (filter.display[0] && filter.display[0] !== '') ? ` ${window.i18n['sc.filter-range.from.label']} ${filter.display[0]}` : '',
					toData = (filter.display[1] && filter.display[1] !== '') ? ` ${window.i18n['sc.filter-range.to.label']} ${filter.display[1]}` : '';
				let lbl = `${ScFilterChipFactory.filterOptionLabels[filter.id]}${fromData}${toData}`;
				if (lbl.split('|').length > 1) {
					let {renderedMin:minValue, renderedMax:maxValue} = ScFilterChipFactory.renderSpecialTuples(ScFilterChipFactory.filterOptionLabels[filter.id], filter.display),
						tuples = ScFilterChipFactory.getValueTuples(filter.display);
					minValue = (tuples.min.filter(tuple => tuple === "" || tuple === null).length) === (tuples.min.length) ? "" : minValue;
					maxValue = (tuples.max.filter(tuple => tuple === "" || tuple === null).length) === (tuples.max.length) ? "" : maxValue;
					lbl = this._buildMinMaxValue({minValue, maxValue});
				}
				filterNameArray[filter.position - 1] = lbl;
			}
			else {
				const lbl = ScFilterChipFactory.filterOptionLabels[filter.id] || this._capitalizeTitle(key);
				filterNameArray[filter.position - 1] = lbl.split('|')[0];
			}
		}
		return filterNameArray;
	}

	_buildMinMaxValue({minValue, maxValue}) {
		const minSeparator = window.i18n['sc.filter-range.from.label'],
			maxSeparator = window.i18n['sc.filter-range.to.label'];
		let valuesLabel;
		if (!!minValue && minValue !== 'null') {
			if (!!maxValue && minValue !== 'null') {
				const maxValueArray = maxValue.split('|'),
					minValueArray = minValue.split('|');
				// min and max values
				valuesLabel = `${minValueArray.shift()} ${minSeparator} ${minValueArray.pop()} ${maxSeparator} ${maxValueArray.pop()}`;
			}
			else {
				const minValueArray = minValue.split('|');
				// min value only
				valuesLabel = `${minValueArray.shift()} ${minSeparator} ${minValueArray.pop()}`;
			}
		}
		else {
			// max Value only
			const maxValueArray = maxValue.split('|');
			valuesLabel = `${maxValueArray.shift()} ${maxSeparator} ${maxValueArray.pop()}`;
		}
		return valuesLabel;
	}


	/**
	 * get an array of all filter ids which are included in an "allSelected" filtergroup
	 * @param {Object} filterOptions_ - object with selected filter options
	 * @returns {array} array with filter ids
	 */
	static _getAllIncludedPackages(filterOptions_ = {}) {
		let packageContents = [];
		Object.entries(filterOptions_).forEach((entry) => {
			if (entry.length && entry[1] && entry[1].allSelected && entry[1].selectedFilter.length > 1) {
				packageContents = [...packageContents, ...entry[1].selectedFilter[0].remove];
			}
		});
		return packageContents;
	}

	/**
	 * _capitalizeTitle - capitalize title
	 * @private
	 * @param {string} title - lowecase title string
	 * @returns {string} returns capitalized title
	 */
	_capitalizeTitle(title = '') {
		if (title.length > 3) {
			let label = [...title];
			label[0] = label[0].toUpperCase();
			label = label.join('');
			return label;
		}
		return title.toUpperCase();
	}

	/**
	 * _bindContextToFunctions - bind context to necessary functions
	 * @private
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._openSearch = this._openSearch.bind(this);
		this._removeItem = this._removeItem.bind(this);
		this._updateSearchAgentNotificationStatus = this._updateSearchAgentNotificationStatus.bind(this);
		this._showDeleteLayer = this._showDeleteLayer.bind(this);
		this._closeDeleteLayer = this._closeDeleteLayer.bind(this);
		this._extendSearchAgentDuration = this._extendSearchAgentDuration.bind(this);
		this._handlesSearchAgentItemClick = this._handlesSearchAgentItemClick.bind(this);
		this._handlesSearchAgentCloseButtonClick = this._handlesSearchAgentCloseButtonClick.bind(this);
	}

	/**
	 * _addEvents - adds events when element is connected
	 * @private
	 * @returns {void} returns nothing
	 */
	_addEvents() {
		this.openSearch.addEventListener('click', this._openSearch);
		this.removeButton.addEventListener('click', this._removeItem);
		this.updateSwitch.addEventListener('click', this._updateSearchAgentNotificationStatus);
		this.showDeleteLayerButton.addEventListener('click', this._showDeleteLayer);
		this.closeDeleteLayerButton.addEventListener('click', this._closeDeleteLayer);
		this.extendDurationButton.addEventListener('click', this._extendSearchAgentDuration);
		this.searchAgentItemEditButton.addEventListener('click', this._handlesSearchAgentItemClick);
		this.searchAgentItemCloseButton.addEventListener('click', this._handlesSearchAgentCloseButtonClick);
	}

	/**
	 * _removeEvents - removes events when element is disconnected
	 * @private
	 * @returns {void} returns nothing
	 */
	_removeEvents() {
		this.openSearch.removeEventListener('click', this._openSearch);
		this.removeButton.removeEventListener('click', this._removeItem);
		this.updateSwitch.removeEventListener('change', this._updateSearchAgentNotificationStatus);
		this.showDeleteLayerButton.removeEventListener('click', this._showDeleteLayer);
		this.closeDeleteLayerButton.removeEventListener('click', this._closeDeleteLayer);
		this.extendDurationButton.removeEventListener('click', this._extendSearchAgentDuration);
		this.searchAgentItemEditButton.removeEventListener('click', this._handlesSearchAgentItemClick);
		this.searchAgentItemCloseButton.removeEventListener('click', this._handlesSearchAgentCloseButtonClick);
	}

	/**
	 * _getDomProperties
	 * @private
	 * @returns {void} returns nothing
	 */
	_getDomProperties() {
		this.headlineTitle = dom.getElement(selectors.headline, this);
		this.showDeleteLayerButton = dom.getElement(selectors.deleteLayerButton, this);
		this.closeDeleteLayerButton = dom.getElement(selectors.closeDeleteLayerButton, this);
		this.deleteLayerWrapper = dom.getElement(selectors.dialogueWrapper, this);
		this.deleteLayerDialouge = dom.getElement(selectors.dialogue, this);
		this.updateSwitch = dom.getElement(selectors.updateSwitch, this);
		this.extendDurationButton = dom.getElement(selectors.extendDurationButton, this);
		this.searchAgentDashboard = dom.getElement(selectors.dashboard);
		this.openSearch = dom.getElement(selectors.showResultsButton, this);
		this.removeButton = dom.getElement(selectors.removeButton, this);
		this.searchAgentItemEditButton = dom.getElement(selectors.searchAgentItemEditButton, this);
		this.searchAgentItemCloseButton = dom.getElement(selectors.searchAgentItemCloseButton, this);
	}

	/**
	 * _openSearch
	 * @private
	 * @param {Event} event event
	 * @returns {void} returns nothing
	 */
	async _openSearch(event) {
		event.preventDefault();
		await scsController.clearAllFilters();
		location.href = this.url;
	}

	/**
	 * _updateSearchAgentNotificationStatus
	 * @returns {Promise<void>} returns nothing
	 * @private
	 */
	async _updateSearchAgentNotificationStatus() {
		this.notification = this.notification === 'false' ? 'true' : 'false';
		const updatedSearchAgent = {
			notification: this.notification
		};
		const payload = await searchAgentController.updateSearchAgent(this.id, updatedSearchAgent);
		this.setAttribute(attributes.notification, payload.notification.toString());
	}

	async _extendSearchAgentDuration() {
		const updatedSearchAgent = {
			extend: true
		};
		const payload = await searchAgentController.updateSearchAgent(this.id, updatedSearchAgent);
		this.setAttribute(attributes.extendableToday, payload.extendableToday.toString());
		this.setAttribute(attributes.endDate, payload.endDate.toString());
		this.setAttribute(attributes.extendUntilDate, payload.extendUntilDate.toString());
		this._render();
	}

	/**
	 * _removeItem - makes DELETE request and removes item from dom
	 * @returns {void} returns nothing
	 * @private
	 */
	async _removeItem() {
		try {
			const payload = await searchAgentController.deleteSearchAgent(this.id);
			if (payload && payload.status === 204) {
				this.parentNode.removeChild(this);
				this.searchAgentDashboard._updateSearchAgentsCount();
			}
			else {
				console.log('could not remove item, wrong status', status);
			}
		}
		catch (err) {
			console.warn('could not remove item', err);
		}
	}

	/**
	 * _showDeleteLayer
	 * @private
	 * @returns {void} returns nothing
	 */
	_showDeleteLayer() {
		this.deleteLayerWrapper.classList.remove(selectors.hiddenClass);
		this.deleteLayerWrapper.setAttribute('data-active', 'true');
		this.deleteLayerDialouge.style.opacity = '1';
	}

	/**
	 * _closeDeleteLayer
	 * @private
	 * @returns {void} returns nothing
	 */
	_closeDeleteLayer() {
		this.deleteLayerWrapper.setAttribute('data-active', 'false');
		this.deleteLayerDialouge.style.opacity = '0';
		this.deleteLayerWrapper.classList.add(selectors.hiddenClass);
	}

	/**
	 * _handlesSearchAgentItemClick
	 * @private
	 * @returns {void} returns nothing
	 */
	_handlesSearchAgentItemClick() {
		const searchAgentItems = dom.getElementsArray(selectors.searchAgentItem),
			currentItem = dom.closest(this, selectors.searchAgentItem);
		// set data-status-extended='false' for every item
		searchAgentItems.forEach((item) => {
			item.setAttribute(attributes.statusExtended, false);
		});
		// only set true for clicked one
		currentItem.setAttribute(attributes.statusExtended, true);
	}

	_changeHeadlineSize() {
		if (this.getAttribute(attributes.statusExtended || '') === 'true') {
			this.headlineTitle.classList.remove(selectors.classHeadline);
			this.headlineTitle.classList.add(selectors.classExtendedHeadline);
		}
		else {
			this.headlineTitle.classList.remove(selectors.classExtendedHeadline);
			this.headlineTitle.classList.add(selectors.classHeadline);
		}
	}

	/**
	 * _handlesSearchAgentCloseButtonClick
	 * @private
	 * @returns {void} returns nothing
	 */
	_handlesSearchAgentCloseButtonClick() {
		const currentItem = dom.closest(this, selectors.searchAgentItem);
		currentItem.setAttribute(attributes.statusExtended, false);
	}
}

if (!window.customElements.get('search-agent-dashboard-item', SearchAgentDashboardItem)) {
	window.customElements.define('search-agent-dashboard-item', SearchAgentDashboardItem);
}
