export default class DealerAddressElement extends HTMLElement {
	constructor() {
		super();
		this._structurePattern = SETUPS.get('stockcar.dealer.address.pattern') || '["street",["zipcode", "city"],"region"]';
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		this._dealerAddressList = this.querySelector('.sc-dealer-address-list');
		this._dealerAddressParts = [...this.querySelectorAll('.sc-dealer-address-list-item')];

		if (this._dealerAddressParts.length && this._dealerAddressList) {
			const parts = this._getAddressParts(this._structurePattern);
			const sortedFragment = this._getOrderedFragment(parts);

			this._dealerAddressList.innerHTML = '';
			this._dealerAddressList.appendChild(sortedFragment);
		}
	}

	/**
	 * _getOrderedFragment -
	 * @param {array} parts address parts
	 * @returns {DocumentFragment} domFragment document fragment with sorted items
	 * @private
	 */
	_getOrderedFragment(parts = []) {
		const domFragment = document.createDocumentFragment();

		parts.forEach(part => {
			if (typeof part === 'string') {
				const node = this._dealerAddressParts.filter(dealerAddressPart => dealerAddressPart.dataset.addressPart === part);
				if (node[0]) {
					const nodeClone = node[0].cloneNode(true);
					domFragment.appendChild(nodeClone);
				}
			}
			else {
				part.forEach((linePart, index) => {
					const node = this._dealerAddressParts.filter(dealerAddressPart => dealerAddressPart.dataset.addressPart === linePart);
					if (node[0]) {
						const nodeClone = node[0].cloneNode(true);
						nodeClone.style.display = 'inline-block';
						if (index > 0){
							domFragment.appendChild(document.createTextNode(' '));
						}
						domFragment.appendChild(nodeClone);
					}
				});
			}
		});
		return domFragment;
	}

	/**
	 * _getAddressParts - parses structure patter and returns array of parts
	 * @param {string} structurePattern structurePattern
	 * @returns {*} address parts
	 * @private
	 */
	_getAddressParts(structurePattern) {
		try {
			return JSON.parse(structurePattern);
		}
		catch (e) {
			console.log(e, 'Cannot parse structure pattern');
			return [];
		}
	}
}

if (window.customElements.get('sc-dealer-address-element') === undefined) {
	window.customElements.define('sc-dealer-address-element', DealerAddressElement);
}
