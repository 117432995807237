import {vehicleTilesTop} from './vehicle-tiles-top';
import {vehicleTilesBottom} from './vehicle-tiles-bottom';
import {vehicleTilesDealerContact} from './vehicle-tiles-dealer-contact';
import {vehicleTilesDealerLabel} from './vehicle-tiles-dealer-label';
import {vehicleExtIntColors} from './vehicle-ext-int-color';
import {vehicleTilesAvailableFrom} from './vehicle-tile-available-from';

const exports = `
	${vehicleTilesTop}
			<div class="sc-details-list">
				${vehicleTilesDealerLabel}
				${vehicleTilesDealerContact}

				{{? vehicle && isUsedCar && vehicle.used}}
					{{? !!vehicle.used.initialRegistration }}
						<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-calendar-events-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/calendar-events-small.svg#nm-icon-calendar-events-small"}}></use></svg> {{=vehicle.used.initialRegistration}}</p>
					{{?}}
					{{? !!vehicle.used.mileageFormatted}}
						<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-logbook-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/logbook-small.svg#nm-icon-logbook-small"}}></use></svg>${vehicleTilesAvailableFrom} {{=vehicle.used.mileageFormatted}} {{? vehicle.used.mileageUnit && vehicle.used.mileageUnit != ""}}{{=vehicle.used.mileageUnit}}{{?}}
						<a href='#fn_miles' class='audi-j-footnote-reference' target='_self'><sup class='audi-footnote-anchor__text'></sup></a>
						</p>
					{{?}}
				{{?}}
				${vehicleExtIntColors}
			</div>
			{{? vehicle}}
				{{? vehicle.tileTabs}}
					<vehicle-tab-element class="sc-vehicle-tab-element" data-tile-tabs-options='{{=vehicle.tileTabs}}' data-vehicle-id="{{=vehicle.id}}" data-vehicle-url="{{=vehicle.url}}" data-consumption-options='{"consumption":true, "emission":true, "efficiency":true, "isElectrical":{{=vehicle.isElectrical}}}'></vehicle-tab-element>
				{{?}}
			{{?}}
	${vehicleTilesBottom}
`;

export {exports as vehicleTilesTemplateUCOPT3};
