export default class GenericFeatureAppElement extends HTMLElement {
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		const data = this.initialize();
		this.render(data);
	}

	initialize() {
		const data = {};
		const basePath = this.getAttribute('data-base') || 'https://fa-vtp-components.cdn.prod.arcade.apps.one.audi/1.10.0/';
		data.src = `${basePath}fh/app.js`;
		data.id = this.getAttribute('data-id') || `generic-feature-app-${this.getTimestamp()}`;
		data.config = this.updateConfig(this.getAttribute('data-config') || {}, basePath);
		return data;
	}

	getTimestamp() {
		const now = Date.now();
		return now - (now % 3600000);
	}

	updateConfig(config_, basePath_) {
		if (!!config_) {
			try {
				const parsed = JSON.parse(config_);
				if (Object.keys(parsed).length) {
					parsed.basePath = basePath_;
				}
				return JSON.stringify(parsed);
			}
			catch {
				console.warn('STCK Generic Feature App Element could not parse config.json. Parse ERROR:', config_);
				return config_;
			}
		}
	}

	render(data_) {
		this.innerHTML = `<feature-app src=${data_.src} data-audi-core-tracking-include=true id=${data_.id} config=${data_.config}></feature-app>`;
	}
}

if (window.customElements.get('sc-generic-feature-app-element') === undefined) {
	window.customElements.define('sc-generic-feature-app-element', GenericFeatureAppElement);
}
