export default class FilterChipItem {
	constructor(id_, label, remove, syntheticFilter = false) {
		if (!id_) {
			throw new TypeError("missing param ID for FilterChipItem");
		}
		this._id = id_;
		this._label = label;
		this._removeArr = remove;
		this._synthetic = syntheticFilter;
	}

	get isSyntheticFilter() {
		return this._synthetic;
	}

	get label() {
		return this._label;
	}

	get id() {
		return this._id;
	}

	get remove() {
		return this._removeArr;
	}

	static createFilterChipItemFromObject(obj = {}) {
		return new FilterChipItem(obj._id, obj._label, obj._removeArr, obj._synthetic);
	}
}
