import {ExpiringStorage} from '@oneaudi/stck-store';
import ScsModelBase from '../../filter/scs-model-base';
import {scPage} from '../../../stockcars-utils-bundle';

class ScModelMandatoryAreaSearchClass extends ScsModelBase {
	constructor(name_ = 'ScModelMandatoryAreaSearch') {
		super(name_);
		this._location = null;
		// each market has its own key/localStorage
		const storageKey = `mandatoryLocationStorage_${scPage.getMarket()}_${scPage.getStartPage()}`;
		this.storage = new ExpiringStorage(storageKey, ScModelMandatoryAreaSearchClass.defaults.EXPIRING_DAYS);
	}

	static get defaults() {
		return {
			EXPIRING_DAYS: 30, // 30 days
			modalLayerActiveClass: 'modal-layer-open'
		};
	}

	/**
	 * set model and store item in the local storage
	 * @returns {Object|null} location filterDTO
	 */
	get presetLocation() {
		return this._location || this.storage.readEntry();
	}

	set presetLocation(location_filter) {
		const success = this.storage.writeEntry(location_filter);
		if (success) {
			this._location = location_filter;
			this.notifyObservers();
		}
		else {
			console.warn('could not write MAS to storage!');
		}
	}

	/**
	 * check if MAS is active and MAS layer is open
	 * @returns {Boolean} returns boolean
	 */
	get activeMasLayer() {
		return (this.presetLocation && this.presetLocation.filter && document.body.classList.contains(ScModelMandatoryAreaSearchClass.defaults.modalLayerActiveClass));
	}

	/**
	 * check if MAS is active
	 * @returns {Boolean} returns boolean
	 */
	get activeMas() {
		return (this.presetLocation && this.presetLocation.filter);
	}

	createOrUpdateLocation(location_filter) {
		this.presetLocation = location_filter;
	}

	removeLocation() {
		this._location = null;
		this.storage.writeEntry({});
	}
}

const scModelMandatoryAreaSearch = new ScModelMandatoryAreaSearchClass('scModelMandatoryAreaSearch');
export {scModelMandatoryAreaSearch, ScModelMandatoryAreaSearchClass};

