const exports = `
	{{? it.url && it.text}}
		<p class="sc-compare-dealer-investor-shared-vehicle-link-wrapper">
			<a class="sc-compare-dealer-investor-shared-vehicle-link nm-layerLink audi-link-s audi-link-s--arrow {{=it.css}}" href="{{=it.url}}">
				<span class="audi-link-s__text">{{=it.text}}</span>
				<svg class="audi-link-s__icon">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
				</svg>
			</a>
		</p>
	{{?}}
`;

export {exports as compareIsvInfoTemplate};
