import {BaseButtonLogic} from '../buttons/sc-base-button-logic';
import {OBJECT_UTILS, scPage} from '../../stockcars-utils-bundle';
import {template} from 'core-utils';
import {vehicleTilesButtonsTemplate} from '../tiles/templates/vehicle-tiles-button-element-template';
import {vehicleTilesButtonsTemplateSpain} from '../tiles/templates/markets/spain/vehicle-tiles-button-element-template-spain';

const ONE_ITEM_CLASS = 'sc-vehicle-tiles-button-element--one-item';
const TWO_ITEM_CLASS = 'sc-vehicle-tiles-button-element--two-items';

export class VehicleTilesButtonElement extends BaseButtonLogic {
	constructor() {
		super();
	}

	render() {
		const useSpainTemplate = SETUPS.get('stockcar.scs.market.path')?.split('/').map(part => part.toLowerCase()).includes('es');
		this.data.linkType = this.dataset.linkType;
		if ((SETUPS.get('stck.show.cta.on.result.tiles') && this.data.linkType !== 'link') || (!SETUPS.get('stck.show.cta.on.result.tiles') && this.data.linkType === 'link')) {
			const templateData = this._getVehicleTilesButtonArray();
			if (templateData.length === 1) {
				this.classList.add(ONE_ITEM_CLASS);
			}
			if (templateData.length === 2) {
				this.classList.add(TWO_ITEM_CLASS);
			}
			const tilesButtonTemplate = !useSpainTemplate ? vehicleTilesButtonsTemplate : vehicleTilesButtonsTemplateSpain;
			this.innerHTML = template.render(tilesButtonTemplate, {data: templateData});
		}
	}

	_getVehicleTilesButtonArray() {
		let buttonArray = [];
		let specialLinks = OBJECT_UTILS.deepCopy(this.data.ctaSpecialLinks);
		if (this.data.leasing) {
			buttonArray.push(Object.assign(specialLinks.leasing, {
				'url': this._getUrl(specialLinks),
				'type': this._getButtonType('leasing'),
				'css': 'sc-j-layerLink',
				'postData': scPage.createPostFormData(this.data.vehicleData.id)
			}));
		}
		else if (this.data.ecom) {
			if (!!SETUPS.get('scopes.ecom.show.contact')) {
				buttonArray.push(Object.assign(specialLinks.contact, {
					'type': 'link',
					'primary': ((!!this.dataset && !!this.dataset.primary)),
					'url': specialLinks.contact.url,
					'css': 'sc-j-layerLink'
				}));
			}
			buttonArray.push(Object.assign(specialLinks.ecom, {
				'type': 'ecom-link',
				'dataStoreUrl': this.data.vehicleData.vtpDataStoreUrl,
				'pdfUrl': this.data.pdfUrl,
				'vehicleId': this.data.vehicleData.id,
				'css': 'sc-j-layerLink'
			}));
		}
		else if (this.data.vehicleData.weblink && this.data.linkType === 'weblink') {
			buttonArray.push({
				'text': this.data.vehicleData.buyableOnline ? window.i18n['sc.tiles.cta.ecom.vehicle.text'] : window.i18n['sc.tiles.cta.see.dealer.website'],
				'url': this.data.vehicleData.weblink,
				'type': this._getButtonType(),
				'target': '_blank'
			});
		}
		else if (this.data.linkType !== 'weblink') {
			const linkData = this._getSpecificSpecialLink(specialLinks);
			if (linkData && linkData.method === 'POST') {
				linkData.postData = scPage.createPostFormData(this.data.vehicleData.id);
			}
			if (!!linkData) {
				buttonArray.push(Object.assign(linkData, {
					'primary': ((!!this.dataset && !!this.dataset.primary)),
					'url': this._getUrl(specialLinks),
					'type': this._getButtonType(),
					'css': this._getCssClasses(linkData)
				}));
			}
		}
		return buttonArray;
	}

	_getCssClasses(linkData_ = {}) {
		return (linkData_.target !== '_blank' && linkData_.layer && linkData_.method !== 'POST') ? 'sc-j-layerLink nm-layerLink' : 'sc-j-layerLink';
	}

	_getButtonType(type_ = 'contact') {
		let type = type_;
		if (this.data.nws) {
			type = 'nws';
		}
		if (this.data.linkType === 'link') {
			type = 'link';
		}
		return type;
	}

	_getSpecificSpecialLink(ctaSpecialLinks_ = {}) {
		return (ctaSpecialLinks_.nws && this.data.vehicleData.nationWideSelling) ? ctaSpecialLinks_.nws : ctaSpecialLinks_.contact;
	}

	_getUrl(specialLinks_) {
		const data = this._getSpecificSpecialLink(specialLinks_) || {};
		return data.url || "";
	}
}

if (window.customElements.get('vehicle-tiles-button-element') === undefined) {
	window.customElements.define('vehicle-tiles-button-element', VehicleTilesButtonElement);
}
