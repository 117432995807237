import {vehicleTilesTop} from './vehicle-tiles-top';
import {vehicleTilesBottom} from './vehicle-tiles-bottom';
import {vehicleTilesDealerContact} from './vehicle-tiles-dealer-contact';
import {vehicleTilesDealerLabel} from './vehicle-tiles-dealer-label';
import {vehicleTilesOrderStatus} from './vehicle-tiles-order-status';
import {vehicleExtIntColors} from './vehicle-ext-int-color';

const exports = `
	${vehicleTilesTop}
			<div class="sc-details-list">
				${vehicleTilesDealerLabel}
				${vehicleTilesDealerContact}
				${vehicleExtIntColors}
				${vehicleTilesOrderStatus}
			</div>
			{{? vehicle}}
				{{? vehicle.tileTabs}}
					<vehicle-tab-element class="sc-vehicle-tab-element" data-tile-tabs-options='{{=vehicle.tileTabs}}' data-vehicle-id="{{=vehicle.id}}" data-vehicle-url="{{=vehicle.url}}" data-consumption-options='{"consumption":true, "emission":true, "efficiency":true, "isElectrical":{{=vehicle.isElectrical}}}'></vehicle-tab-element>
				{{?}}
			{{?}}
	${vehicleTilesBottom}
`;

export {exports as vehicleTilesTemplateNCOPT2};
