import {scsController} from '../filter/scs-controller';
import {scPage} from '../../stockcars-utils-bundle';
import {FilterDTO} from '../../stockcars-filter-bundle';

export default class StockcarsEditorialTeaserElement extends HTMLElement {
	constructor() {
		super();
	}

	/**
	 * getter for defaults
	 */
	static get defaults() {
		return {
			elementName: 'stockcars-editorial-teaser-element',
			pattern: '{{filtered_count_vehicle}}',
			selectorHeadline: '.sc-j-teaser-editorial-title',
			selectorText: '.sc-j-teaser-editorial-text',
			selectorLinkText: '.sc-j-teaser-editorial-link-text'
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	async connectedCallback() {
		this._initializeProperties();
		const totalCount = await this._getTotalCountForLink();
		this._patchCountPatterns(totalCount);
	}

	/**
	 * _initializeProperties
	 * @private
	 * @returns {void} void
	 */
	_initializeProperties() {
		this.headline = this.querySelector(StockcarsEditorialTeaserElement.defaults.selectorHeadline);
		this.text = this.querySelector(StockcarsEditorialTeaserElement.defaults.selectorText);
		this.linkText = this.querySelector(StockcarsEditorialTeaserElement.defaults.selectorLinkText);
	}

	/**
	 * extract filters and presets from hash
	 * @param {string} hash_ location hash
	 * @returns {object} object with presets and filters map {{filters: Map<any, any>, presets: Map<any, any>}}
	 */
	static extractFiltersAndPresetsFromHash(hash_) {
		let filters = new Map();
		let presets = new Map();
		if (!!hash_) {
			const decodedHash = decodeURIComponent(hash_);
			const filtersHash = scPage.getFilterHash(decodedHash, 'filter');
			if (!!filtersHash) {
				filters = StockcarsEditorialTeaserElement._parseFilterStringToMap(filtersHash, false);
			}

			const presetsHash = scPage.getFilterHash(decodedHash, 'preset');
			if (!!presetsHash) {
				presets = StockcarsEditorialTeaserElement._parseFilterStringToMap(presetsHash, true);
			}
			// clear current url hash
			scPage.cleanupFilterHash();
		}
		return {filters, presets};
	}

	/**
	 * parse filters (or presets) from a string to a map of FilterDTOs
	 * @param {string} filterString_ - string with comma separated filters
	 * @param {boolean} preset_ flag if presets should be used
	 * @returns {Map<FilterDTO>} a map of filters (or presets)
	 */
	static _parseFilterStringToMap(filterString_, preset_ = false) {
		const currentFiltersMap = new Map();
		const filtersRawArray = filterString_.split(',');
		filtersRawArray.forEach(filter => {
			const filterDTO = FilterDTO.initFromFilterString(filter, true, preset_);
			if (filterDTO) {
				currentFiltersMap.set(filterDTO.id, filterDTO);
			}
		});
		return currentFiltersMap;
	}

	/**
	 * _getTotalCountForLink
	 * @returns {Promise<void>} totalCount wrapped in promise
	 * @private
	 */
	async _getTotalCountForLink() {
		const {linkUrl = ''} = this.dataset;
		const {filters, presets} = StockcarsEditorialTeaserElement.extractFiltersAndPresetsFromHash(linkUrl);
		const {totalCount} = await scsController.getFilterResponse(filters, presets).catch(err => {
			console.log('Could not get total count', err);
		});
		return totalCount;
	}

	/**
	 * _patchCountPatterns
	 * @param {number} totalCount totalCount
	 * @private
	 * @returns {void} void
	 */
	_patchCountPatterns(totalCount) {
		if (!!totalCount) {
			this._patchPatterns(totalCount);
		}
	}

	/**
	 * _patchPatterns
	 * @param {number} totalCount totalCount
	 * @private
	 * @returns {void} void
	 */
	_patchPatterns(totalCount) {
		[this.headline, this.text, this.linkText].forEach(item => {
			if (item) {
				const {original = ''} = item.dataset;
				if (original.includes(StockcarsEditorialTeaserElement.defaults.pattern)) {
					item.innerHTML = original.replace(StockcarsEditorialTeaserElement.defaults.pattern, totalCount);
				}
			}
		});
	}
}

if (window.customElements.get(StockcarsEditorialTeaserElement.defaults.elementName) === undefined) {
	window.customElements.define(StockcarsEditorialTeaserElement.defaults.elementName, StockcarsEditorialTeaserElement);
}
