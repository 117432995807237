const exports = `
	{{
		var onlyUnique = function (value, index, self) {
			return self.indexOf(value) === index;
		};
	}}

	{{var pictures = [];}}

	{{var dealerImages = vehicle.pictures && vehicle.pictures.filter(function(picture) { return picture.originalKey.startsWith('dealer-images'); });}}
	{{var renderImages = vehicle.tilesPictures;}}
	{{var fallbackImage = vehicle.fallbackPictures && vehicle.fallbackPictures.find(function(picture) {return picture.key === "render_4x3";});}}

	{{var showDealerImages = !vehicle.nationWideSelling && dealerImages && dealerImages.length > 0;}}
	{{var showRenderImages = !isUsedCar && !vehicle.hideRenderImages && renderImages && renderImages.length > 0;}}

	{{? showDealerImages}}
		{{dealerImages.forEach(function(dealerImage) {return pictures.push(dealerImage);});}}
	{{?}}

	{{? showRenderImages}}
		{{renderImages.forEach(function(renderImage) {return pictures.push(renderImage);});}}
	{{?}}

	{{? !pictures.length}}
		{{? renderImages && renderImages.length > 0}}
			{{renderImages.forEach(function(renderImage) {return pictures.push(renderImage);});}}
		{{??}}
			{{pictures.push(fallbackImage);}}
		{{?}}
	{{?}}

	{{function getVtpImagesWithDimensions(url_, width, height) {var parts = url_.split('/');if (parts.length < 7){return url_;}parts[5] = width;parts[6] = height;return parts.join('/');};}}
	<tiles-swipe-gallery-element class="sc-car-tile-swipe-gallery swipe-gallery-element" data-index="0" data-disable-keyboard="true" data-enable-infinite="true">
		<div class="sc-car-tile-swipe-container sc-j-swipe-gallery-container">
			<div class="sc-car-tile-swipe-slider-wrapper sc-j-swipe-gallery-slider-wrapper">
				<div class="sc-j-swipe-gallery-slider">
					{{~pictures :picture}}
						{{var url = picture.url;}}
						<div class="sc-j-swipe-gallery-slide">
							<div class="sc-j-swipe-gallery-slide-item">
								<div class="sc-car-tile-swipe-slide-sizer sc-j-swipe-gallery-slide-item-image-sizer">
									<div class="sc-j-swipe-gallery-slide-item-image-wrapper {{? url.indexOf('mediaservice') > -1}}sc-media-image{{?}}">
										<a class="sc-car-tile-swipe-item-grid sc-details-link {{? !useWeblink && vehicle.url && vehicle.url.length > 0}}" href="{{=vehicle.url}}{{?? useWeblink && vehicle.weblink && vehicle.weblink != ''}}" href="{{=vehicle.weblink}}" target="_blank{{?}}">
											<picture class="sc-j-swipe-gallery-slide-item-image sc-j-lazy-load-image sc-j-lazyload-spinner">
												{{? url.indexOf('mediaservice') > -1}}
													{{var separator = "?";}}
													{{? url.indexOf('?') > -1}}
														{{separator = "&";}}
													{{?}}
													<source data-srcset="{{=url+separator}}wid=400&mimetype=image/webp 1x, {{=url+separator}}wid=600&mimetype=image/webp 2x" media="(max-width: 567px)" type="image/webp"/>
													<source data-srcset="{{=url+separator}}wid=400&mimetype=image/webp" media="(max-width: 900px)" type="image/webp"/>
													<source data-srcset="{{=url+separator}}wid=400&mimetype=image/webp" media="(min-width: 901px)" type="image/webp"/>

													<source data-srcset="{{=url+separator}}wid=400&mimetype=image/jp2 1x, {{=url+separator}}wid=600&mimetype=image/jp2 2x" media="(max-width: 567px)" type="image/jp2"/>
													<source data-srcset="{{=url+separator}}wid=400&mimetype=image/jp2" media="(max-width: 900px)" type="image/jp2"/>
													<source data-srcset="{{=url+separator}}wid=400&mimetype=image/jp2" media="(min-width: 901px)" type="image/jp2"/>

													<img class="sc-j-swipe-gallery-slide-item-image" draggable="false" style="width: 100%;">
												{{?? url.indexOf('vtpimages') > -1}}
													{{var url600x450 = getVtpImagesWithDimensions(url, '600','450');}}
													{{var url480x360 = getVtpImagesWithDimensions(url,'480','360');}}

													<source data-srcset="{{=url600x450}}" media="(max-width: 567px)"/>
													<source data-srcset="{{=url480x360}}" media="(max-width: 900px)"/>
													<source data-srcset="{{=url480x360}}" media="(min-width: 901px)"/>

													<img class="sc-j-swipe-gallery-slide-item-image" data-image-src="{{=url}}" draggable="false" style="width: 100%;">
												{{??}}
													<img class="sc-j-swipe-gallery-slide-item-image" data-image-src="{{=url}}" draggable="false" style="width: 100%;">
												{{?}}
											</picture>
										</a>
										{{? picture.type == 'render' && window.i18n['sc.stage.renderedDisclaimer'].trim() !== '' }}
											<popover-trigger class="sc-popover-trigger" role="tooltip">
												<svg class="nm-audi-system-info-small" viewBox="0 0 24 24">
													<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/audi-system-info-small.svg#audi-icon-system-info-small"}}></use>
												</svg>
											</popover-trigger>
											<div class="sc-popover-content">
												{{=window.i18n['sc.stage.renderedDisclaimer']||''}}
											</div>
										{{?}}
										<a class="sc-car-tile-swipe-item-list sc-details-link {{? !useWeblink && vehicle.url && vehicle.url.length > 0}}" href="{{=vehicle.url}}{{?? useWeblink && vehicle.weblink && vehicle.weblink != ''}}" href="{{=vehicle.weblink}}" target="_blank{{?}}">
											<picture class="sc-j-swipe-gallery-slide-item-image sc-j-lazy-load-image sc-j-lazyload-spinner">
												{{? url.indexOf('mediaservice') > -1}}
													{{var separator = "?";}}
													{{? url.indexOf('?') > -1}}
														{{separator = "&";}}
													{{?}}
													<source data-srcset="{{=url+separator}}wid=400&mimetype=image/webp 1x, {{=url+separator}}wid=600&mimetype=image/webp 2x" media="(max-width: 567px)" type="image/webp"/>
													<source data-srcset="{{=url+separator}}wid=600&mimetype=image/webp" media="(min-width: 568px)" type="image/webp"/>
													<source data-srcset="{{=url+separator}}wid=400&mimetype=image/jp2 1x, {{=url+separator}}wid=600&mimetype=image/jp2 2x" media="(max-width: 567px)" type="image/jp2"/>
													<source data-srcset="{{=url+separator}}wid=400&mimetype=image/jp2" media="(min-width: 568px)" type="image/jp2"/>
													<img class="sc-j-swipe-gallery-slide-item-image" draggable="false" style="width: 100%;">
												{{?? url.indexOf('vtpimages') > -1}}
													{{var url600x450 = getVtpImagesWithDimensions(url,'600','450');}}
													{{var url480x360 = getVtpImagesWithDimensions(url,'480','360');}}

													<source data-srcset="{{=url600x450}}" media="(max-width: 567px)"/>
													<source data-srcset="{{=url600x450}}" media="(min-width: 568px)"/>

													<img class="sc-j-swipe-gallery-slide-item-image" data-image-src="{{=url}}" draggable="false" style="width: 100%;">

												{{??}}
													<img class="sc-j-swipe-gallery-slide-item-image" data-image-src="{{=url}}" draggable="false" style="width: 100%;">
												{{?}}
											</picture>
										</a>
										{{? picture.type == 'render' && window.i18n['sc.stage.renderedDisclaimer'].trim() !== '' }}
											<popover-trigger class="sc-popover-trigger" role="tooltip">
												<svg class="nm-audi-system-info-small" viewBox="0 0 24 24">
													<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/audi-system-info-small.svg#audi-icon-system-info-small"}}></use>
												</svg>
											</popover-trigger>
											<div class="sc-popover-content">
												{{=window.i18n['sc.stage.renderedDisclaimer']||''}}
											</div>
										{{?}}
									</div>
								</div>
							</div>
						</div>
					{{~}}
				</div>
			</div>

			{{? pictures.length > 1 }}
				<div class="sc-car-tile-swipe-navigation audi-copy-s">
					<a class="sc-car-tile-swipe-previous sc-j-swipe-gallery-navigation-prev sc-hidden" tabindex="0" role="button"
						 aria-label="Next slide" aria-disabled="false">
						<svg class="sc-car-tile-swipe-previous-icon audi-icon-forward-small">
							<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
						</svg>
					</a>
					<div class="sc-car-tile-swipe-counter">
						<span class="sc-car-tile-swipe-counter-active sc-j-swipe-gallery-counter-active">1</span>/<span class="sc-car-tile-swipe-counter-total sc-j-swipe-gallery-counter-total">10</span>
					</div>

					<a class="sc-car-tile-swipe-next sc-j-swipe-gallery-navigation-next" tabindex="0" role="button" aria-label="Previous slide"
						 aria-disabled="false">
						<svg class="sc-car-tile-swipe-next-icon audi-icon-forward-small">
							<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
						</svg>
					</a>
				</div>
			{{?}}
		</div>
	</tiles-swipe-gallery-element>`;
export {exports as vehicleTilesSwipeGallery};
