import RequestController from './scs-request-controller';
import {STCK_STORE, ACTIONS} from '@oneaudi/stck-store';

const isIe11 = !!navigator.userAgent.match(/Trident\/7\./);

class SvdModelClass {
	constructor() {
		this.initialize();
	}

	initialize() {
		this._fetchSVDVersion();
	}

	/**
	 * fetch current SVD Version
	 *
	 * @method fetchSVDVersion
	 * @return {Promise} with SVD version value
	 */

	async _fetchSVDVersion() {
		try {
			const svdResponse = await RequestController.fetchSvdHeader();
			this.setSvdVersion(svdResponse.version);
			this.setCountry(svdResponse.country);
			return svdResponse.version;
		}
		catch (err) {
			console.warn("could not load SVD", err);
			throw new Error("could not load SVD: " + err);
		}
	}

	/**
	 * set svd version
	 * @param {string} version_ new svd version
	 * @returns {void} returns nothing
	 */
	setSvdVersion(version_) {
		STCK_STORE.dispatch(ACTIONS.SVD_VERSION.setSvdVersion({version: version_}));
	}

	/**
	 * set country
	 * @param {string} country_ market country
	 * @returns {void} returns nothing
	 */
	setCountry(country_) {
		STCK_STORE.dispatch(ACTIONS.COUNTRY.setCountry({country: country_}));
	}

	/**
	 * public getSVDVersion method (calls fetchVersion if version has not yet existed)
	 * @return {Promise} svd version string wrapped in a Promise
	 */
	async getSvdVersion() {
		return STCK_STORE.waitForStateFromStorePromise({
			conditionFn: (state) => (state.svdVersion !== ACTIONS.SVD_VERSION.PENDING_SVD_VERSION),
			selectorToReturn: (state) => state.svdVersion,
			timeOut: (isIe11 ? 30000 : 5000)
		});
	}
}

const svdModel = new SvdModelClass();
export {svdModel, SvdModelClass};
