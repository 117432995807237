import {SELECTORS, STCK_STORE} from '@oneaudi/stck-store';

export default class SortingDropdownElement extends HTMLElement {
	constructor() {
		super();
		this.removedOptions = [];
	}

	/**
	 * getter for defaults
	 * @static
	 * @returns {object} defaults defaults
	 */
	static get defaults() {
		return {
			elementName: 'sorting-dropdown-element',
			distanceOptionValues: [
				'byDistance:asc',
				'byDistance:desc'
			]
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		this.selectElement = this.querySelector('select');
		this.selectOptions = this.selectElement ? [...this.selectElement.options] : [];
		this._toggleDistanceOptions();
		this._subscribeToUiStateChanges();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} void
	 */
	disconnectedCallback() {
		this._unsubscribeToUiStateChanges();
	}

	/**
	 * _unsubscribeToUiStateChanges
	 * @returns {void} nothing
	 */
	_unsubscribeToUiStateChanges() {
		this._unsubscribeToUiStateChangesCallBack();
	}

	/**
	 * _subscribeToUiStateChanges
	 * @returns {void} nothing
	 */
	_subscribeToUiStateChanges() {
		this._unsubscribeToUiStateChangesCallBack = STCK_STORE.observeStateFromStore(SELECTORS.UI.getShowDistance, this._toggleDistanceOptions.bind(this));
	}

	/**
	 * _insertOptionAtIndex
	 * @param {HTMLElement} option option
	 * @param {Number} index index
	 * @private
	 * @returns {void} void
	 */
	_insertOptionAtIndex(option, index = 0) {
		if (index >= this.selectElement.length - 1) {
			this.selectElement.appendChild(option);
		}
		else {
			this.selectElement.insertBefore(option, this.selectElement.children[index]);
		}
	}

	/**
	 * _toggleDistanceOptions
	 * @returns {void} nothing
	 */
	_toggleDistanceOptions() {
		if (!SELECTORS.UI.getShowDistance(STCK_STORE.state)) {
			this._removeOptions();
		}
		else {
			this._appendOptions();
		}
	}

	/**
	 * _appendOptions
	 * @private
	 * @returns {void} void
	 */
	_appendOptions() {
		let index = this.removedOptions.length;
		while (index--) {
			this._insertOptionAtIndex(this.removedOptions[index].clone, this.removedOptions[index].index);
		}

		this.removedOptions.length = 0;
	}

	/**
	 * _removeOptions
	 * @private
	 * @returns {void} void
	 */
	_removeOptions() {
		let index = this.selectOptions.length;
		while (index--) {
			const option = this.selectOptions[index];
			if (SortingDropdownElement.defaults.distanceOptionValues.includes(option.value)) {
				const clone = option.cloneNode(true);
				this.removedOptions.push({
					index,
					clone
				});
				this.selectElement.remove(index);
			}
		}

	}
}

if (window.customElements.get(SortingDropdownElement.defaults.elementName) === undefined) {
	window.customElements.define(SortingDropdownElement.defaults.elementName, SortingDropdownElement);
}
