import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';

export default class HitsLoadingElement extends HTMLElement {
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		this._initializeProperties();
		this.update(SELECTORS.UI.getLoadingState(STCK_STORE.state));
		this.unsubscribeLoadingObserver = STCK_STORE.observeStateFromStore(SELECTORS.UI.getLoadingState, this.update.bind(this));
	}

	update(state_) {
		this._visibilitySwitch(this.spinner, this.content, state_);
	}

	/**
	 * disconnectedCallback
	 * @returns {void} void
	 */
	disconnectedCallback() {
		this.unsubscribeLoadingObserver();
	}

	/**
	 * getter for defaults
	 */
	static get defaults() {
		return {
			elementName: 'hits-loading-element',
			spinner: 'sc-hits-loading-spinner',
			content: 'sc-hits-loading-content',
			hiddenClass: 'sc-transparent',
			animationClass: 'sc-hits-loading-fade-in'
		};
	}

	/**
	 * _initializeProperties
	 * @private
	 * @returns {void} void
	 */
	_initializeProperties() {
		this.spinner = this.querySelector(`.${HitsLoadingElement.defaults.spinner}`);
		this.content = this.querySelector(`.${HitsLoadingElement.defaults.content}`);
	}

	/**
	 * _visibilitySwitch
	 * @private
	 * @param {HTMLElement} loader_ loading element
	 * @param {HTMLElement} content_ thing that loads
	 * @param {Boolean} loadingState_ loading state
	 * @returns {void} void
	 */
	_visibilitySwitch(loader_, content_, loadingState_ = false) {
		if (loadingState_ === true) {
			content_.classList.add(HitsLoadingElement.defaults.hiddenClass);
			loader_.classList.remove(HitsLoadingElement.defaults.hiddenClass);
			loader_.classList.add(HitsLoadingElement.defaults.animationClass);
			if (this.parentElement) {
				this.parentElement.classList.add(HitsLoadingElement.defaults.animationClass);
			}
		}
		else {
			loader_.classList.add(HitsLoadingElement.defaults.hiddenClass);
			loader_.classList.remove(HitsLoadingElement.defaults.animationClass);
			content_.classList.remove(HitsLoadingElement.defaults.hiddenClass);
			if (this.parentElement) {
				this.parentElement.classList.remove(HitsLoadingElement.defaults.animationClass);
			}
		}
	}

}

if (window.customElements.get(HitsLoadingElement.defaults.elementName) === undefined) {
	window.customElements.define(HitsLoadingElement.defaults.elementName, HitsLoadingElement);
}
