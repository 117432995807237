/* global SETUPS */
import {scPage} from '../../stockcars-utils-bundle';

export default class UrlSetups {
	/**
	 * getStckVersionString - prepare version string
	 * @param {String} requestType_ - type of request
	 * @returns {String} version
	 */
	static _versionStringFromMapping(requestType_ = '') {
		let version = UrlSetups.url.defaultVersion;
		if (Object.keys(UrlSetups.url.versionMapping).length > 0 && requestType_.length > 0) {
			version = (typeof UrlSetups.url.versionMapping[requestType_] === 'string') ? UrlSetups.url.versionMapping[requestType_] : UrlSetups.url.defaultVersion;
		}
		return version;
	}

	/**
	 * get url for version requests
	 * @returns {string} request url string
	 */
	static get versionUrl() {
		return `${UrlSetups.url.baseUrl}${UrlSetups._versionStringFromMapping('version')}/version/${UrlSetups.url.market}`;
	}

	/**
	 * get url for filter service requests
	 * @returns {string} filter request url
	 */
	static get filterRequestUrl() {
		return `${UrlSetups.url.baseUrl}${UrlSetups._versionStringFromMapping('filter')}/search/filter/${UrlSetups.url.market}`;
	}

	/**
	 * get url for media service requests
	 * @returns {string} url
	 */
	static get mediaUrl() {
		return `${UrlSetups.url.baseUrl}${UrlSetups._versionStringFromMapping('media')}/vehicles/media/${UrlSetups.url.market}`;
	}

	/**
	 * get url for caline matching service
	 * @returns {string} matching url
	 */
	static get matchUrl() {
		return `${UrlSetups.url.baseUrl}${UrlSetups._versionStringFromMapping('match')}/search/match/${UrlSetups.url.market}`;
	}

	/**
	 * get url for campaign structure call
	 * @returns {string} campaign structure url
	 */
	static get campaignsStructureUrl() {
		return `${UrlSetups.url.baseUrl}${UrlSetups.url.defaultVersion}/structure/campaigns/${UrlSetups.url.market}`;
	}

	/**
	 * get url for compare service
	 * @returns {string} matching url
	 */
	static get compareUrl() {
		return `${UrlSetups.url.baseCompareUrl}${UrlSetups._versionStringFromMapping('compare')}/compare`;
	}

	/**
	 * get base url for search agent service
	 * @returns {string|*} url
	 */
	static get searchAgentBaseUrl() {
		return UrlSetups.url.baseSearchAgentUrl;
	}

	/**
	 * get url for search agent api service
	 * @returns {string} url
	 */
	static get searchAgentApiUrl() {
		return `${UrlSetups.url.baseSearchAgentUrl}api/${UrlSetups._versionStringFromMapping()}/${UrlSetups.url.market}/searchagents`;
	}

	/**
	 * get url for search agent api service
	 * @returns {string} url
	 */
	static get searchAgentXsrfUrl() {
		return `${UrlSetups.url.baseSearchAgentUrl}xsrf`;
	}

	/**
	 * get url for vehicle requests
	 * @returns {string} url
	 */
	static get vehicleUrl() {
		return `${UrlSetups.url.baseUrl}${UrlSetups._versionStringFromMapping('vehicle')}/vehicles/vehicle/${UrlSetups.url.market}/`;
	}

	static get financingServiceUrl() {
		return `${UrlSetups.url.financingServiceUrl}`;
	}

	/**
	 * generic setups lookup for url fragments
	 * @returns {object} {{baseUrl: string, market: string, versionId: string, baseCompareUrl: string, versionCompareId: string, baseSearchAgentUrl: string}} lookup for url fragments
	 */
	static get url() {
		return {
			baseUrl: SETUPS.get('stockcar.scs.base.url') || '',
			baseSearchAgentUrl: SETUPS.get('stockcar.sca.base.url') || '',
			baseCompareUrl: SETUPS.get('stockcar.cmp.base.url') || '',
			financingServiceUrl: SETUPS.get('stockcar.financing.crs.serviceurl') || '',
			market: scPage.getMarket() || '',
			versionMapping: SETUPS.get('stockcar.scs.version.mapping') || {},
			defaultVersion: SETUPS.get('stockcar.scs.default.version') || ''
		};
	}
}
