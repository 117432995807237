import {vehicleTilesSwipeGallery} from './vehicle-tiles-swipe-gallery';
import {vehicleTilesButtonTemplate} from './vehicle-tiles-buttons';
import {similarCarsCircleTemplate} from './circle-template';

const exports = `
	{{~it.data :vehicle :index}}
	{{var isUsedCar = vehicle.type.toLowerCase() === "u";}}
	{{var useWeblink = SETUPS.get('stck.show.cta.on.result.tiles') || false;}}
	<li class="sc-tiles-item sc-car-tile sc-j-car-tile" data-vehicle-id="{{=vehicle.id}}">
		<div class="sc-tiles-item-image">
			{{? vehicle.suggestedCar && vehicle.suggestedCar === true }}
				<a class="sc-details-link" href="{{=vehicle.url}}">
					<picture class="sc-tiles-suggested-image-container sc-j-lazy-load-image sc-lazyload-blur sc-j-lazyload-spinner">
						{{? vehicle.suggestedImage.indexOf('mediaservice') > -1}}
							{{var separator = '?';}}
							{{? vehicle.suggestedImage.indexOf('?') > -1}}
								{{separator = '&';}}
							{{?}}

							<source data-srcset="{{=vehicle.suggestedImage+separator+'mimetype=image/webp'}}" type="image/webp">
							<source data-srcset="{{=vehicle.suggestedImage+separator+'mimetype=image/jp2'}}" type="image/jp2">
							<img class="sc-tiles-suggested-image" src="{{=vehicle.suggestedImage+separator+'width=25'}}" data-image-src="{{=vehicle.suggestedImage}}" draggable="false" style="width: 100%;">
						{{??}}
							<img class="sc-tiles-suggested-image" data-image-src="{{=vehicle.suggestedImage}}" draggable="false" style="width: 100%;">
						{{?}}
					</picture>
				</a>
			{{??}}
				${vehicleTilesSwipeGallery}
			{{?}}

			{{? !vehicle.suggestedCar }}
				${vehicleTilesButtonTemplate}
			{{?}}
		</div>
		<div class="sc-tiles-item-info">
			{{? vehicle && vehicle.symbolicCarline && !!vehicle.symbolicCarline.description }}
				<a class="sc-car-tile-headline sc-details-link {{? !useWeblink && vehicle.url && vehicle.url.length > 0}}" href="{{=vehicle.url}}{{?? useWeblink && vehicle.weblink && vehicle.weblink != ''}}" href="{{=vehicle.weblink}}" target="_blank{{?}}">
					<h3 class="audi-headline-order-3">
						{{=vehicle.symbolicCarline.description}}
					</h3>
				</a>
			{{?}}
			{{? vehicle && vehicle.modelCode && !!vehicle.modelCode.description }}
				<p class="sc-car-tile-description audi-copy-s">{{=vehicle.modelCode.description}}</p>
			{{?}}

			{{? vehicle.buyableOnline && vehicle.buyableOnline == true && !SETUPS.get('stockcar.hide.ecom')}}
				<p class="sc-car-tile-buyable audi-copy-s">{{=window.i18n['sc.tiles.buyable.online'] || ''}}</p>
			{{?}}

			{{? vehicle && vehicle.leasingCar && (vehicle.leasingCar === 'true' || vehicle.leasingCar === true) && vehicle.financing}}
				{{var financeJSON='';}}
				{{ try{financeJSON=JSON.stringify(vehicle.finance);}catch(e){console.info(e);financeJSON="{}";}}}
				<leasing-element class="sc-leasing-element" data-vehicle-id="{{=vehicle.id}}" data-vehicle-finance='{{=financeJSON}}'></leasing-element>
			{{??}}
				<finance-element class="sc-finance-element sc-car-tile-finance-element" data-vehicle-id="{{=vehicle.id}}"></finance-element>
			{{?}}

			{{? vehicle.suggestedCar && vehicle.suggestedCar === true && vehicle.percentage.length != '' }}
				${similarCarsCircleTemplate}
			{{?}}
	`;
export {exports as vehicleTilesTop};
