import {polling} from 'core-utils';

class GoogleControllerClass {
	constructor() {
		this.autoCompleteService = null;
	}

	/**
	 * getMap get new google map instance
	 * @param {HTMLElement} element_ html element where map should be rendered
	 * @param {number} zoom_ - initial zoom factor
	 * @param {number} center_ - initial map center
	 * @returns {google.map} google map object
	 */
	getMap(element_, zoom_ = 10, center_) {
		return new google.maps.Map(element_, {
			center: this.getLatLng(center_[0], center_[1]),
			zoom: zoom_,
			mapTypeControl: false,
			streetViewControl: false,
			disableDefaultUI: true,
			zoomControl: false,
			scaleControl: false,
			scrollwheel: false,
			navigationControl: false,
			draggable: false,
			disableDoubleClickZoom: true,
			mapTypeId: google.maps.MapTypeId.ROADMAP
		});
	}

	/**
	 * getLatLng
	 * @param {string} lat latitude
	 * @param {string} lng longitude
	 * @returns {google.map.latLng} returns google maps lat lang
	 */
	getLatLng(lat, lng) {
		return new google.maps.LatLng(Number(lat), Number(lng));
	}

	/**
	 * addResizeHandler add google map resize handler
	 * @param {function} fn callback function
	 * @param {google.map} map google map instance
	 * @returns {void} void
	 */
	addResizeHandler(fn, map) {
		google.maps.event.addDomListener(window, 'resize', () => {
			fn(map);
		});
	}

	/**
	 * removeResizeListener - clear all maps handler
	 * @returns {void} void
	 */
	removeResizeListener() {
		google.maps.event.clearListeners(window, 'resize');
	}

	/**
	 * getCityNameSuggestions
	 * @param {string} addressOrZipCode addressOrZipCode
	 * @param {string} localeCountry localeCountry
	 * @returns {Promise<any>} city name suggestions
	 */
	getCityNameSuggestions(addressOrZipCode, localeCountry) {
		const options = {
			input: addressOrZipCode,
			componentRestrictions: {country: localeCountry},
			types: ['(regions)']
		};

		// Initialize service if necessary.
		if (!this.autoCompleteService) {
			this._initializeAutoCompleteService();
		}
		return new Promise((resolve, reject) => {
			this.autoCompleteService.getPlacePredictions(
				options,
				(results, status) => {
					if (status === 'OK') {
						const suggestions = this._generateSuggestions(results);
						resolve(suggestions);
					}
					else {
						reject(new Error('Could not retrieve address suggestions: ' + status)
						);
					}
				}
			);
		});
	}

	/**
	 * initializeAutoCompleteService
	 * @returns {void} returns nothing
	 */
	_initializeAutoCompleteService() {
		try {
			this.autoCompleteService = new google.maps.places.AutocompleteService();
		}
		catch (error) {
			console.error(error);
		}
	}

	/**
	 * getPlaceDetail
	 * @param {string} placeId placeId
	 * @returns {Promise<any>} places array
	 */
	async getPlaceDetail(placeId) {
		this.geocoderService = new google.maps.Geocoder();
		return new Promise((resolve, reject) => {
			this.geocoderService.geocode({'placeId': placeId}, (results, status) => {
				if (status === 'OK') {
					resolve(results);
				}
				else {
					reject('No placeId' + status + ' found');
				}
			});
		});
	}

	/**
	 * generateSuggestions
	 * @param {array<object>} results - results response from google request
	 * @return {Array} suggestions list of suggestions
	 */
	_generateSuggestions(results) {
		let suggestions = [];

		for (let i = results.length - 1; i >= 0; i--) {
			const displayText = {};
			const descriptionParts = results[i].description.split(',');

			if (descriptionParts.length > 1) {
				descriptionParts.pop(); // remove country, its always the last element
			}
			displayText.city = descriptionParts.join();
			displayText.place_id = results[i].place_id;
			suggestions.unshift(displayText);
		}

		return suggestions;
	}

	/**
	 * getCircle
	 * @param {google.map} map_ google maps instance
	 * @returns {google.map.circle} returns google map circle
	 */
	getCircle(map_) {
		return new google.maps.Circle({
			map: map_,
			fillColor: '#6d7579',
			fillOpacity: 0.5,
			strokeColor: '#FF0000',
			strokeOpacity: 0,
			strokeWeight: 1
		});
	}

	/**
	 * getAddressFromLatLng
	 * @param {string} lat_ latitude
	 * @param {string} lng_ longitude
	 * @returns {Promise<any>} location address
	 */
	getAddressFromLatLng(lat_, lng_) {
		const geocoder = new google.maps.Geocoder();
		const latLng = this.getLatLng(lat_, lng_);

		return new Promise((resolve, reject) => {
			geocoder.geocode({latLng: latLng}, (results, status) => {
				if (status === google.maps.GeocoderStatus.OK) {
					resolve(results[0].formatted_address);
				}
				else {
					reject(status);
				}
			});
		});
	}

	/**
	 * isGoogleAvailablePolling polls 3 sec and checks if window.google is available
	 * @returns {Promise} resolves promise if google is available within 3 sec
	 */
	isGoogleAvailablePolling() {
		return polling.wait(() => !!window.google, 3000);
	}
}

/**
 * GoogleControllerClass instance
 * @type {GoogleControllerClass}
 */
const googleController = new GoogleControllerClass();

export {GoogleControllerClass, googleController};
