import FilterChipItem from "./filter-chip-item";
import {FilterDTO} from "../../../stockcars-filter-bundle";
import {dom} from "core-utils";
import {SELECTORS, STCK_STORE} from '@oneaudi/stck-store';

export default class ScFilterChipFactory {
	/**
	 * static lookup table for special range radio filter labels
	 * @returns {{}} hash map with labelIds, translated values and maxValue
	 */
	static get rangeRadioLabelsLookup() {
		return !!SETUPS.get('stockcar.rangeradio.lookup') ? SETUPS.get('stockcar.rangeradio.lookup') : {};
	}

	/**
	 * static lookup table for filters with special translation whenever all items are selected
	 * @returns {{}} hash map with filterId and label
	 */
	static get allSelectedLabelsWhitelist() {
		return !!SETUPS.get('stockcar.allselectedpairs.labels') ? SETUPS.get('stockcar.allselectedpairs.labels') : {};
	}

	/**
	 * static lookup table for special  filter labels
	 * @returns {{}} hash map with labelIds, translated values or sringtemplate
	 */
	static get filterOptionLabels() {
		return !!SETUPS.get('stockcar.filteroption.text.lookup') ? SETUPS.get('stockcar.filteroption.text.lookup') : {};
	}

	/**
	 * check if a filterID has a special RangeLabel
	 * @param {string} cleanedFilterID_ cleaned filterID (min_max rangelabels have manipulated id suffix ":min" or ":max")
	 * @returns {boolean} has special label
	 */
	static isSpecialRangeRadioLabel(cleanedFilterID_ = "") {
		return !!ScFilterChipFactory.rangeRadioLabelsLookup[cleanedFilterID_];
	}


	/**
	 * static methodcreturns filter chip data for a grouped chip
	 * @param {object} group_ geroup
	 * @param {string} groupname_ name
	 * @returns {FilterChipItem} group-chip data
	 * @private
	 */
	static generateGroupedChipItem(group_ = {}, groupname_ = "") {
		const selectedFiltersArr = group_.selectedFilter || [];
		// fallback for "allSelected" 1st CQ-value 2nd SCS-allSelected Label
		let label = ScFilterChipFactory.allSelectedLabelsWhitelist[groupname_] || group_.allSelectedText;
		label = label || ScFilterChipFactory.generateUppercaseName(groupname_);
		const id = group_.group;
		let removeArr = [];
		selectedFiltersArr.forEach((filter_) => {
			removeArr = [...removeArr, ...filter_.remove];
		});
		return new FilterChipItem(id, label, removeArr, false);
	}

	static generateUppercaseName(groupname_) {
		if (groupname_.length > 3) {
			let label = [...groupname_];
			label[0] = label[0].toUpperCase();
			label = label.join('');
			return label;
		}
		return groupname_.toUpperCase();
	}

	/**
	 * returns an array of filter chip data for one group
	 * @param {object} group_ filtergroup
	 * @param {boolean} searchAgent is a search agent (filterOptions) filter item
	 * @returns {array<FilterChipItem>} array of FilterChipItems
	 */
	static generateChipItemsDataArray(group_ = {}, searchAgent = false) {
		const selectedFiltersArr = group_.selectedFilter || [];
		const items = [];
		selectedFiltersArr.forEach((filter) => {
			let filterDTO = ScFilterChipFactory.getFilterDTO(filter, searchAgent);
			const filterLabel = filter.text || group_.name;
			if (filterDTO) {
				filterDTO.group = group_.group;
				switch (filterDTO.type) {
					case FilterDTO.types.RANGE_FILTER:
						items.push(ScFilterChipFactory.getRangeTemplateData(filterDTO, filterLabel, filter.display));
						break;

					case FilterDTO.types.MINMAX_RANGE_FILTER:

						let filterProps = { // eslint-disable-line no-case-declarations
							id: filterDTO.id + ':min',
							active: filterDTO.active,
							preset: filterDTO.preset,
							values: [filterDTO.values[0], ''],
							group: filterDTO.group
						};
						const minRangeFilter = new FilterDTO(filterProps); // eslint-disable-line no-case-declarations
						items.push(ScFilterChipFactory.getRangeTemplateData(minRangeFilter, filterLabel, filter.display));

						filterProps = {
							id: filterDTO.id + ':max',
							active: filterDTO.active,
							preset: filterDTO.preset,
							values: ['', filterDTO.values[1]],
							group: filterDTO.group
						};
						const maxRangeFilter = new FilterDTO(filterProps); // eslint-disable-line no-case-declarations
						items.push(ScFilterChipFactory.getRangeTemplateData(maxRangeFilter, filterLabel, filter.display));
						break;

					case FilterDTO.types.LOCATION_FILTER:
						items.push(ScFilterChipFactory.getLocationTemplateData(filterDTO));
						break;
					case FilterDTO.types.BASIC_FILTER:
					default:
						let label = filterLabel; // eslint-disable-line
						if (group_.text && !filter.uniqueText) {
							label = group_.text + ': ' + filterLabel;
						}
						items.push(ScFilterChipFactory.getBasicTemplateData(filterDTO, label));
				}
			}
		});
		return items;
	}

	/**
	 * get known filter from store or create a temporary
	 * filter from SearchAgent´s filterOptions
	 * @param {object} filter_ filter object
	 * @param {boolean} searchAgent (filterOptions) is SerachAgent item
	 * @returns {null|FilterDTO} null or a FilterDTO
	 */
	static getFilterDTO(filter_ = {}, searchAgent = false) {
		if (!filter_.id) {
			return null;
		}

		if (searchAgent) {
			let values = filter_.remove && Array.isArray(filter_.remove) ? filter_.remove : null;
			if (filter_.id === "geo") {
				values = filter_.display;
			}
			if (values) {
				values = values.map((val) => (val === 'null') ? null : val);
			}
			return new FilterDTO({id: filter_.id, active: true, values: values, group: filter_.group});
		}
		else {
			return SELECTORS.FILTERS.getFilterDTOsMap(STCK_STORE.state).get(filter_.id);
		}
	}

	/**
	 * generate template data for range chips
	 * @param {FilterDTO} filterDTO_ - filter object
	 * @param {string} filterLabel_ - filter's label
	 * @param {array} displayValues_ - special values to display
	 * @returns {FilterChipItem} range filter chip item
	 */
	static getRangeTemplateData(filterDTO_, filterLabel_ = "", displayValues_) { // eslint-disable-line max-params
		if (!filterDTO_) {
			throw new Error("missing param filterDTO_!");
		}
		const id = filterDTO_.id;
		const removeArr = [filterDTO_.id];
		const label = ScFilterChipFactory.getRangeValueLabel(filterDTO_, displayValues_, filterLabel_);
		return new FilterChipItem(id, label, removeArr, FilterDTO.isMemberOfSyntheticFilterGroup(filterDTO_));
	}

	/**
	 * get the label for a range filter chip using special TextTemplate mechanism
	 * @param {FilterDTO} filterDTO_ - filterDTO object
	 * @param {array} displayValues_ - special display values
	 * @param {string} filterLabel_ - fallback name
	 * @returns {string} label text for breadcrumb display
	 */
	static getRangeValueLabel(filterDTO_, displayValues_ = [], filterLabel_) { // eslint-disable-line max-params, max-statements
		if (!filterDTO_) {
			throw new Error("missing param filterDTO_!");
		}
		let minValue = filterDTO_.values[0];
		let maxValue = filterDTO_.values[1];
		let labelValue;
		// min_max rangelabels have manipulated id suffix ":min" or ":max"
		const cleanedFilterID = filterDTO_.id.split(":")[0];
		const [specialLabel, specialTemplate] = ScFilterChipFactory.filterOptionLabels[cleanedFilterID] ? ScFilterChipFactory.filterOptionLabels[cleanedFilterID].split("|") : [];

		// handle special rangeRadio CQ-Label radios values ('4 or more')
		if (ScFilterChipFactory.isSpecialRangeRadioLabel(cleanedFilterID)) {
			labelValue = ScFilterChipFactory.getSpecialRangeRadioMaxDisplayValue(
				filterDTO_,
				maxValue
			);
		}

		// if no range radio or no matching value conversion
		if (!labelValue) {
			// handles display values with special dotJS text templating
			if (specialTemplate && displayValues_.length) {
				const renderedMinMax = ScFilterChipFactory.renderSpecialTuples(specialTemplate, displayValues_);
				if (!!maxValue) {
					maxValue = renderedMinMax.renderedMax || maxValue;
				}
				if (!!minValue) {
					minValue = renderedMinMax.renderedMin || minValue;
				}
			}

			labelValue = ScFilterChipFactory.buildMinMaxValueTextFragments({minValue, maxValue});
		}

		const label = specialLabel || filterLabel_ || filterDTO_.id.toUpperCase();
		return `${label} ${labelValue}`;
	}

	/**
	 * get special names for radio ranges like "4 or more" from whitelist
	 * data attribute 'data-rangeradio-labels'
	 * @param {FilterDTO} filterDTO filter item
	 * @param {number} maxValue_ selected value (range radios use maxValue only)
	 * @returns {string|null} new value for this filter or null
	 * @private
	 */
	static getSpecialRangeRadioMaxDisplayValue(filterDTO = {}, maxValue_) {
		let displayString;
		const maxValue = parseInt(maxValue_, 10);
		if (filterDTO.id && !isNaN(maxValue)) {
			const rangeRadioCqLabel = ScFilterChipFactory.rangeRadioLabelsLookup[filterDTO.id];
			if (rangeRadioCqLabel && rangeRadioCqLabel.label) {
				displayString = maxValue_ >= parseInt(rangeRadioCqLabel.maxvalue, 10) ? rangeRadioCqLabel.label : maxValue_;
			}
		}
		return displayString || null;
	}

	/**
	 * get basic template data for filter chip item
	 * @param {FilterDTO} filter - filter id
	 * @param {string} filterLabel  - filter label or filter group name (fallback label)
	 * @return {FilterChipItem}  filter chip template item
	 */
	static getBasicTemplateData(filter, filterLabel) {
		return new FilterChipItem(filter.id, filterLabel, [filter.id], FilterDTO.isMemberOfSyntheticFilterGroup(filter));
	}

	/**
	 * get location filterchip template data
	 * @param {FilterDTO} filterDTO locationfilter (FilterDTO)
	 * @return {FilterChipItem} template item
	 */
	static getLocationTemplateData(filterDTO) {
		if (!filterDTO || filterDTO.type !== FilterDTO.types.LOCATION_FILTER) {
			throw new Error("missing/wrong param filterDTO!");
		}
		const radiusLabel = window.i18n['sc.filter-location.range.label'];
		const label = `${filterDTO.values[4].split('|').join(', ')} , ${filterDTO.values[2]} ${ScFilterChipFactory.getTranslatedDistanceRangeUnit(filterDTO.values[3])} ${radiusLabel}`;
		const id = 'geo';
		const removeArr = ['geo'];
		return new FilterChipItem(id, label, removeArr, FilterDTO.isMemberOfSyntheticFilterGroup(filterDTO));
	}

	/**
	 * translate distance unit label
	 * @param {string} distanceUnit_ distance unit (e.g. km, ms)
	 * @returns {String} translated unit for the given range
	 */
	static getTranslatedDistanceRangeUnit(distanceUnit_ = ' ') {
		const unitLabel = window.i18n['sc.range-unit.label.' + distanceUnit_] || distanceUnit_;
		return unitLabel;
	}


	/**
	 * DotJS rendering of special tuples
	 * @param {string} dotJsTemplateString template to display the tuples
	 * @param {array} displayValues_ - array off all min or all max value tuples
	 * @returns {object} object with rendered strings {renderedMin, renderedMax}
	 */
	static renderSpecialTuples(dotJsTemplateString = '{{=it.minmax1}}', displayValues_) {
		const dataTuples = ScFilterChipFactory.getValueTuples(displayValues_);

		let tplMinDataTouples = {};
		dataTuples.min.forEach((value, index) => {
			tplMinDataTouples['minmax' + (index + 1)] = value;
			tplMinDataTouples['minmax' + (index + 1)] = value;
		});

		const renderedMin = Object.keys(tplMinDataTouples).length ? dom.renderTemplate(dotJsTemplateString, tplMinDataTouples) : null;
		let tplMaxDataTouples = {};
		dataTuples.max.forEach((value, index) => {
			tplMaxDataTouples['minmax' + (index + 1)] = value;
			tplMaxDataTouples['minmax' + (index + 1)] = value;
		});

		const renderedMax = Object.keys(tplMaxDataTouples).length ? dom.renderTemplate(dotJsTemplateString, tplMaxDataTouples) : null;
		return {renderedMin, renderedMax};
	}


	/**
	 * build value string connecting values (min, max or min and max) and their separators (e.g. from…to, starting at…up to)
	 * @param {string|number} minValue original numeric min value or a patched tuple of min values 40kW/60PS
	 * @param {string|number} maxValue original numeric min value or a patched tuple of min values 40kW/60PS
	 * @returns {string} value string like "from 45 PS to 175 PS"
	 * @private
	 */
	static buildMinMaxValueTextFragments({minValue, maxValue}) {
		const minSeparator = window.i18n['sc.filter-range.from.label'];
		const maxSeparator = window.i18n['sc.filter-range.to.label'];
		let valuesLabel;
		if (!!minValue && minValue !== 'null') {
			if (!!maxValue && minValue !== 'null') {
				// min and max values
				valuesLabel = `${minSeparator} ${minValue} ${maxSeparator} ${maxValue}`;
			}
			else {
				// min value only
				valuesLabel = `${minSeparator} ${minValue}`;
			}
		}
		else {
			// max Value only
			valuesLabel = `${maxSeparator} ${maxValue}`;
		}
		return valuesLabel;
	}

	static getValueTuples(valuesTuplesArray = []) {
		let minValuesArray = [], maxValuesArray = [], len, i = 0;
		len = valuesTuplesArray.length;
		while (i < len) {
			minValuesArray.push(valuesTuplesArray[i++]);
			maxValuesArray.push(valuesTuplesArray[i++]);
		}
		return {min: minValuesArray, max: maxValuesArray};
	}


	/**
	 * checks if the preconditions match to display a group's filtermembers as one chip
	 * => has to be allSelected && (has an existing translation for all or group or is a carline)
	 * @param {object} group_ a group of selected filters
	 * @param {string} groupname_ name of the group
	 * @returns {boolean} is group chip ?
	 * @private
	 */
	static isGroupFilterChip(group_ = {}, groupname_) {
		return !!(group_.allSelected && (ScFilterChipFactory.allSelectedLabelsWhitelist[groupname_] || group_.group === 'carlinegroups' || group_.group === 'bodytypes'));
	}

	/**
	 * get a group's template data as an array (one or many chips)
	 * @param {Object} group_  - filtergroup item
	 * @param {string} groupname_ - filtergroup name/key
	 * @param {boolean} searchAgent is a search agent (filterOptions) filter item
	 * @return {array<FilterChipItem>}  array with template filter items
	 */
	static getGroupTemplateDataArray(group_ = {}, groupname_ = "", searchAgent = false) {
		if (ScFilterChipFactory.isGroupFilterChip(group_, groupname_)) {
			const groupedItem = ScFilterChipFactory.generateGroupedChipItem(group_, groupname_);
			return [groupedItem];
		}
		else {
			return [...ScFilterChipFactory.generateChipItemsDataArray(group_, searchAgent)];
		}
	}

	/**
	 * prepare template date
	 * @param {Object} filterOptions_ - object with all filter options by group
	 * @param {boolean} searchAgent is a search agent (filterOptions) filter item
	 * @returns {Object} template model
	 */
	static getBreadCrumbTemplateData(filterOptions_ = {}, searchAgent = false) {
		let tplData = [];
		Object.entries(filterOptions_).forEach(([groupId, group]) => {
			// add multiple items
			const itemsArr = ScFilterChipFactory.getGroupTemplateDataArray(group, groupId, searchAgent);
			tplData = [...tplData, ...itemsArr];
		});

		return ScFilterChipFactory.removeDuplicateFilterChips(tplData);
	}

	/**
	 * remove duplicate chips
	 * @param {Array} tplArray_ array with chips
	 * @returns {Array} unique array of filterchips
	 */
	static removeDuplicateFilterChips(tplArray_ = []) {
		// remove single chips tha are already included in grouped chips
		let outputArray = [...tplArray_];
		outputArray = ScFilterChipFactory.stripIncludedChips(outputArray);
		outputArray = ScFilterChipFactory.uniqueArrayByPropertyValue(outputArray, 'label');
		// outputArray = ScFilterChipFactory.stripDuplicateSyntheticFilters(outputArray);
		return outputArray;
	}

	/**
	 * remove duplicate filters that are included in a synthetic group as well
	 * @param {Array} tplArray_ array with filterchips
	 * @returns {Array} cleaned array with filterchips
	 */
	static stripDuplicateSyntheticFilters(tplArray_ = []) {
		const clone = [...tplArray_];
		var duplicateIdsArray = clone.filter((element, index, arr) => {
			const res = arr.filter((el) => el._id === element._id && el !== element && element._synthetic);
			return res.length ? res : null;
		});
		const withoutDuplicateSyntheticFilters = clone.filter((item) => duplicateIdsArray.indexOf(item) === -1); // eslint-disable-line no-bitwise

		return withoutDuplicateSyntheticFilters;
	}

	/**
	 * remove single items/chips that are already included in grouped items/chips
	 * @param {array} tplArray_ array of filterchips to be rendered
	 * @returns {array} copy of the original array excluding duplicate items
	 */

	static stripIncludedChips(tplArray_ = []) {
		// array of all package items
		const packagesArray = tplArray_.filter(entry => Array.isArray(entry._removeArr) && entry._removeArr.length > 1);
		// array of all non-package items
		const singleItemsArray = tplArray_.filter(entry => Array.isArray(entry._removeArr) && entry._removeArr.length === 1);
		const packageIDs = packagesArray.reduce((merged, currentItem) => [...merged, ...currentItem._removeArr], []);
		// array of items that are not included within a package
		const notIncludedItemsArray = packageIDs.length ?singleItemsArray.filter(item => !packageIDs.includes(item._removeArr[0])):singleItemsArray;
		const mergedItemsArray = [...notIncludedItemsArray, ...packagesArray];
		return mergedItemsArray;
	}

	/**
	 * removes items with duplicate property value from an array
	 * @param {array} myArr - array to remove items with duplicate property value from
	 * @param {string} prop - property values to be unique
	 * @returns {array} clone array without items with duplicate property
	 * @private
	 */
	static uniqueArrayByPropertyValue(myArr = [], prop) {
		if (!prop) {
			return [...myArr];
		}
		return myArr.filter((obj, pos, arr) => {
			return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
		});
	}
}
