import {dom} from 'core-utils';
import {searchAgentController} from './search-agent-controller';
import {searchAgentObserver} from './search-agent-observer';
import {searchAgentState} from './search-agent-state';

/**
 * selectors
 * @type {{dialogueWrapper: string, dialogue: string, deleteLayerButton: string, closeDeleteLayerButton: string, loginLayer: string, updateButton: string, dashboard: string, showResultsButton: string, removeButton: string, hiddenClass: string}}
 */
const selectors = {
	hidden: 'sc-hidden',
	searchAgentCount: '.sc-j-search-agent-count',
	searchAgentMessageNotification: '.sc-j-search-agent-notification-message',
	searchAgentMessageNotificationStatus: '.sc-j-search-agent-notification-message span'
};

export default class SearchAgentDashboard extends HTMLElement {
	constructor() {
		super();
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		searchAgentObserver.addObserver(this);
		this._setElementDomProperties();
		this._prepareRendering();
		this._setStartPageUrl();
	}

	/**
	 * update - base model callback function
	 * @returns {void} void
	 */
	update() {
		this._fetchAndRenderAgentsData();
	}

	// PRIVATE FUNCTIONS

	/**
	 * set element properties
	 * @returns {void} returns nothing
	 */
	_setElementDomProperties() {
		this.searchAgentCount = dom.getElement(selectors.searchAgentCount, this);
	}

	/**
	 * prepare dashboard rendering
	 * @returns {void} returns nothing
	 */
	async _prepareRendering() {
		await this._saveSearchAgentsFromEntry();
		this._fetchAndRenderAgentsData();
	}

	/**
	 * _setStartPageUrl
	 * @private
	 * @returns {void} void
	 */
	_setStartPageUrl() {
		searchAgentController.startPageUrl = SETUPS.get('stockcar.url.startpage') || '';
	}

	/**
	 * save search agent from entry
	 * @returns {void} returns nothing
	 */
	async _saveSearchAgentsFromEntry() {
		try {
			const entry = searchAgentState.searchAgentEntry;
			if (entry && entry.action && entry.action === 'save') {
				await searchAgentController.saveSearchAgent();
				searchAgentState.clearSearchAgentStorage();
			}
		}
		catch (error) {
			console.error(error);
			throw error;
		}
	}

	/**
	 * fetch search agents array and trigger render
	 * @returns {void} nothing
	 */
	async _fetchAndRenderAgentsData() {
		try {
			const payload = await searchAgentController.getAllSearchAgents();
			this._render(payload);
			searchAgentState.renderedState = true;
		}
		catch (error) {
			console.log('Could not fetch User Agents: ', error);
		}
	}

	/**
	 * render search agent items/entries to the DOM
	 * @param {array} searchAgentsArray_ array of serach agent items/entries
	 * @returns {void}
	 */
	_render(searchAgentsArray_) {
		// SET COUNT INITIAL
		this.currentSearchAgentCount = searchAgentsArray_.length;
		this.searchAgentCount.innerText = this.currentSearchAgentCount;

		const searchDashboardItems = dom.getElementsArray('search-agent-dashboard-item', this);
		const searchAgentItemsContainer = dom.getElement('.sc-search-agent-dashboard-items', this);
		searchDashboardItems.forEach(item => {
			searchAgentItemsContainer.removeChild(item);
		});
		// RENDER SEARCHAGENT ITEMS
		searchAgentsArray_.forEach((item) => {
			const searchAgentDashboardItem = `
						<search-agent-dashboard-item
							class="sc-search-agent-dashboard-item"
							search-agent-filter-options='${JSON.stringify(item.filterOptions)}'
							search-agent-url='${item.url}'
							search-agent-id='${item.id}'
							search-agent-createdate='${item.createDate}'
							search-agent-enddate='${item.endDate}'
							search-agent-extendUntilDate='${item.extendUntilDate}'
							search-agent-extendableToday='${item.extendableToday}'
							search-agent-notification='${item.notification}'
							search-agent-amountcars='${item.amountCars}'
							search-agent-status-extended='false'>
						</search-agent-dashboard-item>
				`;
			dom.appendHtmlString(searchAgentItemsContainer, searchAgentDashboardItem);
		});
	}

	/**
	 * update search agents count to the DOM
	 * @returns {void}
	 */
	_updateSearchAgentsCount() {
		// SET COUNT ON CHANGE
		this.currentSearchAgentCount -= 1;
		this.searchAgentCount.innerText = this.currentSearchAgentCount;
	}
}

if (!window.customElements.get('search-agent-dashboard', SearchAgentDashboard)) {
	window.customElements.define('search-agent-dashboard', SearchAgentDashboard);
}


