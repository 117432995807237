import {vehicleTilesDealerContactSpain} from './markets/spain/vehicle-tiles-dealer-contact-spain';

const exports = `
	{{? it.data.useSpainTemplate}}
		${vehicleTilesDealerContactSpain}
	{{??}}
		<div class="sc-dealer-contact">
			{{? vehicle && vehicle.dealer }}
				{{? vehicle.dealer.phoneNumbers && vehicle.dealer.phoneNumbers.length > 0 && !(!!SETUPS.get('scopes.ecom.show.contact') && vehicle.buyableOnline == true)}}
				{{var showHotline = (!!SETUPS.get('scopes.show.hotline') && vehicle.type == 'U' && vehicle.nationWideSelling); }}
					{{~vehicle.dealer.phoneNumbers :number}}
						{{? number.type == "phone" && !!number.number}}
							<a class="audi-link-s audi-link-s--arrow" href="tel:{{=number.number.replace(/\\s/g, '')}}">
								<span class="audi-link-s__text">{{=window.i18n['sc.details.dealer-phone']||''}}: {{=number.number}}{{? showHotline}}&nbsp;{{=window.i18n['sc.hotline.free.label']||'(free of charge)'}}{{?}}</span>
								<svg class="audi-link-s__icon">
									<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
								</svg>
							</a>
						{{?}}
					{{~}}
				{{?}}
				<vehicle-tiles-button-element class="sc-vehicle-tiles-button-element" data-link-type="link" data-vehicle-id="{{=vehicle.id}}"></vehicle-tiles-button-element>
				{{? vehicle.whatsAppLink}}
					<a class="audi-link-s audi-link-s--arrow" href="{{=vehicle.whatsAppLink}}" target="_blank" rel="noopener noreferrer">
						<span class="audi-link-s__text">{{=window.i18n['sc.details.dealer-whatsapp.label']||''}}</span>
						<svg class="audi-link-s__icon">
							<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
						</svg>
					</a>
				{{?}}
			{{?}}
		</div>
	{{?}}
`;

export {exports as vehicleTilesDealerContact};
