const exports = `
	<div class="sc-search-agent-dashboard-item-wrapper">
		<button class="sc-search-agent-dashboard-item-close-button">
			<svg class="nm-icon-close-item nm-icon-cancel">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel.svg#nm-icon-cancel"}}></use>
			</svg>
		</button>
		<button class="sc-search-agent-dashboard-item-edit-button">
			<svg class="nm-icon-edit-item nm-icon-edit-small">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/edit-small.svg#nm-icon-edit-small"}}></use>
			</svg>
		</button>
		<div class="sc-search-agent-item-headline">
			<p class="sc-j-search-agent-title sc-search-agent-title sc-hyphenate audi-headline-order-3">{{=it.title}}</p>
			<p class="sc-search-agent-create-date audi-copy-s">{{=window.i18n['sc.searchagent.dashboard.created']||'Erstellt am'}} {{=it.createDate}}</p>
		</div>

		<div class="sc-search-agent-active-filters">
			<p class="sc-search-agent-active-filters-headline audi-headline-order-3">{{=window.i18n['sc.searchagent.dashboard.filterselection']||'Filterauswahl'}}</p>
			<div class="sc-search-agent-filter-chips">
				<ul>
					{{ for(var prop in it.filterOptions) { }}
						<li class="sc-search-agent-filter-chip audi-copy-m">{{=it.filterOptions[prop].label}}</li>
					{{ } }}
				</ul>
			</div>
		</div>

		<div class="sc-search-agent-extended-wrap">
			<div class="sc-search-agent-duration" data-is-extendable="{{=it.showExtendLink}}">
				<p class="sc-search-agent-duration-headline audi-headline-order-3">{{=window.i18n['sc.searchagent.dashboard.duration']||'Suchauftragsdauer'}}</p>
				<p class="sc-search-agent-duration-extend-message sc-hidden">
					<svg class="nm-icon-confirmation-large sc-icon-search-agent-duration-confirmation">
						<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/confirmation-large.svg#nm-icon-confirmation-large"}}></use>
					</svg>
					<span class="audi-copy-s">{{=window.i18n['sc.searchagent.dashboard.extend.notification']||'Ihr Suchauftrag wurde verlängert.'}}</span>
				</p>
				<p class="sc-search-agent-duration-text">
					<span class="audi-copy-s sc-search-agent-duration-text-small">{{=window.i18n['sc.searchagent.dashboard.enddate']||'Endet am'}} {{=it.endDate}}</span>
					<span class="audi-copy-m sc-search-agent-duration-text-medium">{{=window.i18n['sc.searchagent.dashboard.enddate']||'Endet am'}} {{=it.endDate}}</span>
				</p>
				<a class="sc-search-agent-duration-extend audi-link-m audi-link-m--underline sc-j-search-agent-duration-extend">
					<svg class="nm-icon-duration-extend nm-icon-time-long-small">
						<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/time-long-small.svg#nm-icon-time-long-small"}}></use>
					</svg>
					<span>{{=window.i18n['sc.searchagent.dashboard.extend.until']||'Verlängern bis'}} {{=it.extendUntilDate}}</span>
				</a>
				<p class="sc-search-agent-duration-description audi-copy-s">
					<span class="sc-search-agent-duration-description-not-extendable">{{=window.i18n['sc.searchagent.dashboard.active.notification']||'Ihr Suchauftrag ist für die nächsten 30 Tage aktiv. Bevor er automatisch beendet wird, erhalten Sie eine E-Mail mit der Möglichkeit, diesen zu verlängern.'}}</span>
					<span class="sc-search-agent-duration-description-extendable">{{=window.i18n['sc.searchagent.dashboard.inactive.notification']||'Bevor Ihr Suchauftragausläuft werden Sie per E-Mail benachrichtigt.'}}</span>
				</p>
			</div>

			/* custom version of global switch element https://kb.dasburo.com/pages/viewpage.action?pageId=68444269 */
			{{? it.showNotification}}
				<div class="sc-search-agent-email-notification" data-notification-status="{{=it.notification}}">
					<p class="sc-search-agent-notification-headline audi-headline-order-3">{{=window.i18n['sc.searchagent.dashboard.email.notification']||'E-Mail Benachrichtigung'}}</p>
					<div class="sc-search-agent-email-notification-switch audi-switch">
						<input id="audi-switch-{{=it.id}}" class="audi-switch-checkbox sc-j-search-agent-change-notification-status" type="checkbox" {{?it.notification && it.notification=="true"}}checked="checked"{{?}}>
						<label for="audi-switch-{{=it.id}}" class="audi-switch-label">
							<span class="audi-switch-label-status audi-switch-label-active audi-copy-m">{{=window.i18n['sc.searchagent.dashboard.notification.activated']||'Benachrichtigung aktiviert'}}</span>
							<span class="audi-switch-label-status audi-switch-label-inactive audi-copy-m">{{=window.i18n['sc.searchagent.dashboard.notification.deactivated']||'Benachrichtigung deaktiviert'}}</span>
						</label>
					</div>
					<div class="sc-search-agent-short-email-notification audi-copy-s">
						<span class="sc-search-agent-email-notification-activated">{{=window.i18n['sc.searchagent.dashboard.notification.short.activated']||'E-mail Benachrichtigung aktiviert'}}</span>
						<span class="sc-search-agent-email-notification-deactivated">{{=window.i18n['sc.searchagent.dashboard.notification.short.deactivated']||'Email Benachrichtigung deaktiviert'}}</span>
					</div>
				</div>
			{{?}}
		</div>

		<div class="sc-search-agent-item-buttons">
			{{?it.amountCars==='0' }}
				<p class="sc-search-agent-no-results-label audi-copy-m">0 {{=window.i18n['sc.searchagent.dashboard.results']||'Ergebnisse'}}</p>
			{{??}}
				<button class="audi-button sc-search-agent-show-results-button" data-href=""><span class="audi-button__text">{{=it.amountCars}} {{=window.i18n['sc.searchagent.dashboard.showresults']||'Ergebnisse anzeigen'}}</span></button>
			{{?}}
			<div class="sc-search-agent-item-show-delete-layer">
				<button class="sc-search-agent-delete-item-button audi-button audi-button--ghost"><span class="audi-button__text">{{=window.i18n['sc.searchagent.dashboard.deletesearch']||'Suchauftrag löschen'}}</span></button>
				<svg class="nm-icon-delete-item nm-icon-erase"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/nm-icon-erase.svg#nm-icon-erase"}}></use></svg>
			</div>
		</div>

		<div class="sc-dialogue-wrapper sc-hidden" data-active="false" data-theme="dark">
			<div class="sc-dialogue">
				<div class="sc-dialogue-content">
					<p class="sc-dialogue-headline audi-headline-order-2">{{=window.i18n['sc.searchagent.dashboard.deletesearch']||'Suchauftrag löschen'}}</p>
					<p class="sc-dialogue-description audi-copy-m">{{=window.i18n['sc.searchagent.dashboard.deletesearch.query']||'Möchten Sie diesen Suchauftrag wirklich löschen?'}}</p>
					<div class="sc-dialogue-content-button-wrap">
						<button class="audi-button sc-search-agent-remove-button"><span class="audi-button__text">{{=window.i18n['sc.searchagent.dashboard.yes']||'Ja'}}</span></button>
						<button class="audi-button audi-button--ghost sc-search-agent-item-close-delete-layer"><span class="audi-button__text">{{=window.i18n['sc.searchagent.dashboard.no']||'Nein'}}</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
`;

export {exports as searchAgentDashboardItemTemplate};
