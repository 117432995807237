const exports = `
	{{? it.url && it.infoText }}
		<a href="{{=it.url}}" class="nm-layerLink audi-link-s audi-link-s--arrow">
			<span class="audi-link-s__text">{{=it.infoText}}</span>
			<svg class="sc-detail-carinfo-buyable-link-icon audi-link-s__icon">
			   <use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
			</svg>
		</a>
	{{?}}
`;

export {exports as dealerNwsInfoTemplate};
