import {patternMatching} from 'core-global';
import {vehiclesController} from "../vehicles/vehicles-controller";

class PatternScVehicleType {
	/**
	 * checks for vehicle type in url
	 * @method getVehicleType
	 * @returns {Promise} promise
	 */
	_getVehicleType () {
		return new Promise((resolve, reject) => {
			const vehicleId = this._getUrlParameterByName('vehicleid', location.href);
			if (vehicleId) {
				vehiclesController.loadVehicleById(vehicleId)
					.then((result) => {
						if (result.dealer && result.dealer.id && result.dealer.id === '05886') {
							resolve(window.i18n['sc.contact.salesperson'] || '');
						}
						else {
							resolve(window.i18n['sc.contact.dealercontact'] || '');
						}
					})
					.catch((err) => {
						reject(err);
					});
			}
			else {
				reject(new TypeError('load pattern -sc_vehicle_type- failed'));
			}
		});
	}

	/**
	 * get url parameter by name
	 * @method getUrlParameterByName
	 * @param {string} name - get parameter
	 * @param {string} url - url
	 * @returns {string} values of get parameter
	 */
	_getUrlParameterByName (name, url) {
		const _url = url || window.location.href;
		const _name = (name || '').replace(/[[\]]/g, '\\$&');
		const regex = new RegExp('[?&]' + _name + '(=([^&#]*)|&|#|$)');
		const results = regex.exec(_url);
		if (!results) {
			return null;
		}
		if (!results[2]) {
			return '';
		}
		return decodeURIComponent((results[2] || '').replace(/\+/g, ' '));
	}

	register () {
		this._getVehicleType = this._getVehicleType.bind(this);
		patternMatching.register('sc_vehicle_type', this._getVehicleType);
	}
}

const patternScVehicleType= new PatternScVehicleType();
patternScVehicleType.register();
export {patternScVehicleType, PatternScVehicleType};

