import {dom, template} from 'core-utils';
import {presetsTemplate} from './dealer-branding-presets-template';
import {SELECTORS, STCK_STORE} from '@oneaudi/stck-store';
import {scPage} from '../../../stockcars-utils-bundle';

export default class ScDealerPresetElement extends HTMLElement {
	constructor() {
		super();
		this._unsubscribeFilterObserverFromStoreCB = null;
	}

	/**
	 * subscribeToFilterModelChanges
	 * @returns {void} nothing
	 */
	subscribeToFilterModelChanges() {
		this.update = this.update.bind(this);
		this._unsubscribeFilterObserverFromStoreCB = STCK_STORE.observeStateFromStore(SELECTORS.FILTERS.getFilterPresetsRaw, this.update);
	}

	/**
	 * subscribeToVehiclesChanges
	 * @returns {void} nothing
	 */
	subscribeToVehiclesChanges() {
		this.update = this.update.bind(this);
		this._unsubscribeVehiclesObserverFromStoreCB = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getVehiclesRawState, this.update);
	}

	/**
	 * unsubscribeFromFilterModelChanges
	 * @returns {void} nothing
	 */
	unsubscribeFromFilterModelChanges() {
		this._unsubscribeFilterObserverFromStoreCB();
	}

	/**
	 * unsubscribeFromVehiclesChanges
	 * @returns {void} nothing
	 */
	unsubscribeFromVehiclesChanges() {
		this._unsubscribeVehiclesObserverFromStoreCB();
	}

	connectedCallback() {
		// get initial data on page.ready
		this.update();
		this.subscribeToFilterModelChanges();
		this.subscribeToVehiclesChanges();
	}

	disconnectedCallback() {
		this.unsubscribeFromFilterModelChanges();
		this.unsubscribeFromVehiclesChanges();
	}


	/**
	 * Basic update function, which has to be overwritten from each filter element module
	 * @returns {void} returns nothing
	 */
	async update() {
		// Do not render dealer header on the detail page
		if (scPage.isDetailsPage(location.href)) {
			return;
		}

		const presetFilters = SELECTORS.FILTERS.getPresetFiltersMap(STCK_STORE.state);
		if (presetFilters.size) {
			const dealerId = this._getDealerIdFromPresetMap();
			if (dealerId) {
				try {
					const dealerObject = await this._getDealerObject(dealerId);
					const dealerName = dealerObject.name;
					this._renderBrandingPreset(dealerName, dealerId);
				}
				catch (err) {
					console.error(err);
				}
			}
		}
	}

	/**
	 * get dealer id from preset Map
	 * @returns {string|null} dealerId or null
	 */
	_getDealerIdFromPresetMap() {
		let foundDealerID = null;
		const presetFilterKeys = [...SELECTORS.FILTERS.getPresetFiltersMap(STCK_STORE.state).keys()] || [];
		const presetDealerFilterKeys = presetFilterKeys.filter((key) => (/^dealer.\d+/).test(key));

		if (presetDealerFilterKeys.length) {
			const dealerPreset = SELECTORS.FILTERS.getPresetFiltersMap(STCK_STORE.state).get(presetDealerFilterKeys[0]);
			if (dealerPreset) {
				foundDealerID = dealerPreset.id.replace('dealer.', '');
			}
		}
		return foundDealerID;
	}

	/**
	 * renderBrandingPreset - renders dealer branding headline
	 * @param {string} dealerName_ - name of preset dealer
	 * @param {int} dealerID_ - id of preset dealer
	 * @return {void} returns nothing
	 */
	_renderBrandingPreset(dealerName_, dealerID_) {
		this.innerHTML = '';
		if (!!dealerName_) {
			const moduleString = template.render(presetsTemplate, {'dealerName': dealerName_, 'dealerID': dealerID_});
			dom.appendHtmlString(this, moduleString);
		}
	}


	/**
	 * getDealerObject - get dealer name and id from results first item, because item[0] is from correct dealer
	 * @param {string} dealerId_ - id of the dealer
	 * @return {string} returns dealer name
	 */
	async _getDealerObject(dealerId_) {
		let vehicles = SELECTORS.VEHICLES.getVehiclesRawState(STCK_STORE.state);
		let name = null;
		let id = null;
		const vehicleFromThisDealer = vehicles.find((vehicle) => vehicle.dealer && vehicle.dealer.id === dealerId_);
		if (vehicleFromThisDealer) {
			name = vehicleFromThisDealer.dealer.name;
			id = vehicleFromThisDealer.dealer.id;
		}
		else {
			// Dealer data was not found, probably because the cars are not loaded
			// yet or because the dealer doesnt have any cars at this moment.
			const loadedFromDSH = await this._loadDealerById(dealerId_);
			if (loadedFromDSH.partners && loadedFromDSH.partners.length) {
				name = loadedFromDSH.partners[0].name;
				id = loadedFromDSH.partners[0].id;
			}
		}
		return {
			'name': !!name ? this._prepareAudiLettering(name) : '',
			'id': !!id ? id : ''
		};
	}

	/**
	 *get Dealer by ID
	 * @param {string} dealerId_ - id of the selected dealer
	 * @returns {Promise} dealerresponse (id,name) object wrapped in a promise
	 */
	async _loadDealerById(dealerId_) {
		const clientID = SETUPS.get('stck.dealersearch.clientid');
		const dealersearchURL = SETUPS.get('stck.dealersearch.url');
		const country = SETUPS.get('stck.dealersearch.country');
		const url = `//${dealersearchURL}/${country}/id?q=${dealerId_}&clientId=${clientID}`;
		try {
			const requestOptions = {
				method: 'GET',
				headers: {
					Accept: 'application/json'
				},
				cache: 'no-cache'
			};
			const rawResponse = await fetch(url, requestOptions);
			const status = rawResponse.status;
			const response = await rawResponse.json();
			const error = new Error();

			if (status === 200) {
				return response;
			}
			else {
				error.message = 'unhandled error';
				throw error;
			}
		}
		catch (error) {
			throw error;
		}
	}

	/**
	 * prepareAudiLettering
	 * @param {string} dealerName_ dealer name
	 * @return {string} returns replaced dealer name with span around audi when string starts with audi
	 */
	_prepareAudiLettering(dealerName_ = "") {
		return dealerName_.replace(/^audi/i, '<span class=\'sc-headline-normal-bold sc-presets-audi-wording\'>Audi</span>');
	}

}
if (window.customElements.get('sc-dealer-preset-element') === undefined) {
	window.customElements.define('sc-dealer-preset-element', ScDealerPresetElement);
}
