import {vehicleTilesTop} from './vehicle-tiles-top';
import {vehicleTilesBottom} from './vehicle-tiles-bottom';
import {vehicleTilesDealerContact} from './vehicle-tiles-dealer-contact';
import {vehicleTilesDealerLabel} from './vehicle-tiles-dealer-label';
import {vehicleExtIntColors} from './vehicle-ext-int-color';
import {vehicleTilesAvailableFrom} from './vehicle-tile-available-from';

const exports = `
	${vehicleTilesTop}
			<div class="sc-details-list">
				${vehicleTilesDealerLabel}
				${vehicleTilesDealerContact}

				{{? vehicle && isUsedCar && vehicle.used}}
					{{? vehicle.fuelTypes}}
						<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-fuel-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/engine-data/fuel-small.svg#fuel-small"}}></use></svg> {{=vehicle.fuelTypes}}</p>
					{{?}}
					{{? vehicle.gearType && vehicle.gearType.code }}
						{{? vehicle.gearType.code == "gear-type.automatic" }}
							<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-transmission-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/engine-data/transmission-small.svg#transmission-small"}}></use></svg> {{=window.i18n['sc.result.gearType.automatic']||'Automatic'}}</p>
						{{??}}
							<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-transmission-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/engine-data/transmission-small.svg#transmission-small"}}></use></svg> {{=window.i18n['sc.result.gearType.manual']||'Manual'}}</p>
						{{?}}
					{{?}}
					{{? vehicle.used.initialRegistration && vehicle.used.initialRegistration != "" }}
						<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-calendar-events-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/calendar-events-small.svg#nm-icon-calendar-events-small"}}></use></svg> {{=vehicle.used.initialRegistration}}</p>
					{{?}}
					{{? vehicle.used.mileageFormatted }}
						<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-logbook-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/logbook-small.svg#nm-icon-logbook-small"}}></use></svg>${vehicleTilesAvailableFrom} {{=vehicle.used.mileageFormatted}} {{? vehicle.used.mileageUnit && vehicle.used.mileageUnit != ""}}{{=vehicle.used.mileageUnit}}{{?}}
						<a href='#fn_miles' class='audi-j-footnote-reference' target='_self'><sup class='audi-footnote-anchor__text'></sup></a>
						</p>
					{{?}}
				{{??}}
					{{? vehicle && vehicle.powerDisplay && !!vehicle.powerDisplay }}
						<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-power-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/engine-data/power-small.svg#power-small"}}></use></svg>{{=window['stockcars-utils-bundle'].TECHDATA_UTILS.checkForEnginePowerBoostFootnote({vehicle, techDataKey:"powerDisplay", techDataValue:vehicle.powerDisplay})}}</p>
					{{?}}
					{{? vehicle && vehicle.fuelType && vehicle.fuelType.description && vehicle.fuelType.description != "" }}
						<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-fuel-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/engine-data/fuel-small.svg#fuel-small"}}></use></svg> {{=vehicle.fuelType.description}}</p>
					{{?}}
					{{? vehicle && vehicle.gearboxDisplay && !!vehicle.gearboxDisplay }}
						<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-transmission-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/engine-data/transmission-small.svg#transmission-small"}}></use></svg> {{=vehicle.gearboxDisplay}}</p>
					{{?}}
				{{?}}
				${vehicleExtIntColors}
				{{? vehicle && vehicle.vin && !!vehicle.vin }}
					<p class="sc-car-tile-info sc-car-tile-info-vin audi-copy-s"><span class="sc-car-tile-info-label">{{=window.i18n['sc.result.vin']||'VIN'}}</span> {{=vehicle.vin}}</p>
				{{?}}
			</div>

			{{? vehicle}}
				{{? vehicle.tileTabs}}
					<vehicle-tab-element class="sc-vehicle-tab-element" data-tile-tabs-options='{{=vehicle.tileTabs}}' data-vehicle-id="{{=vehicle.id}}" data-vehicle-url="{{=vehicle.url}}" data-consumption-options='{"consumption":true, "emission":true, "efficiency":true, "isElectrical":{{=vehicle.isElectrical}}}'></vehicle-tab-element>
				{{?}}
			{{?}}
	${vehicleTilesBottom}
`;

export {exports as vehicleTilesTemplateUCOPT1};
