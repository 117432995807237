/**
 * FilterBase Class implementing basic observer pattern
 */
export default class ScsModelBase {

	constructor(name = 'ScsModelBase: this model should only be inherited from') {
		this.registeredObservers = [];
		this.subscribe = this.addObserver;
		this.unsubscribe = this.removeObserver;
		this.triggerUpdate = this.notifyObservers;
		this._name = name;
	}

	get name() {
		return this._name;
	}

	addObserver(observer_) {
		if (this.registeredObservers.indexOf(observer_) !== -1) {
			throw new Error('observer already registered');
		}
		if (typeof observer_.update !== 'function') {
			throw new Error('Observer has to implement an update method');
		}
		this.registeredObservers.push(observer_);
	}

	removeObserver(observer_) {
		const index = this.registeredObservers.indexOf(observer_);
		if (index !== -1) {
			this.registeredObservers.splice(index, 1);
		}
	}

	notifyObservers(payload_) {
		const messageData = {'name': this.name};
		if (payload_) {
			messageData.payload = payload_;
		}
		this.registeredObservers.forEach(observer => {
			observer.update(messageData);
		});
	}
}
