/*
 * SearchAgentError - Custom Error Class
 */
class SearchAgentError extends Error {
	constructor(message, type) {
		super(message);
		this.name = 'SearchAgentError';
		this.type = type;
	}
}

export default SearchAgentError;

