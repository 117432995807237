import {vehicleTilesTop} from './vehicle-tiles-top';
import {vehicleTilesBottom} from './vehicle-tiles-bottom';
import {vehicleTilesDealerContact} from './vehicle-tiles-dealer-contact';
import {vehicleExtIntColors} from './vehicle-ext-int-color';

const exports = `
	${vehicleTilesTop}
			<div class="sc-details-list">
				{{? vehicle.nationWideSelling}}
					<p class="sc-car-tile-dealer audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-poi-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/poi-small.svg#nm-icon-poi-small"}}></use></svg> {{=window.i18n['sc.result.dealer.label']||''}}</p>
				{{??}}
					{{? vehicle && vehicle.dealer && !!vehicle.dealer.name }}
						{{? vehicle.employeeVehicle}}
							<p class="sc-car-tile-dealer audi-copy-s">
								<svg class="sc-car-tile-info-symbol nm-icon-poi-small">
									<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/poi-small.svg#nm-icon-poi-small"}}></use>
								</svg>
								<span>{{=window.i18n['sc.messages.dealer.employeeLabel']}}</span>
							</p>
						{{??}}
							<p class="sc-car-tile-dealer audi-copy-s">
								<svg class="sc-car-tile-info-symbol nm-icon-poi-small">
									<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/poi-small.svg#nm-icon-poi-small"}}></use>
								</svg>
								{{var unitTextKey = window.i18n['sc.range-unit.label.' + SETUPS.get('stockcar.mileage.unit')];}}
								{{? vehicle.distance}}{{=vehicle.distance}} {{=unitTextKey}} - {{?}} {{=vehicle.dealer.name}}
							</p>
						{{?}}
					{{?}}
				{{?}}
				${vehicleTilesDealerContact}
				{{? vehicle && vehicle.powerDisplay && !!vehicle.powerDisplay }}
					<p class="sc-car-tile-info audi-copy-s"><svg class="sc-car-tile-info-symbol nm-icon-power-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/engine-data/power-small.svg#power-small"}}></use></svg> {{=window['stockcars-utils-bundle'].TECHDATA_UTILS.checkForEnginePowerBoostFootnote({vehicle,techDataKey:"powerDisplay", techDataValue:vehicle.powerDisplay})}}</p>
				{{?}}
				${vehicleExtIntColors}
			</div>
			{{? vehicle}}
				<vehicle-tab-element class="sc-vehicle-tab-element" data-tile-tabs-options='{{=vehicle.tileTabs}}' data-vehicle-id="{{=vehicle.id}}" data-vehicle-url="{{=vehicle.url}}" data-consumption-options='{"consumption":true, "emission":true, "efficiency":true, "isElectrical":{{=vehicle.isElectrical}}}'></vehicle-tab-element>
			{{?}}
	${vehicleTilesBottom}
`;

export {exports as vehicleTilesTemplate};
