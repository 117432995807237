class LocationConfigModelClass {
	constructor() {
		this._locationConfiguration = {};
		this._mapsApiLoaded = false;
	}

	/**
	 * setter for configuration property
	 * @param {object} configObject configuration
	 * @returns {void} void
	 */
	set configuration(configObject){
		this._locationConfiguration = configObject;
	}

	/**
	 * getter for configuration property
	 * @returns {object} returns configuration object
	 */
	get configuration() {
		return this._locationConfiguration;
	}

	/**
	 * getter for mapsApiLoaded property
	 * @returns {boolean} mapsApiLoaded property
	 */
	get mapsApiLoaded() {
		return this._mapsApiLoaded;
	}

	/**
	 * setter for mapsApiLoaded property
	 * @param {boolean} loaded loaded
	 * @returns {void} void
	 */
	set mapsApiLoaded(loaded) {
		this._mapsApiLoaded = loaded;
	}
}

/**
 * LocationConfigModelClass instance
 * @type {LocationConfigModelClass}
 */
const locationConfigurationModel = new LocationConfigModelClass();

export {LocationConfigModelClass, locationConfigurationModel};
