class SearchAgentAuthController {
	/**
	 * get authentication state
	 * @returns {Promise<{}>} returns authentication state
	 */
	async getAuthenticationState() {
		let timeout;
		return new Promise((resolve, reject) => {
			const cbFun = async (state) => {
				clearTimeout(timeout);
				window.microkernel.stateRegistry.unsubscribeFromStore('dbadAuthServiceStore', cbFun);
				const resolveState = await state;
				if (!!resolveState.authToken){
					resolveState.isAuthenticated = true;
				}
				resolve(resolveState);
			};

			timeout = setTimeout(() => {
				clearTimeout(timeout);
				window.microkernel.stateRegistry.unsubscribeFromStore('dbadAuthServiceStore', cbFun);
				reject('auth timed out');
			}, 10000);

			window.microkernel.stateRegistry.subscribeToStore('dbadAuthServiceStore', cbFun);
		});
	}

	/**
	 * trigger login action
	 * @param {string} redirectUrl - redirect url
	 * @returns {Promise<{}>} returns authentication state
	 */
	async login(redirectUrl = '') {
		window.microkernel.stateRegistry.triggerAction('dbadAuthServiceStore', 'loginAction', {
			redirectUrl: redirectUrl
		});
	}

	/**
	 * trigger logout action
	 * @param {string} redirectUrl - redirect url
	 * @returns {Promise<{}>} returns authentication state
	 */
	async logout(redirectUrl = '') {
		window.microkernel.stateRegistry.triggerAction('dbadAuthServiceStore', 'logoutAction', {
			redirectUrl: redirectUrl
		});
	}
}

const searchAgentAuthController = new SearchAgentAuthController();
export {searchAgentAuthController, SearchAgentAuthController};
