const exports = `
{{? item.url && item.text}}
	{{? item.method === 'POST'}}
		{{? !item.layer }}
			<form method="post" action="{{=item.patternUrl}}" target="{{=item.target}}" class="sc-cta-button-form">
				<button type="submit" data-tracking-action="track-contact-click" class="audi-link-s audi-link-s--arrow {{? item.css && item.css !== ''}} {{= item.css}}{{?}}">
					<span class="audi-link-s__text">{{=item.text}}</span>
					<svg class="audi-link-s__icon">
						<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
					</svg>
				</button>
				<input type="hidden" name="inquiry_json_data" value="{{=item.postData}}" />
			</form>
		{{??}}
			<button id="js-sc-contact-button" data-tracking-action="track-contact-click" class="audi-link-s audi-link-s--arrow js-modal-layer-link{{? item.css && item.css !== ''}} {{= item.css}}{{?}}" data-target-type="{{=item.target}}" data-layer-type="IframePostModalLayerElement" data-button-type="{{=item.type}}" data-url="{{=item.url}}" data-pattern-url="{{=item.patternUrl}}">
				<span class="audi-link-s__text">{{=item.text}}</span>
				<svg class="audi-link-s__icon">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
				</svg>
			</button>
		{{?}}
	{{??}}
		<a id="js-sc-contact-button" data-tracking-action="track-contact-click" x-cq-linkchecker="skip" class="audi-link-s audi-link-s--arrow{{? item.css && item.css !== ''}} {{= item.css}}{{?}}" href="{{=item.url}}" data-pattern="{{=item.patternUrl}}" data-button-type="{{=item.type}}" target="{{=item.target}}">
			<span class="audi-link-s__text">{{=item.text}}</span>
			<svg class="audi-link-s__icon">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
			</svg>
		</a>
	{{?}}
{{?}}
`;
export {exports as contactLinkTemplate};
