import {appEvents, modalLayer, ModalLayerContentBase} from "core-application";
import {modalLayerMandatoryAreasSearchTemplate} from '../../../stockcars/presets/mandatory-area-search/modal-layer-mandatory-area-search-template';
import {scViewMandatoryAreaSearch} from '../../../stockcars/presets/mandatory-area-search/view-mandatory-area-search';
import {scModelMandatoryAreaSearch} from '../../../stockcars/presets/mandatory-area-search/model-mandatory-area-search';
import {globalEventEmitter} from '../../../stockcars/event-emitter-proxy';
import {scsController} from '../../../stockcars/filter/scs-controller';
import {dom, jsLoader, polling, template} from "core-utils";
import {FilterDTO} from "../../../stockcars-filter-bundle";
import {STCK_STORE, SELECTORS, ACTIONS} from '@oneaudi/stck-store';
import {FORMAT_UTILS} from "../../../stockcars-utils-bundle";

export default class ModalMandatoryAreaSearchLayerElement extends ModalLayerContentBase {
	constructor(data_ = {}) {
		super();
		this.config = {localeCountry: 'de'};
		this.controlKeyCodes = [13, 38, 40];
		/* 13 Enter Key, 38 Key UP, 40 Key DOWN */
		this.errors = ['default-error', 'change-input', 'not-found', 'no-results'];
		this.globalEventBus = globalEventEmitter;
		this.domEventDelegate = dom.getEventDelegate('body');
		// add observer for MAS Model updates
		scModelMandatoryAreaSearch.addObserver(this);
		this._bindContextToFunctions();
		this.data = data_;
		this.data.backLink = data_.backLink;
		this.data.placeholderLabel = data_.inputType === 'zipcity' ? window.i18n['sc.mas.placelholder.zipcity'] || '' : window.i18n['sc.mas.placelholder.zip'] || '';
		this.data.distances = this._getDistanceData(this.data);
		this._handleOnUpdateView();
		this.unsubscribeCountFromStore = null;
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object <{closeButton: string, confirmButton: string}>} defaults
	 */
	static get defaults() {
		return {
			confirmButton: '#sc-location-finder-submit',
			masEdit: '.sc-md-mandatory-area-search',
			_selectorModule: '.sc-md-mandatory-area-search',
			_selectorAddressInput: '#sc-location-finder-address-input',
			_selectorDistanceInput: '#sc-location-finder-options-distance',
			_selectorDialogueLayer: '.mandatory-area-search-element',
			_selectorSuggestionsContainer: '.sc-location-finder-suggestions',
			_selectorSuggestionsItems: '.sc-location-finder .sc-location-finder-suggestions-item',
			_selectorSuggestionsItemsActive: 'sc-active-suggestions-item',
			_selectorForm: '.sc-location-finder',
			_selectorGeoButton: '.sc-location-finder .sc-location-finder-geo-location',
			_selectorSubmitButton: '#sc-location-finder-submit'
		};
	}

	static get type() {
		return 'ModalMandatoryAreaSearchLayerElement';
	}

	static async getContent(data_) {
		return new ModalMandatoryAreaSearchLayerElement(data_);
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	async connectedCallback() {
		this.unsubscribeCountFromStore = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getTotalCountState, this.upadateCount);
		this.addEvents();
		this._render();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this.removeEvents();
		this.unsubscribeCountFromStore();
		// trigger footnote repaint on layer close
		document.dispatchEvent(new CustomEvent(appEvents.CONTENT_RENDERED));
	}


	/**
	 * _getDistanceData
	 * @private
	 * @param {object} dataSet dataset values
	 * @returns {array} distancesArray - array of distance values
	 */
	_getDistanceData(dataSet = {}) {
		const distancesArray = [];
		let keys = Object.keys(dataSet);
		keys = keys.filter(key => key.match(/distance-/));
		keys.forEach(key => {
			let distance = JSON.parse(dataSet[key]);
			distancesArray.push(distance);
		});
		return distancesArray;
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._render = this._render.bind(this);
		this._closeLayer = this._closeLayer.bind(this);
		this.handleAutosuggestionListClick = this.handleAutosuggestionListClick.bind(this);
		this.handleGeoClick = this.handleGeoClick.bind(this);
		this.handleChangeDistance = this.handleChangeDistance.bind(this);
		this.handleControlKeyUp = this.handleControlKeyUp.bind(this);
		this.handleEscKeyDown = this.handleEscKeyDown.bind(this);
		this.handleInputKeyUp = dom.debounce(this.handleInputKeyUp, 250).bind(this);
		this.handleInputReset = this.handleInputReset.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	async addEvents() {
		if (await this.masLayerAlive()) {
			this.confirmButton.addEventListener('click', this._closeLayer.bind(this));

			this.domEventDelegate.on('click', ModalMandatoryAreaSearchLayerElement.defaults._selectorSuggestionsItems, this.handleAutosuggestionListClick);
			this.domEventDelegate.on('click', ModalMandatoryAreaSearchLayerElement.defaults._selectorGeoButton, this.handleGeoClick);
			this.domEventDelegate.on('change', ModalMandatoryAreaSearchLayerElement.defaults._selectorDistanceInput, this.handleChangeDistance);
			this.domEventDelegate.on('keydown', ModalMandatoryAreaSearchLayerElement.defaults._selectorAddressInput, this.handleControlKeyUp);
			this.domEventDelegate.on('keydown', 'body', this.handleEscKeyDown);
			this.domEventDelegate.on('keyup', ModalMandatoryAreaSearchLayerElement.defaults._selectorAddressInput, this.handleInputKeyUp);
			this.domEventDelegate.on('reset', ModalMandatoryAreaSearchLayerElement.defaults._selectorForm, this.handleInputReset);
			this.domEventDelegate.on('submit', ModalMandatoryAreaSearchLayerElement.defaults._selectorForm, this.handleFormSubmit);
		}
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.confirmButton.removeEventListener('click', this._closeLayer);
		this.domEventDelegate.off('click', ModalMandatoryAreaSearchLayerElement.defaults._selectorSuggestionsItems, this.handleAutosuggestionListClick);
		this.domEventDelegate.off('click', ModalMandatoryAreaSearchLayerElement.defaults._selectorGeoButton, this.handleGeoClick);
		this.domEventDelegate.off('change', ModalMandatoryAreaSearchLayerElement.defaults._selectorDistanceInput, this.handleChangeDistance);
		this.domEventDelegate.off('keydown', ModalMandatoryAreaSearchLayerElement.defaults._selectorAddressInput, this.handleControlKeyUp);
		this.domEventDelegate.off('keydown', 'body', this.handleEscKeyDown);
		this.domEventDelegate.off('keyup', ModalMandatoryAreaSearchLayerElement.defaults._selectorAddressInput, this.handleInputKeyUp);
		this.domEventDelegate.off('reset', ModalMandatoryAreaSearchLayerElement.defaults._selectorForm, this.handleInputReset);
		this.domEventDelegate.off('submit', ModalMandatoryAreaSearchLayerElement.defaults._selectorForm, this.handleFormSubmit);
	}

	/** *********************************
	 *            MAS MAPS API            *
	 ********************************** */
	/**
	 * Maps API Loaded
	 * check if google and google.maps object is there
	 * @returns {boolean} return true/false
	 */
	mapsApiLoaded() {
		return (this.mapApiLoading ? true : (typeof google === 'object' && typeof google.maps === 'object'));
	}

	/**
	 * async loading of the google map API
	 * @param {DOM_Element} module_ - module HTML Element
	 * @returns {void} nothing
	 */
	loadMapApi(module_) {
		if (!!module_.getAttribute('data-url')) {
			this.mapApiLoading = true;
			const url = module_.getAttribute('data-url');
			return jsLoader.loadURL(url);
		}
	}

	/**
	 * loadApiScript on page ready if map conatiner is available
	 * @return {void} returns nothing
	 */
	loadApiScript() {
		const _selectorModule = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorModule);
		if (dom.isElement(_selectorModule) && !this.mapsApiLoaded()) {
			if (!this.mapApiLoaded) {
				this.loadMapApi(_selectorModule).then(() => {
					this.mapApiLoaded = true;
					this.mapApiLoading = false;
				});
			}
		}
	}

	/** *********************************
	 *            MAS HANDLER        *
	 ********************************** */
	/**
	 * handle on update view
	 * @method handleOnUpdateView
	 * @returns {void} return nothing
	 */
	async _handleOnUpdateView() {
		if (this.moduleAvailable()) {
			this.loadApiScript();
			this.setCountryConfig();
			await this.masLayerAlive();
			scViewMandatoryAreaSearch.fillDialogueLayer();
			scViewMandatoryAreaSearch.setDialogueLayerFilterCount(STCK_STORE.state.totalCount);
		}
	}

	/**
	 * handle autosuggestion list click
	 *
	 * @method handleAutosuggestionListClick
	 * @param {HTML_Element|Event} element_or_Event - DOM Element or Event
	 * @return {void} returns nothing
	 */
	async handleAutosuggestionListClick(element_or_Event) {
		// get placeId from DOM Element in Suggestion List Items
		const placeId = dom.isElement(element_or_Event) ? element_or_Event.getAttribute('data-place-id') : element_or_Event.target.getAttribute('data-place-id');
		const addressInput = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorAddressInput);
		const distanceUnit = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorModule).getAttribute('data-distance-unit');
		const distanceValue = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorDistanceInput).value;

		scViewMandatoryAreaSearch.hideError();
		await this.initializeGeocoderService();
		const places = await this.getPlaceDetail(placeId).catch(error => console.error(error));
		const {formattedAddress, locationFilter} = this.getLocationFilter(places, distanceValue, distanceUnit, placeId);
		addressInput.value = formattedAddress;
		scModelMandatoryAreaSearch.createOrUpdateLocation(locationFilter);
		this.setMasFilter({changedFiltersArray: [locationFilter.filter]});
		scViewMandatoryAreaSearch.closeAutosuggestList();
		scViewMandatoryAreaSearch.handleContinueState();
		scViewMandatoryAreaSearch.closeStatus = true;
	}

	/**
	 * handle geo location click
	 *
	 * @method handleGeoClick
	 * @return {void} returns nothing
	 */
	handleGeoClick() {
		let distanceUnit = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorModule).getAttribute('data-distance-unit'),
			distanceValue = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorDistanceInput).value,
			addressInput = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorAddressInput),
			lat, lng, latlng;
		/* reset inputs */
		scViewMandatoryAreaSearch.hideError();
		/* check if geolocation is available */
		if (!!navigator.geolocation) {
			this.initializeGeocoderService();
			navigator.geolocation.getCurrentPosition((position) => {
				// get Geolocation from position object
				lat = position.coords.latitude;
				lng = position.coords.longitude;
				latlng = {lat: parseFloat(lat), lng: parseFloat(lng)};
				/* google maps geocode service */
				this.geocoderService.geocode({'location': latlng}, (geoPlaces, status) => {
					if (status === 'OK' && geoPlaces.length) {
						const {locationFilter = {}} = this.getLocationFilter(geoPlaces, distanceValue, distanceUnit, geoPlaces[0].place_id);
						addressInput.value = (locationFilter.locationIndicator && locationFilter.locationIndicator.addressValue) ? locationFilter.locationIndicator.addressValue : '';

						scModelMandatoryAreaSearch.createOrUpdateLocation(locationFilter);
						this.setMasFilter({changedFiltersArray: [locationFilter.filter]});
						scViewMandatoryAreaSearch.closeAutosuggestList();
					}
					else {
						scViewMandatoryAreaSearch.showError(this.errors[2]);
					}
				});
			}, (err) => {
				console.warn(err);
				scViewMandatoryAreaSearch.showError(this.errors[2]);
			});
		}
	}

	/**
	 * handle change distance
	 *
	 * @method handleChangeDistance
	 * @return {void} returns nothing
	 */
	handleChangeDistance() {
		const addressInput = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorAddressInput);
		const distanceInput = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorDistanceInput);

		scViewMandatoryAreaSearch.hideError();
		if (dom.isElement(addressInput) && addressInput.value !== '' && dom.isElement(distanceInput)) {
			const {filter, locationIndicator} = scModelMandatoryAreaSearch.presetLocation;
			locationIndicator.addressValue = this.getGeneratedAddressValue(locationIndicator.formattedAddress, distanceInput.value, filter.$values[3]);
			locationIndicator.distanceValue = distanceInput.value;
			filter.$values[2] = distanceInput.value;
			const locationFilter = {
				filter: FilterDTO.castObjectToFilterDTO(filter),
				locationIndicator
			};
			scModelMandatoryAreaSearch.createOrUpdateLocation(locationFilter);
			this.setMasFilter({changedFiltersArray: [locationFilter.filter]});
		}
		else {
			scViewMandatoryAreaSearch.showError(this.errors[0]);
		}
	}

	/**
	 * [handleEscKeyDown description]
	 * @param {Event} event_ key event
	 * @return {void}  nothing
	 */
	async handleEscKeyDown(event_) {
		await this.masLayerAlive();
		const location = scModelMandatoryAreaSearch.presetLocation;
		/* ESC Key with already setted location in localStorage */
		if (location && location.locationIndicator && location.locationIndicator.addressValue && location.locationIndicator.addressValue !== '' && event_.keyCode === 27) {
			this._closeLayer();
		}
	}

	/**
	 * handle control key up (Enter key, Arrow Up key, Arrow Down key)
	 *
	 * @method handleControlKeyUp
	 * @param {Event} event_ - key event
	 * @return {void} returns nothing
	 */
	handleControlKeyUp(event_) { // eslint-disable-line max-statements
		var suggestionsContainer = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorSuggestionsContainer),
			addressInput = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorAddressInput),
			submitButton = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorSubmitButton),
			suggestionsItems,
			index;

		// Enter Key with open Suggestion List
		if (dom.isElement(suggestionsContainer) && suggestionsContainer.childElementCount > 0 && event_.keyCode === 13) {
			scViewMandatoryAreaSearch.hideError();
			scViewMandatoryAreaSearch.closeStatus = false;
			suggestionsItems = dom.getElementsArray(ModalMandatoryAreaSearchLayerElement.defaults._selectorSuggestionsItems);
			index = scViewMandatoryAreaSearch.getSuggestedItemActiveCount(suggestionsItems);
			/* if no suggest item selected by user, select first item */
			index = !index ? 0 : index;
			this.handleAutosuggestionListClick(suggestionsItems[index]);
			console.log("Enter Key with open Suggestions");
		}
		/* Enter Key with valid input value */
		if (dom.isElement(addressInput) && addressInput.value.length && dom.isElement(submitButton) && submitButton.getAttribute('data-state') === 'active' && event_.keyCode === 13) {
			scViewMandatoryAreaSearch.hideError();
			scViewMandatoryAreaSearch.closeStatus = true;
			// close layer by enter if input value is valid
			this.handleContinueClick();
			console.log("Enter Key with valid input value");
		}
		/* key UP and key DOWN only on suggestions dropdown allowed */
		if (dom.isElement(suggestionsContainer) && suggestionsContainer.childElementCount > 0 && (event_.keyCode === 38 || event_.keyCode === 40)) {
			scViewMandatoryAreaSearch.hideError();
			scViewMandatoryAreaSearch.closeStatus = false;
			suggestionsItems = dom.getElementsArray(ModalMandatoryAreaSearchLayerElement.defaults._selectorSuggestionsItems);
			index = scViewMandatoryAreaSearch.getSuggestedItemActiveCount(suggestionsItems);
			console.log("Key Up or Down, only with Suggestions");
			if (event_.keyCode === 38) {
				this.handleArrowUpKey(suggestionsItems, index);
			}
			else {
				this.handleArrowDownKey(suggestionsItems, index);
			}
		}
	}

	/**
	 * handle arrow up key
	 *
	 * @method handleArrowUpKey
	 * @param {Array} items_ - array of DOM elements
	 * @param {Number} index_ - numeric index value
	 * @return {void} returns nothing
	 */
	handleArrowUpKey(items_, index_) {
		if (!!index_ || index_ === 0) {
			if (index_ === 0) {
				items_[items_.length - 1].classList.add(ModalMandatoryAreaSearchLayerElement.defaults._selectorSuggestionsItemsActive);
			}
			else if (index_ <= items_.length - 1) {
				items_[index_ - 1].classList.add(ModalMandatoryAreaSearchLayerElement.defaults._selectorSuggestionsItemsActive);
			}
		}
		else if (!index_) {
			items_[items_.length - 1].classList.add(ModalMandatoryAreaSearchLayerElement.defaults._selectorSuggestionsItemsActive);
		}
	}

	/**
	 * handle arrow down key
	 *
	 * @method handleArrowDownKey
	 * @param {Array} items_ - array of DOM elements
	 * @param {Number} index_ - numeric index value
	 * @return {void} returns nothing
	 */
	handleArrowDownKey(items_, index_) {
		if (!!index_ || index_ === 0) {
			if (index_ < items_.length - 1) {
				items_[index_ + 1].classList.add(ModalMandatoryAreaSearchLayerElement.defaults._selectorSuggestionsItemsActive);
			}
			else if (index_ === items_.length - 1) {
				items_[0].classList.add(ModalMandatoryAreaSearchLayerElement.defaults._selectorSuggestionsItemsActive);
			}
		}
		else if (!index_) {
			items_[0].classList.add(ModalMandatoryAreaSearchLayerElement.defaults._selectorSuggestionsItemsActive);
		}
	}

	/**
	 * handle input key up
	 *
	 * @method handleInputKeyUp
	 * @param {Event} event_ - key event
	 * @return {void} returns nothing
	 */
	handleInputKeyUp(event_) {
		var addressInput = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorAddressInput),
			placeTypes = ['(regions)'],
			inputLength = 0,
			addressInputType = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorModule).getAttribute('data-input-type'),
			// Check whether the search term is a zip code
			// (i.e. if all chars are numbers).
			termIsZipCode = !isNaN(Number(this.validateInput(addressInput.value))),
			autoCompleteCall = !(addressInputType === 'zip' && !termIsZipCode);

		// if data-input-type is zip only zipcodes are allowed
		if (addressInputType === 'zip' || termIsZipCode) {
			inputLength = 3;
		}
		scViewMandatoryAreaSearch.hideError();
		if (addressInput.value.length > 0) {
			scViewMandatoryAreaSearch.showResetButton();
		}
		else {
			scViewMandatoryAreaSearch.hideResetButton();
		}
		/* only pass if keyCode is NOT one of this.controlKeyCodes; */
		if (this.controlKeyCodes.indexOf(event_.keyCode) === -1) {
			if (this.mapsApiLoaded() && !!addressInput && !!addressInput.value && addressInput.value.length > inputLength && autoCompleteCall) {
				this.initializeAutocompleteService();
				/* autoSuggest call */
				this.autoCompleteService.getPlacePredictions({
					input: addressInput.value,
					componentRestrictions: {
						country: this.config.localeCountry
					},
					types: placeTypes
				}, (suggestions, status) => {
					if (status === 'OK') {
						scViewMandatoryAreaSearch.renderSuggestions(suggestions);
					}
					else if (status === 'ZERO_RESULTS') {
						scViewMandatoryAreaSearch.closeAutosuggestList();
						scViewMandatoryAreaSearch.showError(this.errors[3]);
					}
					else {
						scViewMandatoryAreaSearch.closeAutosuggestList();
						scViewMandatoryAreaSearch.showError(this.errors[0]);
					}
				});
			}
			else {
				if (inputLength <= 3) {
					scViewMandatoryAreaSearch.showError(this.errors[1]);
				}
				scViewMandatoryAreaSearch.closeAutosuggestList();

			}
		}
	}

	/**
	 * handle input reset
	 *
	 * @method handleInputReset
	 * @return {void} returns nothing
	 */
	handleInputReset() {
		var addressInput = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorAddressInput);
		addressInput.value = '';
		scViewMandatoryAreaSearch.closeAutosuggestList();
		scViewMandatoryAreaSearch.handleContinueState();
		scViewMandatoryAreaSearch.hideError();
		scViewMandatoryAreaSearch.hideResetButton();
		scModelMandatoryAreaSearch.removeLocation();
		const clearedMasFilter = new FilterDTO({id: 'geo', active: false, preset: false, values: null});
		this.setMasFilter(clearedMasFilter);

	}

	setMasFilter(filtersObject) {
		this._setDistanceSorting();
		scsController.updateFilters(filtersObject);
	}

	_setDistanceSorting() {
		const distanceSorting = SETUPS.get('stockcar.scs.distance.sort.param');
		if (!!distanceSorting) {
			STCK_STORE.dispatch(ACTIONS.SORTING.setSorting({sorting: {results: distanceSorting}}));
		}
	}

	/**
	 * handle continue click
	 *
	 * @method handleContinueClick
	 * @param {Event} event_ - click event
	 * @return {void} returns nothing
	 */
	handleContinueClick(event_) {
		if (event_ && typeof event_.preventDefault === 'function') {
			event_.preventDefault();
		}
		scViewMandatoryAreaSearch.closeStatus = true;
		this._closeLayer();
		scViewMandatoryAreaSearch.hideError();
	}

	/**
	 * handle continue state
	 *
	 * @method handleFormSubmit
	 * @param {Event} event_ - submit event
	 * @return {void} returns nothing
	 */
	handleFormSubmit(event_) {
		if (event_) {
			event_.preventDefault();
		}
	}

	/** *********************************
	 *            MAS Helper            *
	 ********************************** */
	/**
	 * private mas layer alive method
	 * @return {void} returns nothing
	 */
	async masLayerAlive() {
		return polling.wait(() => document.querySelector('.mandatory-area-search-element'), 3000);
	}

	/**
	 * initialize google maps autocomplete service
	 *
	 * @method initializeAutocompleteService
	 * @return {void} returns nothing
	 */
	initializeAutocompleteService() {
		/* initialize Google Maps Autocomplete service if neccessary */
		if (!this.autoCompleteService) {
			try {
				this.autoCompleteService = new google.maps.places.AutocompleteService();
			}
			catch (err) {
				scViewMandatoryAreaSearch.showError(this.errors[0]);
			}
		}
	}

	/**
	 * validateInput
	 * @param {string} inputtext_ - text of input
	 * @return {string} returns prepared input text (zip or city)
	 */
	validateInput(inputtext_) {
		var match;
		match = inputtext_.match(/^\d+/, 'g') || [];
		if (match.length) {
			return match[0];
		}
		else {
			return inputtext_;
		}
	}

	/**
	 * set country config
	 * @method setCountryConfig
	 * @return {void} returns nothing
	 */
	setCountryConfig() {
		var module = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorModule);
		if (dom.isElement(module) && module.getAttribute('data-country') !== '') {
			this.config.localeCountry = module.getAttribute('data-country');
		}
	}

	/**
	 * private get generated address value
	 * @param {object} formattedAddress - formatted address object
	 * @param {string} distanceValue - distance value string
	 * @param {string} distanceUnit - distance unit string
	 * @return {void} returns nothing
	 */
	getGeneratedAddressValue(formattedAddress, distanceValue, distanceUnit) {
		return `${formattedAddress}, ${distanceValue} ${ModalMandatoryAreaSearchLayerElement.getTranslatedDistanceRangeUnit(distanceUnit)} ${window.i18n['sc.areasearch.radius']}`;
	}

	/**
	 * private get lat lng from places object
	 * @param {object} placeObject - places object
	 * @return {object} returns lat/lng object
	 */
	getLatLngFromPlaceObject(placeObject) {
		return {
			lat: placeObject.geometry.location.lat(),
			lng: placeObject.geometry.location.lng()
		};
	}

	/**
	 * private get places detail
	 * @param {object} placeId - places id
	 * @return {promise} returns promise
	 */
	async getPlaceDetail(placeId) {
		return new Promise((resolve, reject) => {
			this.geocoderService.geocode({'placeId': placeId}, (results, status) => {
				if (status === 'OK') {
					resolve(results);
				}
				else {
					reject('No placeId' + status + ' found');
				}
			});
		});
	}

	/**
	 * get city name from place object
	 *
	 * @method getCityNameFromPlaceObject
	 * @param {object} place_ - places object from Google Geocode Service
	 * @return {string} returns city short name
	 */
	getCityNameFromPlaceObject(place_) {
		var places = (place_ && !!place_.address_components) ? place_.address_components : '',
			i;
		if (places) {
			for (i = 0; i < places.length; i++) {
				if (!!places[i].types && !!places[i].types[0] && places[i].types[0] === 'locality') {
					return places[i].short_name;
				}
			}
		}
		return '';
	}

	/**
	 * translate distance unit label
	 * @param {string} distanceUnit_ distance unit (e.g. km, ms)
	 * @returns {String} translated unit for the given range
	 */
	static getTranslatedDistanceRangeUnit(distanceUnit_ = ' ') {
		const unitLabel = window.i18n['sc.range-unit.label.' + distanceUnit_] || distanceUnit_;
		return unitLabel;
	}

	/**
	 * get location filter
	 * @param {object} places - places object
	 * @param {string} distanceValue - distance value string
	 * @param {string} distanceUnit - distance unit string
	 * @param {string} placeId - place id string
	 * @returns {String} translated unit for the given range
	 */
	getLocationFilter(places, distanceValue, distanceUnit, placeId) {
		const address = (places[0] && places[0].address_components && places[0].address_components[0]) ? places[0].address_components[0].short_name : '';
		const formattedAddress = places[0].formatted_address;
		const latLng = this.getLatLngFromPlaceObject(places[0]);

		const locationFilter = {
			filter: new FilterDTO({
				active: true,
				preset: true,
				id: 'geo',
				values: [
					latLng.lat,
					latLng.lng,
					distanceValue,
					distanceUnit,
					(address || '').replace(/,/g, '|')
				]
			}),
			locationIndicator: {
				placeId: placeId,
				formattedAddress: formattedAddress,
				distanceValue: distanceValue,
				addressValue: this.getGeneratedAddressValue(formattedAddress, distanceValue, distanceUnit)
			}
		};

		return {formattedAddress, locationFilter};
	}

	/** *********************************
	 *            MAS Methods            *
	 ********************************** */
	/**
	 * render
	 * @returns {void} returns nothing
	 */
	_render() {
		this.innerHTML = template.render(modalLayerMandatoryAreasSearchTemplate, this.data);
		this.confirmButton = this.querySelector(ModalMandatoryAreaSearchLayerElement.defaults.confirmButton);
		const count = STCK_STORE.state.totalCount;
		scViewMandatoryAreaSearch.setDialogueLayerFilterCount(count);
	}

	// handle filter scModelMandatoryAreaSearch updates
	update() {
		this._handleOnUpdateView();
	}

	upadateCount() {
		const count = FORMAT_UTILS.formatCount(SELECTORS.VEHICLES.getTotalCountState(STCK_STORE.state));
		scViewMandatoryAreaSearch.setDialogueLayerFilterCount(count);
	}

	/**
	 * initialize google maps geocoder service
	 *
	 * @method initializeGeocoderService
	 * @return {void} returns nothing
	 */
	async initializeGeocoderService() {
		try {
			this.geocoderService = await new google.maps.Geocoder();
		}
		catch (err) {
			scViewMandatoryAreaSearch.showError(this.errors[0]);
		}
	}

	/**
	 * module available
	 *
	 * @method moduleAvailable
	 * @return {boolean} returns true || false
	 */
	moduleAvailable() {
		var module = dom.getElement(ModalMandatoryAreaSearchLayerElement.defaults._selectorModule);
		if (dom.isElement(module)) {
			return true;
		}
		else {
			return false;
		}
	}

	/**
	 * @method checkPresetLocation
	 * @return {void} nothing
	 */
	checkPresetLocation() {
		var presetLocation = scModelMandatoryAreaSearch.presetLocation;
		if (presetLocation) {
			this._closeLayer();
		}
		else {
			this._openLayer();
		}
	}

	/**
	 * public before open method
	 * @return {void} returns nothing
	 */
	beforeOpen() {
		scViewMandatoryAreaSearch.hideError();
		scViewMandatoryAreaSearch.openDialogueLayer();
	}

	/**
	 * public before close method
	 * @return {void} returns nothing
	 */
	beforeClose() {
		return scViewMandatoryAreaSearch.closeStatus;
	}

	/**
	 * private open layer method
	 * @return {void} returns nothing
	 */
	_openLayer() {
		this._triggerOpen();
	}

	/**
	 * private close layer method
	 * @return {void} returns nothing
	 */
	_closeLayer() {
		if (this.beforeClose()) {
			this._triggerClose();
		}
	}
}

modalLayer.registerLayerType(ModalMandatoryAreaSearchLayerElement);

if (window.customElements.get('modal-mandatory-area-search-layer-element') === undefined) {
	window.customElements.define('modal-mandatory-area-search-layer-element', ModalMandatoryAreaSearchLayerElement);
}
