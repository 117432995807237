import {patternMatching} from 'core-global';
import {vehiclesController} from "../vehicles/vehicles-controller";
import {SELECTORS, STCK_STORE} from '@oneaudi/stck-store';

class PatternSc {
	get _pattern() {
		return [
			'sc_audicode',
			'sc_dealer_id',
			'sc_commission_number',
			'sc_model_code',
			'sc_carline',
			'sc_carline_description',
			'sc_price',
			'sc_market_reference',
			'sc_raw_dealer_id',
			'sc_filters',
			'sc_model_year',
			'sc_list_price',
			'sc_mileage',
			'sc_vehicle_id',
			'sc_car_id',
			'sc_vin',
			'sc_loan_type',
			'sc_initialregistration_year',
			'sc_initialregistration_month'
		];
	}

	/**
	 * @method register
	 * @returns {void} returns nothing
	 */
	_register() {
		this._pattern.forEach((key) => {
			try {
				if (key !== 'sc_filters') {
					patternMatching.register(key, this._getPatternData.bind(this));
				}
				else {
					patternMatching.register(key, this._replaceFilterPattern.bind(this));
				}
			}
			catch (error) {
				console.error(error);
				throw error;
			}
		});
	}

	/**
	 * @method getPatternData
	 * @param {string} pattern_ - pattern key (e.g. sc_audicode)
	 * @param {string} vehicleId - id
	 * @returns {Promise} returns Promise
	 */
	async _getPatternData(pattern_, vehicleId = '') {
		try {
			const patternData = await this._getDataPattern(this._getPatternKey(pattern_), vehicleId);
			return patternData;
		}
		catch (error) {
			console.error(error);
			throw error;
		}
	}

	/**
	 * replaceFilterPattern
	 * @return {Promise} returns Promise
	 */
	_replaceFilterPattern() {
		return new Promise((resolve, reject) => {
			let paramsStringParts = [];
			if (!!SELECTORS.FILTERS.getSelectedFiltersParamString(STCK_STORE.state)) {
				const filterString = SELECTORS.FILTERS.getSelectedFiltersParamString(STCK_STORE.state);
				if (filterString) {
					paramsStringParts.push('filter=' + filterString);
				}
			}
			if (!!SELECTORS.FILTERS.getPresetFiltersParamString(STCK_STORE.state)) {
				const presetString = SELECTORS.FILTERS.getPresetFiltersParamString(STCK_STORE.state);
				if (presetString) {
					paramsStringParts.push('preset=' + presetString);
				}
			}

			if (paramsStringParts.length) {
				resolve(paramsStringParts.join('&'));
			}
			else {
				reject('Empty filter string');
			}
		});
	}

	/**
	 * @method getPatternKey
	 * @param {string} pattern_ - pattern key
	 * @returns {object} returns pattern
	 * @example
	 * //Correct JSON key needs to be created:
	 * key for 'sc_audicode' is 'audiCode' (key in SCS Data response)
	 */
	_getPatternKey(pattern_) { // eslint-disable-line complexity
		const pattern = {};

		switch (pattern_) {
			case 'sc_audicode':
				pattern.key = 'audiCode';
				break;
			case 'sc_dealer_id':
				pattern.key = 'dealer.id';
				break;
			case 'sc_raw_dealer_id':
				pattern.key = 'rawDealerId';
				break;
			case 'sc_commission_number':
				pattern.key = 'commissionNumber';
				break;
			case 'sc_model_code':
				pattern.key = 'modelCode.code';
				break;
			case 'sc_carline':
				pattern.key = 'symbolicCarline.code';
				break;
			case 'sc_carline_description':
				pattern.key = 'symbolicCarlineDescription';
				break;
			case 'sc_price':
				pattern.key = 'typedPrices';
				pattern.additionalKey = 'retail';
				break;
			case 'sc_market_reference':
				pattern.key = 'marketReference';
				break;
			case 'sc_model_year':
				pattern.key = 'modelYear';
				break;
			case 'sc_list_price':
				pattern.key = 'typedPrices';
				pattern.additionalKey = 'regular';
				break;
			case 'sc_mileage':
				pattern.key = 'used.mileageFormatted';
				break;
			case 'sc_vehicle_id':
				pattern.key = 'id';
				break;
			case 'sc_car_id':
				pattern.key = 'carId';
				break;
			case 'sc_vin':
				pattern.key = 'vin';
				break;
			case 'sc_loan_type':
				pattern.key = 'financeLoan.info';
				break;
			case 'sc_initialregistration_year':
				pattern.key = 'used.initialRegistrationDate';
				pattern.format = 'year';
				break;
			case 'sc_initialregistration_month':
				pattern.key = 'used.initialRegistrationDate';
				pattern.format = 'month';
				break;
			default:
				pattern.key = '';
		}
		return pattern;
	}

	_getVehicleId() {
		if (!!SELECTORS.PATTERN.getPatternId(STCK_STORE.state)) {
			return SELECTORS.PATTERN.getPatternId(STCK_STORE.state);
		}
		return this._getVehicleIdFromUrl(location.href);
	}

	/**
	 * getVehicleIdFromUrl method
	 * @param {string} url_ - url
	 * @returns {string} vehicle ID
	 */
	_getVehicleIdFromUrl(url_) {
		let regex = /(sc_detail.*?\.)(([^.\s])+)/gi,
			matchesArr,
			vehicleId,
			vehicleIds = [];
		// loop all matches and push id into vehicleIds array
		while ((matchesArr = regex.exec(url_))) {
			vehicleIds.push(matchesArr[2]);
		}
		// return only the last id - needed for detail page in layer on top of detail page
		vehicleId = vehicleIds[vehicleIds.length - 1] || '';
		// if url has param vehicleid, use this vehicleid instead of sc_detail vehicleid
		let match = url_.match(/(vehicleid=)(\D|\w)+&*/gi);
		if (!!match && match.length) {
			match = (match[0] || '').replace(/vehicleid=/, '');
			match = match.split('&').shift();
			vehicleId = match;
		}
		return vehicleId;
	}

	/**
	 * getPatternValue
	 * @param {array} pattern pattern
	 * @param {array} patternKeys patternKeys
	 * @param {object} vehicleData vehicleData from scs
	 * @returns {string} patternValue patternValue
	 */
	_getValueForPattern(pattern, patternKeys, vehicleData) {
		let patternData = vehicleData;
		patternKeys.forEach(key => {
			patternData = patternData[key];
			if (Array.isArray(patternData)) {
				if (
					key.indexOf('typedPrices') === 0 &&
					pattern &&
					pattern.additionalKey
				) {
					patternData.forEach(price => {
						if (price.type === pattern.additionalKey) {
							patternData = price.amount;
						}
					});
				}
			}
			else if (key === 'mileageFormatted') {
				patternData += ' ' + vehicleData.used.mileageUnit;
			}
			else if (key === 'symbolicCarlineDescription') {
				patternData = encodeURIComponent(vehicleData.symbolicCarline.description);
			}
			else if (key === 'initialRegistrationDate' && pattern.format === 'year') {
				let year = String(new Date(vehicleData.used.initialRegistrationDate).getFullYear());
				patternData = year;
			}
			else if (key === 'initialRegistrationDate' && pattern.format === 'month') {
				let month = String(new Date(vehicleData.used.initialRegistrationDate).getMonth() + 1);
				patternData = month;
			}
		});

		return patternData;
	}

	/**
	 * getDataPattern
	 * @param {string} pattern pattern
	 * @param {string} vehicleId_ id
	 * @returns {Promise<string>} returns pattern
	 */
	_getDataPattern(pattern, vehicleId_) {
		let vehicleId = !!vehicleId_ ? vehicleId_ : this._getVehicleId();
		const patternKeys = pattern && pattern.key ? pattern.key.split('.') : '';

		return new Promise(async (resolve, reject) => {
			if (patternKeys && !!vehicleId) {
				try {
					const vehicleData = await vehiclesController.loadVehicleById(vehicleId);
					const patternData = this._getValueForPattern(pattern, patternKeys, vehicleData);
					if (!!patternData && !Array.isArray(patternData)) {
						resolve(patternData);
					}
					else {
						reject(new TypeError('load ' + pattern.key + ' failed'));
					}
				}
				catch (error) {
					console.error(error);
					reject(new TypeError('load ' + pattern.key + ' failed'));
				}
			}
		});
	}
}

const patternSc = new PatternSc();
patternSc._register();

export {PatternSc, patternSc};

