import {scModelMandatoryAreaSearch} from "./model-mandatory-area-search";
import {globalEventEmitter} from '../../event-emitter-proxy';
import {CoreEvents} from '@audi/audi-core-events';

export default class MasLocationIndicatorElement extends HTMLElement {
	constructor() {
		super();
		scModelMandatoryAreaSearch.addObserver(this);
	}

	static get defaults() {
		return {
			_selectorLocationIndicator: '.sc-location-indicator',
			_selectorLocationIndicatorInvisible: 'sc-location-indicator-invisible',
			_selectorLocationIndicatorEdit: '.sc-location-indicator .sc-location-indicator-edit'
		};
	}

	connectedCallback() {
		this.data = Object.assign({}, this.dataset);
		const location = scModelMandatoryAreaSearch.presetLocation;
		if (!location && location === null || !Object.keys(location).length) {
			this.triggerMandatoryAreaLayer();
		}
		else {
			this.renderLocationIndicator(location);
		}
	}

	update() {
		const location = scModelMandatoryAreaSearch.presetLocation;
		this.renderLocationIndicator(location);
	}

	/**
	 * render location indicator
	 * @method renderLocationIndicator
	 * @param {FilterDTO} locationFilter location preset filter
	 * @return {void} returns nothing
	 */
	renderLocationIndicator(locationFilter = {}) {
		const locationIndicatorElement = this.querySelector(MasLocationIndicatorElement.defaults._selectorLocationIndicator);
		const locationIndicatorEdit = this.querySelector(MasLocationIndicatorElement.defaults._selectorLocationIndicatorEdit);
		if (locationFilter.locationIndicator && locationFilter.locationIndicator.addressValue) {
			locationIndicatorElement.classList.remove(MasLocationIndicatorElement.defaults._selectorLocationIndicatorInvisible);
			locationIndicatorEdit.innerHTML = locationFilter.locationIndicator.addressValue;
		}
		else {
			locationIndicatorElement.classList.add(MasLocationIndicatorElement.defaults._selectorLocationIndicatorInvisible);
		}
	}

	triggerMandatoryAreaLayer() {
		console.warn("no current MAS found=> trigger MAS Layer");
		const payload = Object.assign(this.data, {
			layerType: 'ModalMandatoryAreaSearchLayerElement',
			layerAction: 'open'
		});
		globalEventEmitter.emit(CoreEvents.MODAL_LAYER_OPEN_EVENT, payload);
	}
}
if (window.customElements.get('sc-mas-location-indicator-element') === undefined) {
	window.customElements.define('sc-mas-location-indicator-element', MasLocationIndicatorElement);
}


