

const template = `<input class="nm-hidden sc-filter-options-show-more" id="sc-filter-options-show-more" name="" type="checkbox" />
<div class="sc-filter-options-container">
	<ul class="sc-filter-options">
		{{~it.data :item :index}}
		<li class="sc-filter-options-item sc-filter-options-item-filter" data-remove-ids='{{=JSON.stringify(item.remove)}}' data-vehicle-id="{{=item.id}}">
			<button class="">
				<span class="sc-filter-options-item-label audi-copy-s sc-hyphenate">{{=item.label}}</span>
				<span class="sc-filter-options-icon-wrap">
					<svg class="sc-filter-options-icon nm-icon-cancel">
						<use xlink:href= {{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel.svg#nm-icon-cancel"}}></use>
					</svg>
				</span>
			</button>
		</li>
		{{~}}
		{{? it.data.length > 0}}
			<li class="sc-filter-options-item-spacer"></li>
			<li class="sc-filter-options-item sc-filter-options-item-more">
				<label class="sc-filter-options-show-more-label audi-copy-s" for="sc-filter-options-show-more">
					<span class="sc-filter-options-icon-wrap">
						<svg class="sc-filter-options-icon nm-icon-add-small" viewBox="0 0 24 24">
							<use xlink:href= {{=SETUPS.get('coreci.url.assets')+"/icons/svg/add-small.svg#nm-add-small"}}></use>
						</svg>
					</span>
					<span class="sc-filter-options-show"><span class="j-sc-filter-options-amount"></span> {{=window.i18n['sc.filter-options.more.label']||'Weitere anzeigen'}}</span>
				</label>
				<label class="sc-filter-options-show-less-label audi-copy-s" for="sc-filter-options-show-more">
					<span class="sc-filter-options-icon-wrap">
						<svg class="sc-filter-options-icon nm-icon-remove-small" viewBox="0 0 24 24">
							<use xlink:href= {{=SETUPS.get('coreci.url.assets')+"/icons/svg/remove-small.svg#nm-icon-remove-small"}}></use>
						</svg>
					</span>
					<span class="sc-filter-options-hide">{{=window.i18n['sc.filter-options.less.label']||'Weniger anzeigen'}}</span>
				</label>
			</li>
			<li class="sc-filter-options-item sc-filter-options-item-reset">
				<button class="sc-j-filter-options-reset audi-copy-s">
					<span class="sc-filter-options-icon-wrap">
						<svg class="sc-filter-options-icon nm-icon-erase" viewBox="0 0 24 24">
							<use xlink:href= {{=SETUPS.get('coreci.url.assets')+"/icons/svg/erase-small.svg#nm-icon-erase"}}></use>
						</svg>
					</span>
					<span>{{=window.i18n['sc.filter-options.reset.label']||'Alle Filter zurücksetzen'}}</span>
				</button>
			</li>
			{{? SETUPS.get('stck.search-agent')}}
				<li class="sc-filter-options-item sc-filter-options-item-save">
					<button class="sc-j-search-agent-save sc-search-agent-save audi-copy-s" data-target-url="{{=SETUPS.get('stockcar.sca.dashboard.url')}}">
						<span class="sc-filter-options-icon-wrap">
							<svg class="sc-filter-options-icon nm-icon-notepad">
								<use xlink:href= {{=SETUPS.get('coreci.url.assets')+"/icons/svg/notepad-small.svg#nm-icon-notepad"}}></use>
							</svg>
						</span>
						<div class="sc-filter-options-loading-spinner"></div>
						<span class="sc-filter-options-save-label" data-loading-label="{{=window.i18n['sc.filter-options.saving.label']||'Filter-Auswahl wird gespeichert'}}" data-label="{{=window.i18n['sc.filter-options.save.label']||'Filter-Auswahl speichern'}}"></span>
					</button>
				</li>
			{{?}}
		{{?}}
	</ul>
</div>`;
export {template as scFilterOptionsChips};


