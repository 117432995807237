import {StorageAdapter} from '@oneaudi/stck-store';
import ScsModelBase from './../filter/scs-model-base';

class SearchAgentState extends ScsModelBase {
	constructor() {
		super();
		this.searchAgentEntryStorage = new StorageAdapter('sc-search-agent-entry-storage', StorageAdapter.types.SESSION_STORAGE);
		this._renderedState = false;
	}

	/**
	 * searchAgentEntry getter
	 * @returns {Object} storage entry
	 */
	get searchAgentEntry() {
		return this.searchAgentEntryStorage.readEntry();
	}

	/**
	 * clear search agent entry storage
	 * @returns {void} void
	 */
	clearSearchAgentStorage() {
		this.searchAgentEntryStorage.clearStorage();
	}

	/**
	 * searchAgentEntry setter
	 * @param {object} entryObject entryObject to be saved in storage
	 */
	set searchAgentEntry(entryObject) {
		try {
			this.searchAgentEntryStorage.writeEntry(entryObject);
			const {action} = entryObject;
			if (action) {
				this.triggerUpdate({action});
			}
		}
		catch (err) {
			console.warn(err);
		}
	}

	/**
	 * renderedState - setter for rendered state - triggers update
	 * @param {boolean} renderedState renderedState
	 * @returns {void} void
	 */
	set renderedState(renderedState) {
		this._renderedState = renderedState;
		this.triggerUpdate(this.searchAgentEntry);
	}
}

/**
 * searchAgentInstance
 * @type {SearchAgentState}
 */
const searchAgentState = new SearchAgentState();
export {SearchAgentState, searchAgentState};
