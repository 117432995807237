import {router} from 'core-application';

export default class FilterBackButtonElement extends HTMLElement {
	constructor() {
		super();
	}

	static get defaults() {
		return {
			elementName: 'filter-back-button-element',
			buttonClass: '.sc-main-navigation-mobile-filter-button'
		};
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		this._initializeProperties();
		this._setLink();
	}

	_setLink() {
		const previousPage = router.getPreviousPage();
		const previousPageUrl = previousPage && previousPage.url ? previousPage.url : '';
		const pages = (this.dataset.pages || '').split(',');
		this.button.href = pages.find(page => page.includes(previousPageUrl)) || SETUPS.get('stockcar.url.startpage');
	}

	/**
	 * _initializeProperties
	 * @private
	 * @returns {void} void
	 */
	_initializeProperties() {
		this.button = this.querySelector(FilterBackButtonElement.defaults.buttonClass);
	}

}

if (window.customElements.get(FilterBackButtonElement.defaults.elementName) === undefined) {
	window.customElements.define(FilterBackButtonElement.defaults.elementName, FilterBackButtonElement);
}
