import {scsController} from "../../filter/scs-controller";

export default class NoResultsResetButtonElement extends HTMLElement{
	constructor(){
		super();
		this._handleButtonClick = this._handleButtonClick.bind(this);
	}
	static get defaults(){
		return {
			buttonSelector: 'button.sc-reset-filter'
		};
	}
	connectedCallback(){
		this._addEvents();
	}

	disconnectedCallback(){
		this._removeEvents();
	}

	_handleButtonClick(){
		scsController.clearAllFilters();
	}

	_addEvents(){
		const btn = this.querySelector(NoResultsResetButtonElement.defaults.buttonSelector);
		if (btn) {
			btn.addEventListener("click", this._handleButtonClick);
		}
	}


	_removeEvents(){
		const btn = this.querySelector(NoResultsResetButtonElement.defaults.buttonSelector);
		if (btn) {
			btn.removeEventListener("click", this._handleButtonClick);
		}
	}
}

if (window.customElements.get('sc-no-result-reset-element') === undefined) {
	window.customElements.define('sc-no-result-reset-element', NoResultsResetButtonElement);
}

