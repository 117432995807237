const exports = `
  {{? SETUPS.get('scopes.show.int.ext.colors.tiles')}}
  {{? vehicle && vehicle.extColor && vehicle.extColor.description}}
    <p class="sc-car-tile-info audi-copy-s sc-hyphenate"><svg class="sc-car-tile-info-symbol nm-icon-exterieur-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/exterieur-small.svg#nm-icon-exterieur-small"}}></use></svg>&nbsp;<span>{{=vehicle.extColor.description}}</span></p>
  {{?}}
  {{? vehicle && vehicle.upholsteryColor && vehicle.upholsteryColor.description}}
    <p class="sc-car-tile-info audi-copy-s sc-hyphenate"><svg class="sc-car-tile-info-symbol nm-icon-interior-configuration-small"><use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/interior-configuration-small.svg#nm-icon-interior-configuration-small"}}></use></svg>&nbsp;<span>{{=vehicle.upholsteryColor.description}}</span></p>
  {{?}}
  {{?}}`;

export {exports as vehicleExtIntColors};
