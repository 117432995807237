import {template} from 'core-utils';
import {appEvents} from 'core-application';
import {vehicleTabTemplate} from '../tiles/templates/vehicle-tiles-tabs';
import {vehiclesController} from './vehicles-controller';
import {FORMAT_UTILS} from "../../stockcars-utils-bundle";

export default class VehicleTabElement extends HTMLElement {

	constructor(data_ = {}) {
		super();
		this._data = data_;
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	async connectedCallback() {
		await this._getData();
		this._render();
	}

	/**
	 * get data
	 * @return {void} returns nothing
	 * @private
	 */
	async _getData() {
		let vehicleUrl = this.dataset.vehicleUrl;
		let templateConsumptionOptions = JSON.parse(this.dataset.consumptionOptions);
		let tileTabsOptions = {};
		try {
			tileTabsOptions = JSON.parse(this.dataset.tileTabsOptions);
		}
		catch (err) {
			console.error(err);
		}
		let vehicleData = await vehiclesController.loadVehicleById(this.dataset.vehicleId);
		const tileTabs = this._getTabData(vehicleData, tileTabsOptions, templateConsumptionOptions);
		const merged = Object.assign({}, vehicleData, {'tileTabs': tileTabs}, {'vehicleUrl': vehicleUrl});
		this._data = merged;
		this._data.isFaAlphaVersion = SETUPS.get('stockcar.vtp.fa.use.alpha.version');
	}

	/**
	 * get tab data
	 * @param {object} vehicleData_ - vehicle object
	 * @param {object} tileTabsOptions_ - result tile data
	 * @param {object} templateConsumptionOptions_ - template consumption options
	 * @return {object} vehicleData
	 * @private
	 */
	_getTabData(vehicleData_ = {}, tileTabsOptions_ = [], templateConsumptionOptions_ = {}) {
		const isCountryEs = vehicleData_.countryCode === 'ES';
		let tileTabs = {};
		tileTabs.tabList = [];
		tileTabs.environmental = {available: false};
		tileTabs.environmental.items = [];
		tileTabs.warranty = {available: false};
		tileTabs.warranty.items = [];
		tileTabs.advantages = {available: false};
		tileTabs.advantages.items = [];
		tileTabs.consumptionEmission = this._setConsumptionEmissionDefaultObject();
		const tabTypes = ['warranty', 'advantages', 'environmental'];
		if (isCountryEs && vehicleData_.pollutionBadge?.code) {
			tileTabsOptions_.push({type: 'environmental'});
		}
		if (tileTabsOptions_.length > 0) {
			this._getTabElementsData(vehicleData_, tileTabsOptions_, templateConsumptionOptions_, tileTabs);
			tabTypes.forEach((tabType) => {
				if (tileTabs[tabType].items.length > 0) {
					tileTabs[tabType].available = true;
					tileTabs.tabList.forEach(tab => {
						if (tab.type === tabType) {
							tab.available = true;
						}
					});
				}
			});
		}
		tileTabs.useWarrantyFA = Boolean(vehicleData_.warrantyInfo);
		const firstAvailableTabElement = tileTabs.tabList.find(element => element.available === true) || {};
		firstAvailableTabElement.checked = true;
		return tileTabs;
	}

	/**
	 * set consumption and emission default object
	 * @return {{consumption: boolean, emission: boolean, efficiency: boolean, available: boolean}} - returns consumption and emission default object
	 * @private
	 */
	_setConsumptionEmissionDefaultObject() {
		return {
			consumption: false,
			emission: false,
			efficiency: false,
			available: false
		};
	}

	/**
	 * get result tile data
	 * @param {object} vehicleData_ - vehicle data object
	 * @param {object} tileTabsOptions_ - tile data object
	 * @param {object} templateConsumptionOptions_ - consumption emission options from template
	 * @param {object} tileTabs_ tab data object
	 * @return {void} returns nothing
	 * @private
	 */
	_getTabElementsData(vehicleData_, tileTabsOptions_, templateConsumptionOptions_, tileTabs_) {
		tileTabsOptions_.forEach((tab, i) => {
			tileTabs_.tabList.push({'type': tab.type, 'id': i.toString(), 'checked': false, available: false});
			if (tab && tab.type === 'emissions' && tab.tabIcons && tab.tabIcons.length > 0 && (templateConsumptionOptions_.consumption || templateConsumptionOptions_.emission || templateConsumptionOptions_.efficiency)) {
				this._getEmissionTabData(tileTabs_, i, tab, templateConsumptionOptions_);
			}
			else if (tab && tab.type === 'warranty' && tab.tabIcons && tab.tabIcons.length > 0 && vehicleData_.used && vehicleData_.used.warrantyTypes) {
				this._getWarrantyTabData(tileTabs_, i, tab, vehicleData_);
			}
			else if (tab && tab.type === 'advantages' && tab.infoIconLinks && tab.infoIconLinks.length > 0) {
				this._getAdvantagesTabData(tileTabs_, i, tab, vehicleData_);
			}
			else if (tab && tab.type === 'environmental') {
				this._getEnvTabData(tileTabs_, i, tab, vehicleData_);
			}
		});
	}

	/**
	 * get emission tab data
	 * @param {object} tileTabs_ - all tabs
	 * @param {number} i - index of tabs
	 * @param {object} tab - specific tab
	 * @param {object} templateConsumptionOptions_  - consumption emission options from template
	 * @return {void} returns nothing
	 * @private
	 */
	_getEmissionTabData(tileTabs_, i, tab, templateConsumptionOptions_) {
		tileTabs_.tabList[i].available = true;
		tileTabs_.consumptionEmission.tabId = i.toString();
		tileTabs_.consumptionEmission.available = true;
		tileTabs_.consumptionEmission.isElectrical = templateConsumptionOptions_.isElectrical || false;
		tileTabs_.consumptionEmission.order = [];
		tab.tabIcons.forEach(tabItem => {
			tileTabs_.consumptionEmission[tabItem.cssKey.split('-display')[0]] = templateConsumptionOptions_[tabItem.cssKey.split('-display')[0]];
			tileTabs_.consumptionEmission.order.push(tabItem.cssKey.split('-display')[0]);
		});
		tileTabs_.consumptionEmission.displayedItems = Object.keys(templateConsumptionOptions_).map((item) => {
			if (item === 'isElectrical') {
				return 'electrical-range';
			}
			return item;
		});
	}

	/**
	 * get warranty tab data
	 * @param {object} tileTabs_ - all tabs
	 * @param {number} i - index of tabs
	 * @param {object} tab - specific tab
	 * @param {object} vehicleData_  - complete vehicle data
	 * @return {void} returns nothing
	 * @private
	 */
	_getWarrantyTabData(tileTabs_, i, tab, vehicleData_) {
		tileTabs_.warranty.tabId = i.toString();
		tab.tabIcons.forEach(tabItem => {
			let warrantyItem = this._getWarrantyTabItem(tabItem, vehicleData_);
			if (Object.keys(warrantyItem).length > 0) {
				tileTabs_.warranty.items.push(warrantyItem);
			}
		});
	}

	/**
	 * get warranty tab items
	 * @param {object} tabItem_ - tab item object
	 * @param {object} vehicle_ - vehicle object
	 * @return {object} - warrantyTypes
	 * @private
	 */
	_getWarrantyTabItem(tabItem_, vehicle_) {
		let warrantyItem = {};
		if (vehicle_.used.warrantyTypes.filter(type => type.code === tabItem_.scsKey).length) {
			warrantyItem = {
				cssClass: tabItem_.cssClass,
				cssKey: tabItem_.cssKey,
				showInfoIcon: (tabItem_.infoLink && tabItem_.infoLink.linkUrl) ? tabItem_.showInfoIcon : false,
				infoIconUrl: (tabItem_.infoLink && tabItem_.infoLink.linkUrl) ? tabItem_.infoLink.linkUrl : '',
				customIcon: (tabItem_.cssClass.indexOf('custom') >= 0),
				warrantyType: tabItem_.scsKey,
				warrantyFormattedText: FORMAT_UTILS.getTextReplace(tabItem_.scsKey)
			};
		}
		return warrantyItem;
	}

	/**
	 * get emission tab data
	 * @param {object} tileTabs_ - all tabs
	 * @param {number} i - index of tabs
	 * @param {object} tab - specific tab
	 * @param {object} vehicleData_  - complete vehicle data
	 * @return {void} returns nothing
	 * @private
	 */
	_getAdvantagesTabData(tileTabs_, i, tab, vehicleData_) {
		tileTabs_.advantages.tabId = i.toString();
		tileTabs_.advantages.items = (vehicleData_.campaigns && vehicleData_.campaigns.length) ? [...vehicleData_.campaigns].filter(campaign => this._isCampaignActive(campaign)) : [];
		if (tab.infoIconLinks.length) {
			tab.infoIconLinks.forEach((infoIconItem) => {
				const matchingAdvantageIndex = tileTabs_.advantages.items.findIndex((advantage) => advantage.campaignId === infoIconItem.campaignId);
				if (matchingAdvantageIndex >= 0) {
					const url = infoIconItem.layerUrl.includes('?') ? `${infoIconItem.layerUrl}&campaignId=${infoIconItem.campaignId}` : `${infoIconItem.layerUrl}?campaignId=${infoIconItem.campaignId}`;
					let advantageWithUrl = {...tileTabs_.advantages.items[matchingAdvantageIndex], layerUrl: url};
					tileTabs_.advantages.items[matchingAdvantageIndex] = advantageWithUrl;
				}
			});
		}
	}

	/**
		 * get environment tab data
		 * @param {object} tileTabs_ - all tabs
		 * @param {number} i - index of tabs
		 * @param {object} tab - specific tab
		 * @param {object} vehicleData_  - complete vehicle data
		 * @return {void} returns nothing
		 * @private
		 */
	_getEnvTabData(tileTabs_, i, tab, vehicleData_ = {}) { // eslint-disable-line max-params, max-statements
		tileTabs_.environmental.tabId = i;
		const pollutionCode = vehicleData_.pollutionBadge.code;
		const type = pollutionCode.split('.').reverse()[0].toUpperCase();
		const altImageText = `${window.i18n['sc.result.tile.pollution-badge.alt-image']} ${vehicleData_.pollutionBadge.description}`;
		const envItem = {
			imageUrl: `https://fa-vtp-technical-data.cdn.prod.arcade.apps.one.audi/2.4.0/static/img/environmental_labels/es/pollution-badge-type.${type}.png`,
			infoIconText: window.i18n[`sc.result.tile.pollution-badge-type.${type}`],
			altImageText
		};
		tileTabs_.environmental.items.push(envItem);
		return envItem;
	}

	/**
	 * isCampaignActive
	 * @param {object} campaign campaign
	 * @returns {boolean} is active
	 */
	_isCampaignActive(campaign) {
		const {startDate, endDate} = campaign;
		let now = Date.now();
		let status = !!((startDate && endDate) && (Number(startDate) <= now && Number(endDate) >= now));
		return status;
	}

	/**
	 * render
	 * @returns {void} returns nothing
	 */
	_render() {
		this.innerHTML = template.render(vehicleTabTemplate, this._data);
		const contentRendered = new CustomEvent(appEvents.CONTENT_RENDERED, {
			detail: {element: this}
		});
		document.dispatchEvent(contentRendered);
	}
}

if (window.customElements.get('vehicle-tab-element') === undefined) {
	window.customElements.define('vehicle-tab-element', VehicleTabElement);
}
