import {scPage} from '../../stockcars-utils-bundle';
import {appEvents} from 'core-application';
import {dom} from 'core-utils';

export default class ToTopButtonElement extends HTMLElement {
	/**
	 * constructor
	 * @returns {void} void
	 */
	constructor() {
		super();
	}

	/**
	 * getter for defaults
	 * @static
	 * @returns {{hiddenClass: string, elementName: string}} defaults object
	 */
	static get defaults() {
		return {
			elementName: 'to-top-button-element',
			selectorLayer: '.nm-layer-wrapper',
			hiddenClass: 'sc-hidden'
		};
	}

	/**
	 * custom elements connected callback function
	 * @override
	 * @returns {void} void
	 */
	connectedCallback() {
		this._addEvents();
		this._showToTopButton();
	}

	/**
	 * custom elements disconnected callback function
	 * @override
	 * @returns {void} void
	 */
	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	 * addEvents
	 * @private
	 * @returns {void} void
	 */
	_addEvents() {
		this._debounceHandler = dom.debounce(this._showToTopButton, 100).bind(this);
		document.addEventListener(appEvents.CONTENT_RENDERED, this._debounceHandler);
		window.addEventListener('resize', this._debounceHandler);
		this.addEventListener('click', ToTopButtonElement._handleTopButtonClicked.bind(this));
	}

	/**
	 * removeEvents
	 * @private
	 * @returns {void} void
	 */
	_removeEvents() {
		this.removeEventListener('click', ToTopButtonElement._handleTopButtonClicked);
		document.removeEventListener(appEvents.CONTENT_RENDERED, this._debounceHandler);
		window.removeEventListener('resize', this._debounceHandler);
	}

	/**
	 * _showToTopButton - show toTopButton when body height > window height
	 * @private
	 * @returns {void} void
	 */
	_showToTopButton() {
		if (ToTopButtonElement._getBodyHeight() > window.innerHeight) {
			this.classList.remove(ToTopButtonElement.defaults.hiddenClass);
		}
		else {
			this.classList.add(ToTopButtonElement.defaults.hiddenClass);
		}
	}

	/**
	 * _handleTopButtonClicked - gets content and scrolls to top (0)
	 * @static
	 * @param {event} event event
	 * @private
	 * @returns {void} void
	 */
	static _handleTopButtonClicked(event) {
		const context = ToTopButtonElement._getContext(event.target);
		if (context) {
			context.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}

	/**
	 * _getContext
	 * @param {element} element element
	 * @returns {HtmlElement | window} context context
	 * @private
	 */
	static _getContext(element) {
		if (scPage.isElementInOpenedLayerContext(element)) {
			return document.querySelector(ToTopButtonElement.defaults.selectorLayer);
		}
		return window;
	}

	/**
	 * _getBodyHeight
	 * @returns {number} html/body height
	 * @private
	 * @static
	 */
	static _getBodyHeight() {
		const body = document.body;
		const html = document.documentElement;

		return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
	}
}

if (window.customElements.get(ToTopButtonElement.defaults.elementName) === undefined) {
	window.customElements.define(ToTopButtonElement.defaults.elementName, ToTopButtonElement);
}
