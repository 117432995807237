import {searchAgentAuthController} from './search-agent-auth-controller';
import {searchAgentController} from './search-agent-controller';
import {searchAgentObserver} from './search-agent-observer';
import {searchAgentState} from './search-agent-state';
import {polling} from 'core-utils';

/**
 * defaults
 * @type {{itemId: string, searchAgentItem: string, statusExtended: string}}
 */
const defaults = {
	searchAgentItem: '.sc-search-agent-dashboard-item',
	statusExtended: 'search-agent-status-extended',
	itemId: 'search-agent-id'
};

class SearchAgentEntry {
	constructor() {
		searchAgentState.subscribe(this);
	}

	/**
	 * update - base models callback function
	 * @param {object} payload - payload
	 * @returns {void} void
	 */
	update({payload}) {
		if (payload && payload.action && payload.action !== 'save') {
			this._handleDashboardEntry();
		}
	}

	async checkReferrer() {
		const referrer = document.referrer;
		if (referrer.match(/\/\/identity/gm)) {
			if (searchAgentController.saveSearchStorageState) {
				try {
					const state = await searchAgentAuthController.getAuthenticationState();
					if (state.isAuthenticated) {
						await searchAgentController.saveSearchAgent();
						searchAgentController.saveSearchStorageState = false;
						searchAgentObserver.triggerUpdate();
					}
				}
				catch (e) {
					console.error('Search Agent Authentication failed: ', e);
				}
			}
		}
	}

	/**
	 * holdEntryParameter - extracts params from hash and sets searchAgentState values
	 * @param {Map} urlParams urlParams
	 * @returns {void} void
	 */
	holdEntryParameter(urlParams) {
		if (urlParams.has('action')) {
			const action = urlParams.get('action');
			let searchId = {};
			if (urlParams.has('searchid')) {
				searchId = urlParams.get('searchid');
			}
			searchAgentState.searchAgentEntry = {action, searchId};
		}
	}

	/**
	 * _handleDashboardEntry
	 * @param {string} searchString searchString
	 * @private
	 * @returns {void} void
	 */
	_handleDashboardEntry() {
		const {action, searchId} = searchAgentState.searchAgentEntry;
		searchAgentState.searchAgentEntryStorage.clearStorage();
		this._handleActions(action, searchId);
	}

	/**
	 * _handleActions
	 * @param {string} action action
	 * @param {string} searchId searchId
	 * @private
	 * @returns {void} void
	 */
	_handleActions(action = '', searchId = '') {
		switch (action) {
			case 'delete':
			case 'unsubscribe':
				this._openDashBoardItem(searchId);
				break;
			default:
				console.log('No action found');
		}
	}

	/**
	 * _openDashBoardItem
	 * @param {string} searchId searchId from dashboard item to be opened
	 * @private
	 * @returns {void} void
	 */
	async _openDashBoardItem(searchId) {
		// wait/poll for search items to be loaded
		polling.wait(() => ([...document.querySelectorAll(defaults.searchAgentItem)].length > 1), 3000).then(() => {
			const searchAgentItems = [...document.querySelectorAll(defaults.searchAgentItem)];
			searchAgentItems.forEach((item) => {
				const id = item.getAttribute(defaults.itemId);
				if (id === searchId) {
					item.setAttribute(defaults.statusExtended, true);
				}
				else {
					item.setAttribute(defaults.statusExtended, false);
				}
			});
		});
	}
}

/**
 * searchAgentEntry instance
 * @type {SearchAgentEntry}
 */
const searchAgentEntry = new SearchAgentEntry();

export {searchAgentEntry, SearchAgentEntry};
