import {contactButtonTemplate} from '../../buttons/templates/contact-button-template';
import {contactLinkTemplate} from '../../buttons/templates/contact-link-template';
import {ecomButtonTemplate} from '../../buttons/templates/ecom-button-template';
import {leasingButtonTemplate} from '../../buttons/templates/leasing-button-template';

const exports = `
{{~ it.data :item}}
	{{? item.type ==="contact" || item.type ==="nws"}}
		${contactButtonTemplate}
	{{?? item.type === "link" }}
		${contactLinkTemplate}
	{{?? item.type === 'ecom' || item.type === "ecom-link"}}
		${ecomButtonTemplate}
	{{?? item.type === "leasing"}}
		${leasingButtonTemplate}
	{{?}}
{{~}}
`;

export {exports as vehicleTilesButtonsTemplate};
