import {vehicleTilesButtonTemplate} from './vehicle-tiles-buttons';
import {vehicleTilesCTAButtonsTemplate} from './vehicle-tiles-cta-buttons';

const UK_MARKET_CONTEXT = 'ukuc/en';
const isUkMarket = SETUPS.get('stockcar.scs.market.path') === UK_MARKET_CONTEXT;

let exports = `
${vehicleTilesCTAButtonsTemplate}
${vehicleTilesButtonTemplate}
</div>
{{? !vehicle.suggestedCar }}
	<div class="sc-favorite-layer">
		<div class="sc-favorite-layer-inner" data-theme="dark">
			<h2 class="sc-favorite-layer-headline audi-headline-order-2">{{=window.i18n['sc.favorites.layer.headline']||'Headline'}}</h2>
			<p class="sc-favorite-layer-text audi-copy-s">{{=window.i18n['sc.favorites.layer.copy']||'Copy'}}</p>
			<div class="sc-favorite-delete audi-button" data-vehicle-id="{{=vehicle.id}}"><span class="audi-button__text" data-vehicle-id="{{=vehicle.id}}">{{=window.i18n['sc.favorites.layer.button.yes']||'Ja'}}</span></div>
			<div class="audi-button audi-button--ghost sc-favorite-abort"><span class="audi-button__text">{{=window.i18n['sc.favorites.layer.button.no']||'Nein'}}</span></div>
		</div>
	</div>
{{?}}
	</li>
{{~}}
`;

if (isUkMarket) {
	exports = `{{? vehicle && vehicle.type && vehicle.financing && vehicle.financing.calculationDisclaimer && vehicle.type == 'U'}}
		{{? !SETUPS.get('scopes.hide.finance.in.tiles') && SETUPS.get('stockcar.financing.rate.show')}}
			<div class="sc-car-tile-special-calculation-disclaimer">
				<span class="sc-car-tile-special-short-disclaimer sc-hypenate audi-copy-s">
					{{=vehicle.financing.calculationDisclaimer}}
				</span>
			</div>
		{{?}}
	{{?}}` + exports;
}

export {exports as vehicleTilesBottom};
