import ScsModelBase from '../filter/scs-model-base';

class LocationModelClass extends ScsModelBase {
	constructor() {
		super();
		this._location = {};
	}

	/**
	 * setter for location property
	 * @param {object} location location
	 */
	set location(location) {
		this._location = location;
		this.triggerUpdate();
	}

	/**
	 * getter for location object
	 * @returns {object} location
	 */
	get location() {
		return this._location;
	}
}

/**
 * LocationModelClass instance
 * @type {LocationModelClass}
 */
const locationModel = new LocationModelClass();

export {LocationModelClass, locationModel};
