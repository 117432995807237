import {modalLayer, ModalLayerContentBase} from 'core-application';
import {ACTIONS, SELECTORS, STCK_STORE} from '@oneaudi/stck-store';
import {iframePostModalLayerTemplate} from './iframe-post-modal-layer-template';
import {template} from 'core-utils';
import {scPage} from '../../stockcars-utils-bundle';
import {patternMatching} from 'core-global';

export default class IframePostModalLayerElement extends ModalLayerContentBase {

	constructor(data_ = {}) {
		super();
		this._bindContextToFunctions();
		this.data = data_;
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object <{closeButton: string, confirmButton: string}>} defaults
	 */
	static get defaults() {
		return {
			form: '.sc-iframe-post-form',
			closeButton: '.modal-layer--close-button'
		};
	}

	/**
	 * get type
	 * @static
	 * @returns {string} modal layer type
	 */
	static get type() {
		return 'IframePostModalLayerElement';
	}

	/**
	 * getContent
	 * @param {object} data_ modal link dataset
	 * @returns {Promise<IframePostModalLayerElement>} iframe modal layer element
	 */
	static async getContent(data_) {
		const element = new IframePostModalLayerElement(data_);
		await element._initializeContent();
		return element;
	}

	/**
	 * _initializeContent - preload and render content
	 * @returns {void} returns nothing
	 */
	async _initializeContent() {
		this.vehicleId = this._setVehicleId();
		this.data.iframeUrl = await this._getUrl();
		this._render();
		this._iframe = this.querySelector('#sc-post-form-iframe');
		this._form = this.querySelector('#sc-iframe-post-form');
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	async connectedCallback() {
		this.createPostFormData();
		this._addEvents();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	 * _addEvents
	 * @private
	 * @returns {void} void
	 */
	_addEvents() {
		window.addEventListener('message', this._handlePostMessage);
		this.closeButton.addEventListener('click', this._closeLayer);
	}

	/**
	 * _removeEvents
	 * @private
	 * @returns {void} void
	 */
	_removeEvents() {
		window.removeEventListener('message', this._handlePostMessage);
		this.closeButton.removeEventListener('click', this._closeLayer);
	}

	/**
	 * _set vehicle id
	 * @private
	 * @returns {string} return vehicleId
	 */
	_setVehicleId() {
		let vehicleId = '';
		if (!!this.data && !!this.data.targetElement && !!this.data.targetElement.closest('[data-vehicle-id]')) {
			vehicleId = this.data.targetElement.closest('[data-vehicle-id]').dataset.vehicleId;
		}
		else {
			vehicleId = scPage.getVehicleIdFromUrl();
		}
		return vehicleId;
	}

	/**
	 * _handlePostMessage
	 * @param {event} event event
	 * @private
	 * @returns {void} void
	 */
	_handlePostMessage(event) {
		const pseudoATag = this._geAnchorTagFromString(this.data.iframeUrl);

		if (event.origin === pseudoATag.origin) {
			try {
				const messageDataJson = JSON.parse(event.data);
				if (messageDataJson.height) {
					this._iframe.height = messageDataJson.height + 'px';
				}
			}
			catch (error) {
				console.log('No message data');
			}
		}
	}

	/**
	 * _getUrl
	 * @private
	 * @returns {string} url
	 */
	async _getUrl() {
		let url = '';
		this._addPatternVehicleId();
		if (!!this.data && !!this.data.patternUrl && this.data.patternUrl !== '') {
			url = await patternMatching.getReplacedUrl(this.data.patternUrl, '');
		}
		else if (!!this.data && !!this.data.url && this.data.url !== '') {
			url = this.data.url;
		}
		else {
			if (!!SETUPS.get('stockcar.ctaSpecialLinks') && !!SETUPS.get('stockcar.ctaSpecialLinks').aoz && SETUPS.get('stockcar.ctaSpecialLinks').aoz.url !== '') {
				url = (SETUPS.get('stockcar.ctaSpecialLinks').aoz.patternUrl && SETUPS.get('stockcar.ctaSpecialLinks').aoz.patternUrl !== '') ? await patternMatching.getReplacedUrl(SETUPS.get('stockcar.ctaSpecialLinks').aoz.patternUrl, '') : SETUPS.get('stockcar.ctaSpecialLinks').aoz.url;
			}
			else {
				url = '';
			}
		}
		this._removePatternVehicleId();
		return url;
	}

	/**
	 * add pattern vehicle id
	 * @private
	 * @returns {void} void
	 */
	_addPatternVehicleId() {
		STCK_STORE.dispatch(ACTIONS.PATTERN.setPattern({patternVehicleId: this.vehicleId}));
	}

	/**
	 * remove pattern vehicle id
	 * @private
	 * @returns {void} void
	 */
	_removePatternVehicleId() {
		STCK_STORE.dispatch(ACTIONS.PATTERN.removePattern());
	}

	/**
	 * _geAnchorTagFromString
	 * @param {string} urlString urlString
	 * @returns {HTMLAnchorElement} html a tag with url as hred
	 * @private
	 */
	_geAnchorTagFromString(urlString) {
		const pseudoATag = document.createElement('a');
		pseudoATag.href = urlString;
		return pseudoATag;
	}

	/**
	 * createPostFormData
	 * @returns {void} void
	 */
	createPostFormData() {
		const vehicle = SELECTORS.VEHICLES.getVehiclesMapByIds(STCK_STORE.state, [this.vehicleId]).get(this.vehicleId);

		if (vehicle) {
			const products = SELECTORS.AOZ.getAddedProductsByVehicleId(STCK_STORE.state, this.vehicleId);
			const financing = SELECTORS.FINANCING.getSummaryOnePointFive(STCK_STORE.state, this.vehicleId);
			const vehicleData = SELECTORS.VEHICLES.getVehiclesMap(STCK_STORE.state).get(this.vehicleId);
			const postData = [
				{'aoz': products},
				{'audicode': vehicle.audiCode},
				{'financingData': financing},
				{'vehicleData': vehicleData}
			];
			this._form.appendChild(this._getPostInput({name: 'inquiry_json_data', value: JSON.stringify(postData)}));
			this._form.submit();
		}
	}

	/**
	 * _getPostInput
	 * @param {string} name name
	 * @param {string} value value
	 * @returns {HTMLInputElement} html input element
	 * @private
	 */
	_getPostInput({name = '', value = ''}) {
		const tempInput = document.createElement('input');
		tempInput.setAttribute('hidden', 'true');
		tempInput.name = name;
		tempInput.value = value;
		return tempInput;
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._handlePostMessage = this._handlePostMessage.bind(this);
		this._render = this._render.bind(this);
		this._closeLayer = this._closeLayer.bind(this);
	}

	/**
	 * render
	 * @returns {void} returns nothing
	 */
	_render() {
		this.innerHTML = template.render(iframePostModalLayerTemplate, this.data);
		this.closeButton = this.querySelector(IframePostModalLayerElement.defaults.closeButton);
		if (this.data && this.data.targetType && this.data.targetType === '_blank') {
			this._closeLayer();
		}
		else if (this.data && this.data.targetElement && this.data.targetElement.classList.contains("aoz-product-added-contact-handler")) {
			const aozProductLayer = this.data.targetElement.closest('aoz-product-added-modal-layer-element');
			if (aozProductLayer) {
				aozProductLayer._triggerClose();
			}
		}
	}

	/**
	 * close layer
	 * @returns {void} returns nothing
	 */
	_closeLayer() {
		setTimeout(() => {
			this._triggerClose();
		}, 0);
	}
}

modalLayer.registerLayerType(IframePostModalLayerElement);

if (window.customElements.get('iframe-post-modal-layer-element') === undefined) {
	window.customElements.define('iframe-post-modal-layer-element', IframePostModalLayerElement);
}
