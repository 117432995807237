const exports = `
	{{? vehicle && vehicle.dealer && !!vehicle.dealer.name}}
		{{? !vehicle.nationWideSelling && !vehicle.investorSharedVehicle}}
			<p class="sc-car-tile-dealer audi-copy-s">
				<svg class="sc-car-tile-info-symbol nm-icon-poi-small">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/poi-small.svg#nm-icon-poi-small"}}></use>
				</svg>
				{{? vehicle.employeeVehicle}}
					<span>{{=window.i18n['sc.messages.dealer.employeeLabel']}}</span>
				{{??}}
					<span>
						{{var unitTextKey = window.i18n['sc.range-unit.label.' + SETUPS.get('stockcar.mileage.unit')];}}
						{{? vehicle.distance}}{{=vehicle.distance}} {{=unitTextKey}} - {{?}}
						{{? window.i18n['sc.details.dealer-label'].trim() !== '' }}
							{{=window.i18n['sc.details.dealer-label']}}<a href="#fn_dealer_offer" class="audi-j-footnote-reference" target="_self"><sup class="audi-footnote-anchor__text"></sup></a>:<br />
						{{?}}
						{{=vehicle.dealer.name}}
					</span>
				{{?}}
			</p>
		{{?}}
	{{?}}
	{{? vehicle.nationWideSelling}}
		{{var hasNwsLink = SETUPS.get('stockcar.infoIconLinks').nws.url !== '';}}
		<p class="sc-car-tile-nationwideselling-link audi-copy-s">
			<svg class="sc-car-tile-info-symbol nm-icon-car-handover-small">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/car-handover-small.svg#nm-icon-car-handover-small"}}></use>
			</svg>
			<span>
				{{? window.i18n['sc.nationwideselling.headline'].trim() !== '' }}
					{{=window.i18n['sc.nationwideselling.headline']}}<a href="#fn_nws_offer" class="audi-j-footnote-reference" target="_self"><sup class="audi-footnote-anchor__text"></sup></a>:<br />
				{{?}}
				{{? hasNwsLink}}
					<a href="{{=SETUPS.get('stockcar.infoIconLinks').nws.url}}" class="nm-layerLink audi-link-s audi-link-s--underline">
						{{=window.i18n['sc.nationwideselling.label']||'sc.nationwideselling.label'}}
					</a>
				{{?}}
				{{? !hasNwsLink}}
					{{=window.i18n['sc.nationwideselling.label']||'sc.nationwideselling.label'}}
				{{?}}
			</span>
		</p>
	{{?? vehicle.investorSharedVehicle}}
		{{var hasDealerChainLink = SETUPS.get('stockcar.infoIconLinks').isv.url !== '';}}
		{{var hasDealerChainText = SETUPS.get('stockcar.infoIconLinks').isv.text !== '';}}
		{{var dealerChainLinkClasses = hasDealerChainLink ? 'nm-layerLink audi-link-s audi-link-s--underline' : 'audi-copy-s';}}
		{{? hasDealerChainLink && hasDealerChainText}}
			<a class="sc-car-tile-dealer audi-copy-s {{=dealerChainLinkClasses}} {{=SETUPS.get('stockcar.infoIconLinks').isv.css}}" href="{{=SETUPS.get('stockcar.infoIconLinks').isv.url}}">
				<svg class="sc-car-tile-info-symbol nm-icon-car-handover-small">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/car-handover-small.svg#nm-icon-car-handover-small"}}></use>
				</svg>
				{{=SETUPS.get('stockcar.infoIconLinks').isv.text}}
			</a>
		{{?}}
	{{?}}
`;

export {exports as vehicleTilesDealerLabel};
