import ScsModelBase from '../filter/scs-model-base';

class SearchAgentObserverClass extends ScsModelBase {
	constructor() {
		super();
	}
}

const searchAgentObserver = new SearchAgentObserverClass();
export {searchAgentObserver, SearchAgentObserverClass};
