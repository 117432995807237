import {FORMAT_UTILS, lazyLoad, OBJECT_UTILS, scPage} from '../../stockcars-utils-bundle';
import {vehiclesController} from './vehicles-controller';
import {dom, template} from 'core-utils';
import ScViewTilesTemplateLookup from '../tiles/sc-view-tiles-template-lookup';
import {appEvents, clientLayer} from 'core-application';
import {CoreEvents} from '@audi/audi-core-events';
import {globalEventEmitter} from '../event-emitter-proxy';
import {scsController} from '../filter/scs-controller';
import {eecModalLayerTemplate} from '../../stockcars-modal-layer-bundle';
import {ACTIONS, SELECTORS, STCK_STORE} from '@oneaudi/stck-store';

export default class VehicleElement extends HTMLElement {
	constructor() {
		super();
		this._globalEventBus = globalEventEmitter;
		this._scDetailLinkPattern = '';
		/**
		 * this._VAGUE_FILTER_VALUE - Max Value for filter, if greater -> this._VAGUE_FILTER_VALUE +
		 * @type {number}
		 */
		this._VAGUE_FILTER_VALUE = SETUPS.get('stockcar.vague.value')
			? parseInt(SETUPS.get('stockcar.vague.value'), 10)
			: 5;
		this.unsubscribeVehcilesObeserver = null;
		this.unsubscribeCompareObeserver = null;
		const initialViewType = this.getAttribute('data-view');
		STCK_STORE.dispatch(ACTIONS.UI.setResultsViewType({resultsViewType: initialViewType}));
	}

	static get defaults() {
		return {
			classMain: '.sc-results-view',
			classItems: '.sc-result-items',
			classItemsList: '.sc-result-items-container',
			classItemsListSkeleton: '.sc-result-items-container-skeleton',
			classLink: '.sc-details-link',
			loadMoreBtn: '.sc-j-load-more',
			loadMoreBtnWrap: '.sc-load-more-btn',
			sortDropdown: '#possibleSortAttributes',
			viewOptions: '.sc-filter-view-option a',
			jsLayerName: 'sc-results-eec-js-layer',
			jsEecLayer: '.sc-results-eec-layer',
			scResultCount: '.j-sc-result-count',
			scResultCountHits: '.j-sc-result-count-hits',
			phevIdentifier: 'OVC_HEV'
		};
	}

	connectedCallback() {
		this.containerSkeleton = this.querySelector(VehicleElement.defaults.classItemsListSkeleton);
		this._useSpainTemplate = SETUPS.get('stockcar.scs.market.path')?.split('/').map(part => part.toLowerCase()).includes('es');
		this._setSelectedSortingAttribute(this._getCurrentSortParam());
		this._addEventListener();
		this.update();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} nothing
	 */
	disconnectedCallback() {
		this._removeEventListener();
	}

	/**
	 * @returns {string} the currently selected sort param
	 */
	_getCurrentSortParam() {
		return STCK_STORE.state.sorting.results;
	}

	/**
	 * add Links before template rendering
	 * @method addDetailLinks
	 * @param {Object} value - detail Link object
	 * @return {Object} returns object with new url value
	 */
	_addDetailLinks(value = {}) {
		const clonedValue = {...value};
		clonedValue.url = (this._scDetailLinkPattern || '').replace(/SC_VEHICLE_ID/i, clonedValue.id);
		return clonedValue;
	}

	/**
	 *
	 * @method setDefaultUrl
	 * @returns {void} returns nothing
	 */
	_setDefaultUrl() {
		const newSearchButton = this.querySelector('.sc-new-search');
		if (STCK_STORE.state.totalCount <= 0 && newSearchButton) {
			newSearchButton.setAttribute('href', this._scDefaultUrl);
		}
	}

	/**
	 * Add favorite status before rendering
	 * @param {Object} resultObj - resultObj
	 * @returns {Object} - resultObj -resultObj
	 */
	_addFavoriteStatus(resultObj) {
		resultObj.forEach(vehicle => {
			vehicle.favorite = SELECTORS.VEHICLES.isFavoriteVehicle(STCK_STORE.state, vehicle.id);
		});
		return resultObj;
	}

	/**
	 * Update dom when compare EVENTS.SC_COMPARE_UPDATE is fired
	 * @param {Object} data - Event data
	 * @returns {void} - returns nothing
	 */
	_onUpdateCompareHandler() {
		const count = SELECTORS.VEHICLES.getCompareVehicleIdsState(STCK_STORE.state).length;
		if (count === 3) {
			this.classList.add('sc-cmp-max');
		}
		else {
			this.classList.remove('sc-cmp-max');
		}
	}

	_setVehiclesContainerContent(incremental_, container, itemsString) {
		if (!incremental_) {
			container.innerHTML = '';
			container.innerHTML = itemsString;
		}
		else {
			dom.appendHtmlString(container, itemsString);
		}
	}

	_renderTemplate(preparedVehicleDataItems) {
		preparedVehicleDataItems.useSpainTemplate = this._useSpainTemplate;
		let itemsString;
		if (STCK_STORE.state.totalCount <= 0) {
			itemsString = template.render(ScViewTilesTemplateLookup.getTemplate('results-empty'));
		}
		else {
			itemsString = template.render(ScViewTilesTemplateLookup.getTemplate(this._scMarket), {
				data: preparedVehicleDataItems
			});
		}
		return itemsString;

	}

	/**
	 * addCompareStatus
	 * @method addCompareStatus
	 * @param {Array} vehicles_ vehicle array for preparing with compare status
	 * @returns {Array} prepared array
	 */
	_getVehiclesWithCompareState(vehicles_) {
		let vehicles = [...vehicles_];
		const compareIds = SELECTORS.VEHICLES.getCompareVehicleIdsState(STCK_STORE.state);
		vehicles = vehicles.map(vehicle => {
			const newVehicle = {compare: ~compareIds.indexOf(vehicle.id), ...vehicle}; // eslint-disable-line no-bitwise
			return newVehicle;
		});

		return vehicles;
	}

	/**
	 * addFuelTypes
	 * @method addFuelTypes
	 * @param {Array} vehicles_ vehicle array for preparing with fuel types (also hybrids)
	 * @returns {Array} prepared array
	 */
	_addFuelTypes(vehicles_) {
		vehicles_.forEach((vehicle) => {
			let fuels = [];
			if (vehicle.fuel) {
				const fuelCode = vehicle.fuel.code;
				const fuelCodeDescription = window.i18n[`sc.eec.fuel.${fuelCode.toLowerCase()}`];
				fuels.push(fuelCodeDescription);
			}
			else if (vehicle.io && (vehicle.io.hasNedc || vehicle.io.hasWltp) && vehicle.io.fuels.length) {
				vehicle.io.fuels.forEach(item => {
					if (!!item.fuel) {
						let type = window.i18n[`sc.tiles.fuel.type.${item.fuel.toLowerCase()}.label`] || '';
						fuels.push(type);
					}
				});
			}
			vehicle.fuelTypes = fuels.join(' / ');
		});
		return vehicles_;
	}

	/**
	 * checkElectrical
	 * @method checkElectrical
	 * @param {Array} vehicles_ vehicle array for checking if its elements are electrical
	 * @returns {Array} prepared array
	 */
	_checkElectrical(vehicles_) {
		vehicles_.forEach((vehicle) => {
			let isElectrical = false;
			const engineType = OBJECT_UTILS.getNestedObject(vehicle, 'io.engineType'.split('.')) || '';
			if (vehicle.io && vehicle.io.fuels && vehicle.io.fuels.length) {
				isElectrical = !!(vehicle.io.fuels.filter((item = {}) => {
					return (item.fuel === 'ELECTRICAL') && (engineType !== VehicleElement.defaults.phevIdentifier);
				}).length);
			}
			vehicle.isElectrical = isElectrical;
		});
		return vehicles_;
	}

	_addOrderStatus(vehicles_) {
		const IN_DELIVERY_STATES = ['7', '8', '9', '10'];
		const AT_DEALER_STATES = ['11', '12'];

		const hasOrderStatus = (orderStatus) => {
			if (!orderStatus) {
				return false;
			}
			const validType = !isNaN(parseInt(orderStatus, 10));
			const validState = IN_DELIVERY_STATES.includes(orderStatus) || AT_DEALER_STATES.includes(orderStatus);
			return validType && validState;
		};

		const getOrderStatusTextKey = (orderStatus) => {
			if (IN_DELIVERY_STATES.includes(orderStatus)) {
				return 'sc.details.dealer.order-status.in-delivery';
			}

			if (AT_DEALER_STATES.includes(orderStatus)) {
				return 'sc.details.dealer.order-status.at-dealer';
			}
			return '';
		};

		vehicles_.forEach(vehicle => {
			const orderStatus = vehicle.vehicleOrderStatus;
			if (hasOrderStatus(orderStatus)) {
				vehicle.calculatedVehicleOrderStatus = getOrderStatusTextKey(orderStatus);
			}
		});

		return vehicles_;
	}

	/**
	 * addAvailableFromFlag
	 * @method addAvailableFromFlag
	 * @param {Array} vehicles_ vehicle array for checking if its elements are electrical
	 * @returns {Array} prepared array
	 */
	_addAvailableFromFlag(vehicles_) {
		vehicles_.forEach((vehicle) => {
			vehicle.isAvailableSoonVehicle = scPage.isAvailableSoonVehicle(vehicle);
		});
		return vehicles_;
	}

	_addWhatsApp(vehicles_) {
		vehicles_.forEach((vehicle) => {
			// depending on the type UC/NC and the maintainance of a dedicated i18n label
			// we toggle the appearance of the whatsapp link
			const vehicleType = vehicle.type;
			const whatsAppLabelKey = `sc.${vehicleType?.toLowerCase()}c.details.dealer-whatsapp.label`;
			const whatsAppLabel = window.i18n[whatsAppLabelKey]?.trim();
			const hasLabel = whatsAppLabel !== '';
			const whatsAppLink = window.location.origin + vehicle.url;

			if (hasLabel && window.i18n['sc.details.dealer-whatsapp.number']?.trim() !== '' && window.i18n['sc.details.dealer-whatsapp.message'] && vehicle.dealer.name && vehicle.commissionNumber) {
				const whatsAppMessage = window.i18n['sc.details.dealer-whatsapp.message']?.replace('${commissionNumber}', vehicle.commissionNumber)?.replace('${dealer}', vehicle.dealer.name || '') + ' ' + whatsAppLink;
				const whatsAppMessageEncoded = encodeURIComponent(whatsAppMessage);
				vehicle.whatsAppLink = `https://api.whatsapp.com/send?phone=${window.i18n['sc.details.dealer-whatsapp.number']}&text=${whatsAppMessageEncoded}`;
				vehicle.whatsAppLabel = whatsAppLabel;
			}
		});
		return vehicles_;
	}

	_getPreparedVehicles(vehicleDataItems_) {
		const vehiclesWithCompareState = this._getVehiclesWithCompareState(vehicleDataItems_);
		const vehiclesWithDealerSpecialLink = this._addDealerSpecialLink(vehiclesWithCompareState);
		const vehiclesWithFuelTypes = this._addFuelTypes(vehiclesWithDealerSpecialLink);
		const vehiclesCheckedForElectrical = this._checkElectrical(vehiclesWithFuelTypes);
		const vehiclesWithOrderStatus = this._addOrderStatus(vehiclesCheckedForElectrical);
		const vehiclesAvailableFromDate = this._addAvailableFromFlag(vehiclesWithOrderStatus);
		const vehiclesWithWhatsApp = this._addWhatsApp(vehiclesAvailableFromDate);

		return this._prepareDataForScope(vehiclesWithWhatsApp); // TODO: Scope STCK_FINANCE, will be removed once the rate is shown on all markets
	}

	/**
	 * render the DOM
	 * @method render
	 * @param {array} vehicleDataItems_ - array containing the template's model data
	 * @param {boolean} incremental_ - render data incrementally (only appending new items)
	 * @return {void} returns nothing
	 */
	_render(vehicleDataItems_, incremental_) {
		const container = this.querySelector(VehicleElement.defaults.classItemsList);
		const preparedVehicleDataItems = this._getPreparedVehicles(vehicleDataItems_);
		const selectUsedCar = document.querySelector('.usedCar');
		const selectNewCar = document.querySelector('.newCar');
		const isUsedCar = vehicleDataItems_.find(selectCar => selectCar.type === 'U');
		if (container && !STCK_STORE.state.ui.loading) {
			const itemsString = this._renderTemplate(preparedVehicleDataItems);
			this.containerSkeleton.classList.add('sc-hidden');
			this._setVehiclesContainerContent(incremental_, container, itemsString);
			const contentRendered = new CustomEvent(appEvents.CONTENT_RENDERED, {
				element: container,
				type: 'module',
				detail: {element: container}
			});
			document.dispatchEvent(contentRendered);
		}
		if (STCK_STORE.state.ui.loading) {
			this.containerSkeleton.classList.remove('sc-hidden');
		}
		if (isUsedCar) {
			selectNewCar.classList.add('sc-hidden');
			selectUsedCar.classList.remove('sc-hidden');
		}
		else {
			selectNewCar.classList.remove('sc-hidden');
			selectUsedCar.classList.add('sc-hidden');
		}
		this._setDefaultUrl();
		this._showNextHideButton();
		lazyLoad.register(this);
	}

	/**
	 * add cq special link to dealer object
	 * @method __.addDealerSpecialLink
	 * @param {Object} promiseData_ promiseData object
	 * @returns {Object} prepared Obj
	 */
	_addDealerSpecialLink(promiseData_) {
		let result;
		if (Array.isArray(promiseData_)) {
			result = promiseData_;
			let spclink = (this.getAttribute('data-dealer-speciallink') || '').trim();
			result = result.map(vehicleObj => {
				const clonedVehicle = {...vehicleObj};
				clonedVehicle.dealer = {'cqSpecialLink': spclink.replace(/(href=\"[^\"]*)/, '$1?vehicleid=' + vehicleObj.id), ...vehicleObj.dealer}; // eslint-disable-line no-useless-escape
				return clonedVehicle;
			});
		}
		return result;
	}

	/**
	 * private function handling the scope STCK_FINANCE
	 * if the scope is not set, remove the finance data
	 * @todo remove once the rate is shown on all markets
	 * @param {Object} vehicleDataItems_ -  the vehicle data
	 * @returns {Object} the changed vehicle data
	 */
	_prepareDataForScope(vehicleDataItems_) {
		if (!(SETUPS.get('stockcar.financing.rate.show') && !SETUPS.get('scopes.hide.finance.in.tiles'))) {
			vehicleDataItems_.map(item => {
				item.financing = {};
			});
		}
		else if (SETUPS.get('scopes.hide.finance.for.ecom')) {
			vehicleDataItems_.map(item => {
				if (item.buyableOnline) {
					item.financing = {};
				}
			});
		}
		return vehicleDataItems_;
	}

	/**
	 * add all Event Listener
	 * @method addEventListener
	 * @return {void} returns nothing
	 */
	_addEventListener() {
		this._onUpdateViewHandler = this._onUpdateViewHandler.bind(this);
		this._handleSortDropdownChanges = this._handleSortDropdownChanges.bind(this);
		this._handleLoadMoreClick = this._handleLoadMoreClick.bind(this);
		this._handleViewOptionsClick = this._handleViewOptionsClick.bind(this);
		this._generateEecLayerHtml = this._generateEecLayerHtml.bind(this);
		this._layerLoadedHandler = this._layerLoadedHandler.bind(this);
		this.update = this.update.bind(this);

		this.unsubscribeVehcilesObeserver = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getResultVehicleIdsState, this.update);
		this.unsubscribeCompareObeserver = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getCompareVehicleIdsState, this.update);
		dom.getEventDelegate('body').on('change', VehicleElement.defaults.sortDropdown, this._handleSortDropdownChanges);
		dom.getEventDelegate('body').on('click', VehicleElement.defaults.loadMoreBtn, this._handleLoadMoreClick);
		dom.getEventDelegate('body').on('click', VehicleElement.defaults.viewOptions, this._handleViewOptionsClick);
		// EEC Layer Events
		clientLayer.register(VehicleElement.defaults.jsLayerName, this._generateEecLayerHtml);
		this._globalEventBus.on(CoreEvents.LAYER_LOADED, this._layerLoadedHandler);
	}

	/**
	 * remove all Event Listener
	 * @method addEventListener
	 * @returns {void} void
	 */
	_removeEventListener() {
		this.unsubscribeVehcilesObeserver();
		this.unsubscribeCompareObeserver();
		dom.getEventDelegate('body').off('change', VehicleElement.defaults.sortDropdown, this._handleSortDropdownChanges);
		dom.getEventDelegate('body').off('click', VehicleElement.defaults.loadMoreBtn, this._handleLoadMoreClick);
		dom.getEventDelegate('body').off('click', VehicleElement.defaults.viewOptions, this._handleViewOptionsClick);
		this._globalEventBus.off(CoreEvents.LAYER_LOADED, this._layerLoadedHandler);
	}

	/**
	 * generateEecLayerHtml - generates layer outer html
	 * @param {Object} payload_ payloas object
	 * @return {string} returns Layer outer html
	 */
	_generateEecLayerHtml(payload_) {
		this._eecVehicleId = payload_;
		return '<div class="nm-layer-wrapper nm-layer-wrapper-sc-results-eec"><div class="sc-results-eec-layer nm-j-jslayer nm-layer"></div></div>';
	}

	/**
	 * layer loaded handler
	 * @return {Void} returns nothing
	 */
	async _layerLoadedHandler() {
		if (!!this._eecVehicleId) {
			try {
				const vehicleData = await vehiclesController.loadVehicleById(this._eecVehicleId);
				this._generateLayerInnerHtml(vehicleData);
			}
			catch (err) {
				console.warn(`could not load vehicle by Id: ${this._eecVehicleId}`, err);
			}
		}
	}

	/**
	 * generateLayerInnerHtml - renders tpl into layer wrapper
	 * @param {string} data_ imprint from data Attribute
	 * @return {Void} returns nothing
	 */
	_generateLayerInnerHtml(data_) {
		var innerHtml,
			content = {
				envkv: data_.envkvData,
				envkvDisclaimer: data_.footnotes.envkvDisclaimer
			},
			outerHtml = dom.getElement(VehicleElement.defaults.jsEecLayer);

		innerHtml = template.render(eecModalLayerTemplate, content);
		outerHtml.innerHTML = innerHtml;
	}

	/**
	 * handle click on more button and trigger incremental loading of new items
	 * @returns {void}
	 */
	_handleLoadMoreClick() {
		// hide button while loading
		const item = this.querySelector(VehicleElement.defaults.loadMoreBtnWrap);
		if (item) {
			item.classList.add('nm-hidden');
		}
		this.containerSkeleton.classList.remove('sc-hidden');
		const currentScrollY = window.scrollY;
		scsController.incrementVehicleResults().then(() => {
			setTimeout(() => {
				window.scrollTo(0, currentScrollY);
			}, 0);
		});
	}

	/**
	 * handle click on options
	 * @param {Event} event - mouse click
	 * @returns {void}
	 */
	_handleViewOptionsClick(event) {
		event.preventDefault();
		const selectViewButton = event.target;
		const viewType = selectViewButton.getAttribute('data-view');
		STCK_STORE.dispatch(ACTIONS.UI.setResultsViewType({resultsViewType: viewType}));
		dom.getElementsArray(VehicleElement.defaults.viewOptions, this).forEach(item => {
			item.classList.remove('active');
		});
		selectViewButton.classList.add('active');
		// reset
		this.classList.remove('list-view');
		if (viewType === 'list') {
			this.classList.add('list-view');
		}
	}

	/**
	 * hide Next Button during loading and show on loaded
	 * @returns {void}
	 */
	_showNextHideButton() {
		const item = this.querySelector(VehicleElement.defaults.loadMoreBtnWrap);
		if (item) {
			if (STCK_STORE.state.totalCount > SELECTORS.VEHICLES.getResultVehicleIdsState(STCK_STORE.state).length) { // TODO check???
				item.classList.remove('nm-hidden');
			}
			else {
				item.classList.add('nm-hidden');
			}
		}
	}

	/**
	 * handle dropdown selection for sort
	 * @param {Event} event_ - dropdown change event
	 * @returns {void}
	 */
	_handleSortDropdownChanges(event_) {
		const selectedValue = event_.target.options[event_.target.selectedIndex].value;
		this._setSelectedSortingAttribute(selectedValue);
		scsController.updateFilters();
	}

	/**
	 * set selected dropdown option
	 * @param {string} value_ - selected key from dropdown
	 * @returns {void}
	 */
	_setSelectedSortingAttribute(value_) {
		// reset all selections
		const optionsArr = dom.getElementsArray(VehicleElement.defaults.sortDropdown + ' option', this);
		optionsArr.forEach(elem => {
			elem.selected = false;
			if (elem.value === value_) {
				elem.selected = true;
			}
		});
		STCK_STORE.dispatch(ACTIONS.SORTING.setSorting({sorting: {results: value_}}));
	}

	/**
	 * Listener for update view
	 * called if EVENTS.SC_UPDATE_VIEW is thrown
	 * @method onUpdateViewHandler
	 * @return {void} returns nothing
	 */
	_onUpdateViewHandler() {
		let resultCars = SELECTORS.VEHICLES.getResultVehicleIdsState(STCK_STORE.state);
		let resultArray = [...SELECTORS.VEHICLES.getVehiclesMapByIds(STCK_STORE.state, resultCars).values()];
		this._prepareMetaVehicleData();
		this._addDetailLinks = this._addDetailLinks.bind(this);
		if (resultArray.length) {
			resultArray = resultArray.map(this._addDetailLinks);
			resultArray = this._addFavoriteStatus(resultArray);
			resultArray = this._addTileTabsJson(resultArray);
		}
		this._render(resultArray, false);
		this._setSelectedSortingAttribute(this._getCurrentSortParam());
		this._onUpdateCompareHandler();
		const resultCount = STCK_STORE.state.totalCount;
		this._updateTotalCountsHeadline(this._generateResultCountLabel(resultCount));
	}

	/**
	 * add results tab json
	 * @param {object} resultsObject - vehicle results object
	 * @return {object} resultsObject - modified vehicle results object
	 * @private
	 */
	_addTileTabsJson(resultsObject) {
		let stringifiedJSON = dom.getElement(VehicleElement.defaults.classItems, this).getAttribute('data-tile-tabs') || '';

		resultsObject.forEach(vehicle => {
			vehicle.tileTabs = stringifiedJSON;
		});
		return resultsObject;
	}

	/**
	 * prepare meta vehicle data
	 * @returns {void}
	 */
	_prepareMetaVehicleData() {
		this._scDetailLinkPattern = dom.getElement(VehicleElement.defaults.classItems, this).getAttribute('data-details-url') || '';
		this._scDefaultUrl = dom.getElement(VehicleElement.defaults.classItems, this).getAttribute('data-default-url') || '';
		this._scMarket = dom.getElement(VehicleElement.defaults.classItems, this).getAttribute('data-market') || '';
	}

	/**
	 * update counter in headline
	 * @param {Number} count_ - number of total results
	 * @returns {void}
	 */
	_updateTotalCountsHeadline(count_) {
		dom.getElement(VehicleElement.defaults.scResultCount, this).innerHTML = count_;
		dom.getElement(VehicleElement.defaults.scResultCountHits, this).innerHTML = (count_ === 1) ? window.i18n['sc.navig.hit'] : window.i18n['sc.navig.hits'];
	}

	/**
	 * generateResultCountLabel
	 * @param {number} count count number for result label
	 * @return {string} resultLabel prepared result label
	 */
	_generateResultCountLabel(count) {
		return FORMAT_UTILS.formatCount(count, this._VAGUE_FILTER_VALUE, this._isVagueFilterResults());
	}

	/**
	 * isVagueFilterResults - checks if scope for vague filter results is set
	 * @return {boolean} - returns if scope is set
	 */
	_isVagueFilterResults() {
		const isVagueScoped = dom.getElement('.sc-scope-vague-filter-results');
		return dom.isElement(isVagueScoped);
	}

	update() {
		this._onUpdateViewHandler();
	}

}
if (window.customElements.get('sc-vehicle-element') === undefined) {
	window.customElements.define('sc-vehicle-element', VehicleElement);
}

