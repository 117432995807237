import {dom, template} from 'core-utils';
import {scModelMandatoryAreaSearch} from './model-mandatory-area-search';
import {mandatoryAreaSearchTemplate} from './mandatory-area-search-template';

class MandatoryAreaSearchViewClass {
	static get defaults() {
		return {
			_selectorAddressInput: '#sc-location-finder-address-input',
			_selectorDistanceInput: '#sc-location-finder-options-distance',
			_selectorDialogueLayer: '.sc-md-mandatory-area-search .sc-dialogue-wrapper',
			_selectorDialogueLayerOpen: 'sc-md-mandatory-area-search-dialogue-layer-open',
			_selectorCount: '.mandatory-area-search-element .sc-count',
			_selectorSuggestionsContainer: '.sc-location-finder-suggestions',
			_selectorSuggestionsItemsActive: 'sc-active-suggestions-item',
			_selectorSubmitButton: '#sc-location-finder-submit',
			_selectorErrorContainer: '.sc-location-finder-error',
			_selectorResetButton: '.sc-location-finder-reset',
			_selectorResetButtonDisabled: 'sc-location-finder-reset-disabled'
		};
	}

	get closeStatus() {
		return this.close;
	}

	set closeStatus(status) {
		this.close = status;
	}


	/**
	 * handle continue state
	 *
	 * @method handleContinueState
	 * @return {void} returns nothing
	 */
	handleContinueState() {
		const submitButton = dom.getElement(MandatoryAreaSearchViewClass.defaults._selectorSubmitButton);
		const addressInput = dom.getElement(MandatoryAreaSearchViewClass.defaults._selectorAddressInput);
		const {locationIndicator} = scModelMandatoryAreaSearch.presetLocation;

		if (dom.isElement(addressInput) && addressInput.value !== '' && locationIndicator && locationIndicator.placeId) {
			submitButton.setAttribute('data-state', 'active');
			this.closeStatus = true;
		}
		else {
			submitButton.setAttribute('data-state', 'inactive');
			this.closeStatus = false;
		}
	}

	/**
	 * render suggestions in dropdown
	 *
	 * @method renderSuggestions
	 * @param {Array} suggestions_ - Suggestions from Google Autocomplete Service
	 * @return {void} returns nothing
	 */
	renderSuggestions(suggestions_) {
		const suggestions = suggestions_ || [];
		const suggestionsContainer = dom.getElement(MandatoryAreaSearchViewClass.defaults._selectorSuggestionsContainer);
		let suggestionsString;
		if (suggestions.length && dom.isElement(suggestionsContainer)) {
			suggestionsContainer.innerHTML = '';
			suggestionsString = template.render(mandatoryAreaSearchTemplate, {'data': suggestions});
			dom.appendHtmlString(suggestionsContainer, suggestionsString);
		}
	}

	/**
	 * close autosuggest list
	 *
	 * @method closeAutosuggestList
	 * @return {void} returns nothing
	 */
	closeAutosuggestList() {
		const suggestionsContainer = dom.getElement(MandatoryAreaSearchViewClass.defaults._selectorSuggestionsContainer);
		if (dom.isElement(suggestionsContainer)) {
			suggestionsContainer.innerHTML = '';
		}
	}

	/**
	 * fill dialogue layer
	 *
	 * @method openDialogueLayer
	 * @return {void} returns nothing
	 */
	fillDialogueLayer() {
		const addressInput = dom.getElement(MandatoryAreaSearchViewClass.defaults._selectorAddressInput);
		const distanceInput = dom.getElement(MandatoryAreaSearchViewClass.defaults._selectorDistanceInput);

		if (scModelMandatoryAreaSearch.presetLocation) {
			const {locationIndicator} = scModelMandatoryAreaSearch.presetLocation;
			if (dom.isElement(addressInput) && addressInput.value === '' && dom.isElement(distanceInput) && locationIndicator && locationIndicator.formattedAddress && locationIndicator.distanceValue) {
				addressInput.value = locationIndicator.formattedAddress;
				distanceInput.value = locationIndicator.distanceValue;
			}

			if (addressInput.value.length > 0) {
				this.showResetButton();
			}
			else {
				this.hideResetButton();
			}

			this.handleContinueState();
		}
	}

	/**
	 * setDialogueLayerFilterCount
	 * @param {string} count filter count
	 * @returns {void} void
	 */
	setDialogueLayerFilterCount(count = '0') {
		const selectorCount = dom.getElement(MandatoryAreaSearchViewClass.defaults._selectorCount);

		if (dom.isElement(selectorCount)) {
			selectorCount.textContent = count;
		}
	}

	/**
	 * get suggested item active count
	 *
	 * @method getSuggestedItemActiveCount
	 * @param {Array} suggestedItems_ - array of suggestion items
	 * @return {string} returns place id
	 */
	getSuggestedItemActiveCount(suggestedItems_) {
		let index;
		if (!!suggestedItems_ && suggestedItems_.length) {
			for (let i = 0; i < suggestedItems_.length; i++) {
				if (suggestedItems_[i].classList.contains(MandatoryAreaSearchViewClass.defaults._selectorSuggestionsItemsActive)) {
					index = i;
					suggestedItems_[i].classList.remove(MandatoryAreaSearchViewClass.defaults._selectorSuggestionsItemsActive);
				}
			}
		}
		return index;
	}

	/**
	 * show error
	 *
	 * @method showError
	 * @param {string} errorType_ - Type of error as string
	 * @return {void} returns nothing
	 */
	showError(errorType_) {
		const errorContainer = dom.getElement(MandatoryAreaSearchViewClass.defaults._selectorErrorContainer);
		if (dom.isElement(errorContainer) && errorType_) {
			[].slice.call(errorContainer.children).forEach((child) => {
				if (child.getAttribute('data-error') === errorType_) {
					child.classList.remove('sc-hidden');
				}
				else {
					child.classList.add('sc-hidden');
				}
			});
			errorContainer.classList.remove('sc-hidden');
		}
	}

	/**
	 * show reset button
	 *
	 * @method showResetButton
	 * @return {void} returns nothing
	 */
	showResetButton() {
		const resetButton = dom.getElement(MandatoryAreaSearchViewClass.defaults._selectorResetButton);
		if (dom.isElement(resetButton)) {
			resetButton.classList.remove(MandatoryAreaSearchViewClass.defaults._selectorResetButtonDisabled);
		}
	}

	/**
	 * hide reset button
	 *
	 * @method hideResetButton
	 * @return {void} returns nothing
	 */
	hideResetButton() {
		const resetButton = dom.getElement(MandatoryAreaSearchViewClass.defaults._selectorResetButton);
		if (dom.isElement(resetButton)) {
			resetButton.classList.add(MandatoryAreaSearchViewClass.defaults._selectorResetButtonDisabled);
		}
	}

	/**
	 * hide error
	 *
	 * @method hideError
	 * @return {void} returns nothing
	 */
	hideError() {
		const errorContainer = dom.getElement(MandatoryAreaSearchViewClass.defaults._selectorErrorContainer);
		if (dom.isElement(errorContainer)) {
			errorContainer.classList.add('sc-hidden');
		}
	}
}

const scViewMandatoryAreaSearch = new MandatoryAreaSearchViewClass();

export {scViewMandatoryAreaSearch, MandatoryAreaSearchViewClass};
