const exports = `
	<div class="modal-layer__inner">
		<div class="modal-layer__inner-content">
			<div class="mandatory-area-search-element" data-layer-options='{"onEscClick": false, "onShaderClick": false, "addCssClass": "modal-layer-mas-options"}'>
				<div class="sc-dialogue-content">
					<div class="sc-dialogue-intro">
						{{? it.backLink}}
							<a class="sc-body-back-link" href="{{=it.backLink}}"><span>{{=window.i18n['sc.location.button.back'] || 'nemo.sc.location.button.back'}}</span></a>
						{{?}}
						<div class="sc-headline-large-bold">{{=window.i18n['sc.location.text.dialogue.headline'] || 'nemo.sc.location.text.dialogue.headline'}}</div>
						<span class="sc-info-text-medium">{{=window.i18n['sc.location.text.dialogue.count.first'] || 'nemo.sc.location.text.dialogue.count.first'}} <span class="sc-count">0</span> {{=window.i18n['sc.location.text.dialogue.count.last'] || 'nemo.sc.location.text.dialogue.count.last'}}</span>
						<span class="sc-info-label-medium-bold">{{=window.i18n['sc.location.text.dialogue.available'] || 'nemo.sc.location.text.dialogue.available'}}:</span>
					</div>
					<form class="sc-location-finder clearfix">
						<div class="sc-location-finder-input-container">
							<div class="sc-location-finder-input">
								<div class="sc-location-finder-input-field">
									<input id="sc-location-finder-address-input" class="sc-info-label-small" type="text" aria-label="{{=it.placeholderLabel}}" placeholder="{{=it.placeholderLabel}}" maxlength="48" class="sc-info-label-small" value="" autocomplete="off" autocorrect="off">
									<input type="reset" class="sc-location-finder-reset" value="X">
									<div class="sc-location-finder-suggestions"></div>
								</div>
								<button type="button" class="sc-location-finder-geo-location"></button>
								<div class="sc-location-finder-options sc-info-label-small">
									<select id="sc-location-finder-options-distance" class="sc-info-label-small" name="dist" value="-1">
										{{~ it.distances :distance}}
											<option value="{{=distance.value}}" {{?distance.selected && distance.selected === 'true'}}selected{{?}}>{{=distance.label}}</option>
										{{~}}
									</select>
								</div>
							</div>
							<div class="sc-location-finder-button-container">
								<button id="sc-location-finder-submit" class="audi-button sc-j-mas-continue" data-state="inactive"><span class="audi-button__text">{{=window.i18n['sc.location.button.continue'] || 'nemo.sc.location.button.continue'}}</span></button>
							</div>
						</div>
						{{? window.i18n['nemo.location.disclaimer.bordersearch'].trim() !== '' }}
							<p class="sc-lf-search-border-disclaimer audi-copy-s">
								{{=window.i18n['nemo.location.disclaimer.bordersearch']}}
							</p>
						{{?}}
						<div class="sc-location-finder-error sc-hidden">
							<p class="sc-hidden" data-error="default-error">{{=window.i18n['sc.location.msg.defaulterror'] || 'nemo.sc.location.msg.defaulterror'}}</p>
							<p class="sc-hidden" data-error="change-input">{{=window.i18n['sc.location.msg.changeinput'] || 'nemo.sc.location.msg.changeinput'}}</p>
							<p class="sc-hidden" data-error="not-found">{{=window.i18n['sc.location.msg.notfound'] || 'nemo.sc.location.msg.notfound'}}</p>
							<p class="sc-hidden" data-error="no-results">{{=window.i18n['sc.location.msg.noresults'] || 'nemo.sc.location.msg.noresults'}}</p>
						</div>
						<div class="sc-location-finder-result-list"></div>
					</form>
				</div>
			</div>
		</div>
	</div>
`;

export {exports as modalLayerMandatoryAreasSearchTemplate};
