import {vehicleTilesTemplate as TEMPLATE} from './templates/vehicle-tiles';
import {vehicleTilesTemplateNCOPT1 as TEMPLATE_NC_OPT1} from './templates/vehicle-tiles-nc-opt1';
import {vehicleTilesTemplateNCOPT2 as TEMPLATE_NC_OPT2} from './templates/vehicle-tiles-nc-opt2';
import {vehicleTilesTemplateNCOPT3 as TEMPLATE_NC_OPT3} from './templates/vehicle-tiles-nc-opt3';
import {vehicleTilesTemplateNCOPT4 as TEMPLATE_NC_OPT4} from './templates/vehicle-tiles-nc-opt4';
import {vehicleTilesTemplateUCOPT1 as TEMPLATE_UC_OPT1} from './templates/vehicle-tiles-uc-opt1';
import {vehicleTilesTemplateUCOPT2 as TEMPLATE_UC_OPT2} from './templates/vehicle-tiles-uc-opt2';
import {vehicleTilesTemplateUCOPT3 as TEMPLATE_UC_OPT3} from './templates/vehicle-tiles-uc-opt3';
import {vehicleTilesTemplateUCOPT4 as TEMPLATE_UC_OPT4} from './templates/vehicle-tiles-uc-opt4';
import {vehicleTilesTemplateFavoriteEmpty as TEMPLATE_FAVORITES_EMPTY} from './templates/vehicle-tiles-favorites-empty';
import {vehicleTilesTemplateResultsEmpty as TEMPLATE_RESULTS_EMPTY} from './templates/vehicle-tiles-results-empty';


export default class ScViewTilesTemplateLookup {
	/**
	 * get template by layout type
	 * @param {string} layoutType_ - layout type
	 * @returns {string} HTML Template
	 */
	static getTemplate(layoutType_) {
		switch (layoutType_) {
			case 'nc-opt1':
				return TEMPLATE_NC_OPT1;
			case 'nc-opt2':
				return TEMPLATE_NC_OPT2;
			case 'nc-opt3':
				return TEMPLATE_NC_OPT3;
			case 'nc-opt4':
				return TEMPLATE_NC_OPT4;
			case 'uc-opt1':
				return TEMPLATE_UC_OPT1;
			case 'uc-opt2':
				return TEMPLATE_UC_OPT2;
			case 'uc-opt3':
				return TEMPLATE_UC_OPT3;
			case 'uc-opt4':
				return TEMPLATE_UC_OPT4;
			case 'results-empty':
				return TEMPLATE_RESULTS_EMPTY;
			case 'favorites-empty':
				return TEMPLATE_FAVORITES_EMPTY;
			default:
				return TEMPLATE;
		}
	}
}
