const exports = `
	{{? item.url && item.text}}
		{{? item.method === 'POST'}}
			{{? !item.layer }}
				<form method="post" action="{{=item.patternUrl}}" target="{{=item.target}}" class="sc-cta-button-form">
					<button type="submit" class="sc-cta-button {{? item.primary}}audi-button{{??}}audi-button audi-button--ghost{{?}} {{? item.css && item.css !== ''}} {{= item.css}}{{?}}">{{=item.text}}</button>
					<input type="hidden" name="inquiry_json_data" value="{{=item.postData}}" />
				</form>
			{{??}}
				<button id="js-sc-contact-button" class="sc-cta-button {{? item.primary}}audi-button{{??}}audi-button audi-button--ghost{{?}} js-modal-layer-link{{? item.css && item.css !== ''}} {{= item.css}}{{?}}" data-tracking-action="track-contact-click" data-target-type="{{=item.target}}" data-layer-type="IframePostModalLayerElement" data-button-type="{{=item.type}}" data-url="{{=item.url}}" data-pattern-url="{{=item.patternUrl}}">
					<span class="audi-button__text">{{=item.text}}</span>
				</button>
			{{?}}
		{{??}}
			<a id="js-sc-contact-button" x-cq-linkchecker="skip" href="{{=item.url}}" data-pattern="{{=item.patternUrl}}" class="sc-cta-button {{? item.primary}}audi-button{{??}}audi-button audi-button--ghost{{?}}{{? item.layer}} nm-layerLink{{?}}{{? item.css && item.css !== ''}} {{= item.css}}{{?}}" data-tracking-action="track-contact-click" target="{{=item.target}}" data-button-type="{{=item.type}}">
				<span class="audi-button__text sc-hyphenate">{{=item.text}}</span>
			</a>
		{{?}}
	{{?}}
`;
export {exports as contactButtonTemplate};
