import {dom as DOM_UTILS} from 'core-utils';

class ScInfoLayer {

	static get defaults() {
		return {
			_selectorInfoIcon: '.js-sc-info-layer-icon',
			_selectorInfoLayer: '.js-sc-info-layer',
			_selectorInfoLayerContent: '.sc-rate-info-layer-content',
			_selectorInfoLayerContentWrapper: '.sc-rate-info-layer-content-wrapper',
			_selectorInfoLayerCloseButton: '.sc-rate-info-layer-close-button'
		};
	}

	/**
	 * handle layer icon click
	 * @param {Event} event - click event
	 * @return {void}
	 */
	_handleInfoLayerIconClick(event) {
		const id = event.target.getAttribute('data-vehicle-id');
		this._displayHideLayer(id, true);
	}

	/**
	 * handle layer close click
	 * @param {Event} event - click event
	 * @return {void}
	 */
	_handleInfoLayerClose(event) {
		const id = event.target.getAttribute('data-vehicle-id');
		this._displayHideLayer(id, false);
	}

	/**
	 * click outside handler
	 * @param {Event} event - click event
	 * @return {void}
	 */
	_handleClickOutsideInfoLayer(event) {
		const clickedOutside = DOM_UTILS.isElementOutsideOfElementWithSelector(event.target, ScInfoLayer.defaults._selectorInfoLayerContentWrapper);
		if (clickedOutside) {
			const id = event.target.getAttribute('data-vehicle-id');
			this._displayHideLayer(id, false);
		}
	}

	/**
	 * show hide layer
	 * @param {string} id - vehicleId
	 * @param {boolean} toDisplay - show or hide
	 * @return {void}
	 */
	_displayHideLayer(id, toDisplay) {
		const layer = DOM_UTILS.getElement('.js-sc-info-layer[data-vehicle-id="' + id + '"]');
		const layerContentWrapper = DOM_UTILS.getElement(ScInfoLayer.defaults._selectorInfoLayerContentWrapper, layer);
		const layerContent = DOM_UTILS.getElement(ScInfoLayer.defaults._selectorInfoLayerContent, layer);
		const displayValue = !!toDisplay ? 'block' : 'none';
		layer.style.display = displayValue;
		layerContentWrapper.style.display = displayValue;
		layerContent.style.display = displayValue;

		/* we hide the scrollbar of the body for a better outlook, but only as long as the info-layer is open */
		const body = DOM_UTILS.getElement('body');
		if (!!toDisplay) {
			body.classList.add('sc-rate-info-layer-open-hide-body-scrollbar');
		}
		else {
			body.classList.remove('sc-rate-info-layer-open-hide-body-scrollbar');
		}
	}

	/*
	 * add all Event Listener
	 *
	 * @method _addEvents
	 * @return {undefined}
	 */
	_addEvents() {
		this._handleInfoLayerIconClick = this._handleInfoLayerIconClick.bind(this);
		this._handleInfoLayerClose = this._handleInfoLayerClose.bind(this);
		this._handleClickOutsideInfoLayer = this._handleClickOutsideInfoLayer.bind(this);
		const _domEventDelegate = DOM_UTILS.getEventDelegate('body');
		_domEventDelegate.on('click', ScInfoLayer.defaults._selectorInfoIcon, this._handleInfoLayerIconClick);
		_domEventDelegate.on('click', ScInfoLayer.defaults._selectorInfoLayerCloseButton, this._handleInfoLayerClose);
		_domEventDelegate.on('click', ScInfoLayer.defaults._selectorInfoLayer, this._handleClickOutsideInfoLayer);
	}

	/*
	 * remove all Event Listener
	 *
	 * @method _removeEvents
	 * @return {undefined}
	 */
	_removeEvents() {
		const _domEventDelegate = DOM_UTILS.getEventDelegate('body');
		_domEventDelegate.off('click', ScInfoLayer.defaults._selectorInfoIcon, this._handleInfoLayerIconClick);
		_domEventDelegate.off('click', ScInfoLayer.defaults._selectorInfoLayerCloseButton, this._handleInfoLayerClose);
		_domEventDelegate.off('click', ScInfoLayer.defaults._selectorInfoLayer, this._handleClickOutsideInfoLayer);
	}

	/**
	 * private initialize method
	 * @return {void} returns nothing
	 */
	initialize() {
		this._addEvents();
	}

}

const scInfoLayer = new ScInfoLayer();
scInfoLayer.initialize();
export {scInfoLayer, ScInfoLayer};
