const tyreItem = `
	<div class="sc-tyre-item">
		<img class="sc-tyre-item-img" src="{{=tyre.tyreLabelUrl}}" />
		<a class="sc-tyre-item-link audi-link-s" href="{{=tyre.productSheetUrl}}" target="_blank">
			<svg class="audi-link-s__icon">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/documents-small.svg#nm-icon-documents-small"}}></use>
			</svg>
			<span class="audi-link-s__text">{{=window.i18n['sc.tyrelabel.datasheet.label']}}</span>
		</a>
	</div>
`;

const multipleTyreLabelDisclaimer = `
	{{? it.showMultipleTyreLabelDisclaimer}}
		<p class="sc-tyre-layer-disclaimer audi-copy-s">
			<svg class="sc-tyre-layer-disclaimer-info-icon nm-icon-system-info-small">
				<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/system-info-small.svg#nm-icon-system-info-small"}}></use>
			</svg>
			<span>{{=window.i18n['sc.tyrelabel.disclaimer']}}
			</span>
		</p>
	{{?}}
`;

const exports = `
	<h3 class="sc-tyre-layer-headline audi-headline-order-3">{{=window.i18n['sc.tyrelabel.headline']}}</h3>
	<a class="sc-tyre-layer-link audi-link-s audi-link-s--arrow" href="{{=SETUPS.get('eu.tyres.info.path')}}" target="_blank">
		<span class="audi-link-s__text">{{=window.i18n['sc.tyrelabel.info.label']}}</span>
		<svg class="audi-link-s__icon">
			<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
		</svg>
	</a>

	${multipleTyreLabelDisclaimer}

	{{? it.mainTyres && it.mainTyres.length}}
		<div class="sc-tyre-container">
			<h4 class="sc-tyre-container-headline audi-headline-order-3">{{=window.i18n['sc.tyrelabel.maintyres.label']}}</h4>
			{{~ it.mainTyres :tyre }}
				${tyreItem}
			{{~}}
		</div>
	{{?}}

	{{? it.additionalTyres && it.additionalTyres.length}}
		<div class="sc-tyre-container">
			<h4 class="sc-tyre-container-headline audi-headline-order-3">{{=window.i18n['sc.tyrelabel.additionaltyres.label']}}</h4>
			{{~ it.additionalTyres :tyre }}
				${tyreItem}
			{{~}}
		</div>
	{{?}}
`;

export {exports as tyreLabelTemplate};
