export default class FeatureAppCacheKiller extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		const {idSelector} = this.dataset;
		this.fixSrc(idSelector);
		console.info("FeatureAppSrcFixer connected");
	}

	fixSrc(idSelector=""){
		let fas = [];
		if (!idSelector) {
			fas = [this.previousElementSibling];
		}
		else {
			console.info("idSelector", idSelector);
			fas = document.querySelectorAll('[id^="' + idSelector + '"]');
		}
		console.info("FeatureApps to Be Fixed", fas);
		fas.forEach((fa)=>{
			if (fa) {
				let src = fa.getAttribute('src');
				if (src.indexOf('?t=') === -1) {
					console.info("found a FeatureApp to patch with cacheKiller", fa);
					const now = Date.now();
					const timestamp = now - (now % 3600000);
					fa.setAttribute('src', src + '?t=' + timestamp);
				}
			}
		});
	}

	setTimestamp() {
		const now = Date.now();
		return now - (now % 3600000);
	}

}

if (window.customElements.get('sc-fa-cache-killer') === undefined) {
	window.customElements.define('sc-fa-cache-killer', FeatureAppCacheKiller);
}

