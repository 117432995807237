const exports=`
	<div class="sc-car-tile-buttons">
		<a href="#" class="sc-button-favorites js-favorite {{? vehicle.favorite}}active{{?}} audi-copy-s" data-vehicle-id="{{=vehicle.id}}" data-text="{{=window.i18n['sc.result.removefavorite']||'sc.result.removefavorite'}}" data-active-text="{{=window.i18n['sc.result.addfavorite']||'sc.result.addfavorite'}}">
			<svg class="sc-button-favorites-icon nm-icon-system-favorites-small">
				<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get( 'coreci.url.assets')+"/icons/svg/system-favorites-small.svg#nm-icon-system-favorites-small"}}></use>
			</svg>
			<svg class="sc-button-favorites-icon-filled nm-icon-system-favorites-small-filled">
				<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get( 'coreci.url.assets')+"/icons/svg/system-favorites-small-filled.svg#nm-icon-system-favorites-small-filled"}}></use>
			</svg>
			<div class="sc-button-text-container">
				<span class="sc-button-text sc-button-text-addfavorite">
					{{=window.i18n['sc.result.addfavorite']||'sc.result.addfavorite'}}
				</span>
				<span class="sc-button-text sc-button-text-removefavorite">
					{{=window.i18n['sc.result.removefavorite']||'sc.result.removefavorite'}}
				</span>
			</div>
		</a>
		{{? !vehicle.suggestedCar && !SETUPS.get('scopes.hide.compare.cta') }}
			<a href="#" class="sc-button-compare js-add-compare {{? vehicle.compare == false}}active-cmp{{?}} audi-copy-s" data-active-text="{{=window.i18n['sc.result.removecompare']||'sc.result.removecompare'}}" data-text="{{=window.i18n['sc.result.addcompare']||'sc.result.addcompare'}}" data-max-text="{{=window.i18n['sc.result.maxcompare']||'sc.result.maxcompare'}}">
				<svg class="sc-button-compare-icon nm-icon-system-compare-small">
					<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get( 'coreci.url.assets')+"/icons/svg/system-compare-small.svg#nm-icon-system-compare-small"}}></use>
				</svg>
				<svg class="sc-button-compare-icon-filled nm-icon-system-compare-small-filled">
					<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href={{=SETUPS.get( 'coreci.url.assets')+"/icons/svg/system-compare-small-filled.svg#nm-icon-system-compare-small-filled"}}></use>
				</svg>
				<div class="sc-button-text-container">
					<span class="sc-button-text sc-button-text-addcompare">
						{{=window.i18n['sc.result.addcompare']||'sc.result.addcompare'}}
					</span>
					<span class="sc-button-text sc-button-text-removecompare">
						{{=window.i18n['sc.result.removecompare']||'sc.result.removecompare'}}
					</span>
					<span class="sc-button-text sc-button-text-maxcompare">
						{{=window.i18n['sc.result.maxcompare']||'sc.result.maxcompare'}}
					</span>
				</div>
			</a>
		{{?}}
	</div>
`;
export {exports as vehicleTilesButtonTemplate};
