import {dom} from 'core-utils';
import {appEvents} from 'core-application';
import {scPage} from '../../stockcars-utils-bundle';
import SearchAgentError from './search-agent-error';
import UrlSetups from '../filter/scs-url-setups';
import {vehiclesController} from '../vehicles/vehicles-controller';
import {svdModel} from '../filter/svd-model';
import {globalEventEmitter} from '../event-emitter-proxy';
import {SELECTORS, STCK_STORE, StorageAdapter} from '@oneaudi/stck-store';
import {searchAgentState} from './search-agent-state';
import {searchAgentAuthController} from './search-agent-auth-controller';

class ScSearchAgentController {
	constructor() {
		this._globalEventBus = globalEventEmitter;
		this._startPageUrl = '';
		searchAgentState.subscribe(this);
		this._saveSearchStorage = new StorageAdapter('sc-save-search_' + scPage.getMarket() || 'default', StorageAdapter.types.SESSION_STORAGE);
	}

	/**
	 * default selectors used several times
	 * @member {Object} selectors
	 */

	static get defaults() {
		return {
			requestOptions: {
				method: 'GET',
				mode: 'cors',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				cache: 'no-cache',
				credentials: 'include'
			}
		};

	}

	/**
	 * get request Options
	 * @returns {object} return request options including authentication
	 */
	async getRequestOptions() {
		const {authToken} = await searchAgentAuthController.getAuthenticationState().catch(err => {
			console.log(err);
		});
		let requestOptions = Object.assign({}, ScSearchAgentController.defaults.requestOptions);
		requestOptions.headers.Authorization = `Bearer ${authToken}`;
		return requestOptions;
	}

	/**
	 * get XSRF Token
	 * @returns {Promise<*>} responde with https status
	 */
	async searchAgentXsrfToken() {
		const url = UrlSetups.searchAgentXsrfUrl;
		const requestOptions = {
			method: 'GET',
			credentials: 'include'
		};
		const headers = new Headers({
			'Content-Type': 'text/plain'
		});
		requestOptions.headers = headers;
		return this.fetchData(url, requestOptions);
	}

	/**
	 * save Search Agent
	 * @returns {void} returns nothing
	 */
	async saveSearchAgent() {
		const searchAgentApiUrl = UrlSetups.searchAgentApiUrl;
		const requestUrl = await this._getPatchedRequestUrl();
		const postBody = {
			notification: false,
			url: requestUrl
		};
		if (ScSearchAgentController.activeFiltersInUrl(requestUrl)) {
			const requestOptions = await this.getRequestOptions();
			requestOptions.method = 'POST';
			requestOptions.body = JSON.stringify(postBody);
			const xsrfToken = await this.searchAgentXsrfToken();
			if (xsrfToken) {
				requestOptions.headers['X-XSRF-TOKEN'] = xsrfToken;
				return this.fetchData(searchAgentApiUrl, requestOptions);
			}
			else {
				return Promise.reject('X-XSRF Token was not found.');
			}
		}
		else {
			this._pageRedirect();
			return Promise.reject();
		}
	}

	/**
	 * activeFiltersInUrl - check active filters in url
	 * @private
	 * @param {string} url - url
	 * @returns {boolean} returns true || false
	 */
	static activeFiltersInUrl(url) {
		const pattern = /#?filter=\w+/;
		return pattern.test(url);
	}

	/**
	 * updateSearchAgent PUT
	 * @param {string} searchAgentId searchAgentId
	 * @param {object} searchAgentObject searchAgentObject
	 * @returns {Promise<{}>} returns sca response wrapped in promise
	 */
	async updateSearchAgent(searchAgentId, searchAgentObject) {
		const {isAuthenticated} = await searchAgentAuthController.getAuthenticationState().catch(err => {
			console.log(err);
			this.showLoginLayer();
		});
		if (isAuthenticated) {
			const updateUrl = UrlSetups.searchAgentApiUrl + '/' + searchAgentId;
			const requestOptions = await this.getRequestOptions();
			requestOptions.method = 'PUT';
			requestOptions.body = JSON.stringify(searchAgentObject);
			const xsrfToken = await this.searchAgentXsrfToken();
			if (xsrfToken) {
				requestOptions.headers['X-XSRF-TOKEN'] = xsrfToken;
				return this.fetchData(updateUrl, requestOptions);
			}
			else {
				return Promise.reject('X-XSRF Token was not found.');
			}
		}
		else {
			this.showLoginLayer();
		}
		return Promise.reject('Not authenticated');
	}

	/**
	 * deleteSearchAgent DELETE
	 * @param {string} searchAgentId searchAgentId
	 * @return {Promise<number>} returns sca response statusCode wrapped in promise
	 */
	async deleteSearchAgent(searchAgentId) {
		const {isAuthenticated} = await searchAgentAuthController.getAuthenticationState().catch(err => {
			console.log(err);
			this.showLoginLayer();
		});
		if (isAuthenticated) {
			const url = UrlSetups.searchAgentApiUrl;
			const deleteUrl = url + '/' + searchAgentId;
			const requestOptions = await this.getRequestOptions();
			requestOptions.method = 'DELETE';
			const xsrfToken = await this.searchAgentXsrfToken();
			if (xsrfToken) {
				requestOptions.headers['X-XSRF-TOKEN'] = xsrfToken;
				return this.fetchData(deleteUrl, requestOptions);
			}
			else {
				return Promise.reject('X-XSRF Token was not found.');
			}
		}
		else {
			this.showLoginLayer();
		}
		return Promise.reject('Not authenticated');
	}

	/**
	 * getter for all search agents
	 * @returns {Promise} returns response wrapped in promise
	 */
	async getAllSearchAgents() {
		const {isAuthenticated} = await searchAgentAuthController.getAuthenticationState().catch(err => {
			console.log(err);
			this.showLoginLayer();
		});
		if (isAuthenticated) {
			const url = UrlSetups.searchAgentApiUrl;
			const requestOptions = await this.getRequestOptions();
			return this.fetchData(url, requestOptions);
		}
		else {
			this.showLoginLayer();
		}
		return Promise.reject('Not authenticated');
	}

	/**
	 * generic fetch with error handling and parsing
	 * @param {string} url url to fetch data from
	 * @param {object} requestOptions_ request options
	 * @returns {Promise<*>} parsed result or handled error
	 */
	async fetchData(url, requestOptions_ = ScSearchAgentController.defaults.requestOptions) {
		const rawResponse = await fetch(url, requestOptions_);
		return this._handleResponse(rawResponse);
	}

	/**
	 * middleware to preflight response/errors returnung parse response or custom Error
	 * @param {object} rawResponse response object
	 * @returns {Promise<*>} parsed response or custom Error Object
	 */
	async _handleResponse(rawResponse) {
		try {
			const response = await this._preflightResponse(rawResponse);
			return response;
		}
		catch (error) {
			return this._handleErrors(error);
		}
	}

	/**
	 * middleware to check response status and generate custom errors or return parsed data
	 * @param {object} rawResponse_ http response
	 * @returns {Promise<*>} valid status result or error
	 */
	async _preflightResponse(rawResponse_) {
		const status = rawResponse_.status;
		const response = await this._extractResponse(rawResponse_);
		const error = new SearchAgentError();

		switch (status) {
			case 200:
			case 201:
			case 204:
			case 302:
				return response;

			case 400:
				error.status = 400;
				if (response && response.hasOwnProperty('urlInvalid')) {
					error.type = 'url invalid';
				}
				else if (response && response.hasOwnProperty('tooManySearchAgents')) {
					error.type = 'too many search agents';
				}
				else {
					error.type = 'bad request';
				}
				throw error;

			case 401:
				error.status = 401;
				error.type = 'unauthorized';
				throw error;

			case 403:
				error.status = 403;
				error.type = 'forbidden';
				throw error;

			case 404:
				error.status = 404;
				error.type = 'not found';
				throw error;

			default:
				error.status = 900;
				error.type = 'unhandled';
				throw error;
		}
	}

	/**
	 * extract response
	 * @param {object} rawResponse_ response object
	 * @returns {Promise<*>} parsed response or custom Error Object
	 */
	async _extractResponse(rawResponse_) {
		const contentType = rawResponse_.headers.get('content-type');
		if (contentType && contentType.indexOf('application/json') !== -1) {
			const response = await rawResponse_.json().catch(() => {
				return rawResponse_;
			});
			return response;
		}
		else if (contentType && contentType.indexOf('text/plain') !== -1) {
			const response = await rawResponse_.text().catch(() => {
				return rawResponse_;
			});
			return response;
		}
		else {
			return rawResponse_;
		}
	}

	/**
	 * handle response error middleware
	 * @param {Error} error_ custom Error
	 * @returns {Promise<*>} Error
	 */
	async _handleErrors(error_) {
		if (error_ && error_.type === 'unauthorized') {
			return false;
		}
		else {
			this._globalEventBus.emit(appEvents.SC_ERROR, {
				layerType: 'ModalNotificationLayerElement',
				type: 'error',
				errorType: error_.type
			});
		}
		throw error_;
	}

	set startPageUrl(url) {
		this._startPageUrl = url;
	}

	get startPageUrl() {
		return this._startPageUrl || '';
	}

	/**
	 * getter for param string
	 * @returns {String} returns param string with the currently selected filters
	 */
	get paramString() {
		return [...SELECTORS.FILTERS.getSelectedFiltersMap(STCK_STORE.state).values()].join(',');
	}

	/**
	 * getter for save serach agent
	 * @returns {String} returns the audi idsso login url
	 */
	get saveSearchStorageState() {
		return this._saveSearchStorage.readEntry();
	}

	/**
	 * setter for save serach agent
	 * @param {String} value - value
	 */
	set saveSearchStorageState(value) {
		this._saveSearchStorage.writeEntry(value);
	}

	/**
	 * _pageRedirect
	 * @private
	 * @returns {void} returns nothing
	 */
	_pageRedirect() {
		if (location.href.indexOf('.sc_searches.') !== -1) {
			scPage.simulateNmPageOpen(location.pathname);
		}
		else {
			scPage.simulateNmPageOpen(this.startPageUrl);
		}
	}

	/**
	 * showLogin layer from login view
	 * @returns {void}    nothing
	 */
	showLoginLayer() {
		const loginLayer = dom.getElement('.sc-search-agent-login');
		if (loginLayer.setButtonTargetUrl && loginLayer.showLayer) {
			loginLayer.setButtonTargetUrl(location.href);
			loginLayer.showLayer();
		}
		else {
			console.warn('\'sc-search-agent-login\' component is missing');
		}
	}
	/**
	 * getter for patched request url
	 * @return {Promise} request wrapped in a Promise
	 */
	async _getPatchedRequestUrl() {
		let requestString,
			paramsArr = [];
		try {
			await STCK_STORE.waitForStateFromStorePromise({
				conditionFn: (state) => !!SELECTORS.FILTERS.getSelectedFiltersMap(state).size
			});
			const svd = await svdModel.getSvdVersion();
			paramsArr.push('filter=' + this.paramString);
			paramsArr.push('svd=' + svd);
			paramsArr.push('from=' + SELECTORS.UI.getResultIndex(STCK_STORE.state));
			paramsArr.push('size=' + SELECTORS.UI.getResultSize(STCK_STORE.state));
			paramsArr.push('sort=' + vehiclesController.sorting);
			requestString = paramsArr.join('&');
			let url = UrlSetups.filterRequestUrl + '?' + requestString;
			if (url.indexOf('://') === -1) {
				url = location.protocol + url;
			}
			return url;
		}
		catch (err) {
			throw new Error('could not svd Version: ', err);
		}
	}

	update(payload) {
		if (payload && payload.action && payload.action === 'save') {
			this.saveSearchAgent();
		}
	}
}

const searchAgentController = new ScSearchAgentController();
export {searchAgentController, ScSearchAgentController};
