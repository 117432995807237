import {template} from 'core-utils';
import {mediathekInfolayerTemplate} from './mediathek-infolayer-template';
import {content as CONTENT} from 'core-application';
import {svdModel} from "../filter/svd-model";
import RequestController from "../filter/scs-request-controller";
import {vehiclesController} from "../vehicles/vehicles-controller";

class MediatheInfolayerClass {
	static get defaults() {
		return {
			JS_LINK_REGEXP: new RegExp('sc-mediathek-infolayer', 'gi'),
			TYRELABEL_FALLBACK_PR: 'tyrelabel',
			vehicleId: 'data-vehicle-id',
			selectorMediathekLayer: '.sc-detail-equipment'
		};
	}

	/**
	 * content provider call back function
	 * is registered at the content module
	 * @param {string} url_ - requested url
	 * @returns {Promise} async content (HTML String) wrapped in a promise
	 */
	async _contentProviderCallback(url_) {
		let urlParams = this._decodeUrlSegments(url_, MediatheInfolayerClass.defaults.JS_LINK_REGEXP);
		if (!!urlParams && !!urlParams['vehicle-id'] && !!urlParams.prnumber) {
			return this._getMediaData(urlParams['vehicle-id'], urlParams.prnumber);
		}
		else {
			return Promise.reject(new Error('missing contentProvider params'));
		}
	}

	/**
	 * decode params from url segments
	 * @param {string} url_ - url to be examined
	 * @param {RegExp} matcher_ - regExp to mark the start of the url segments
	 * @return {Object} all found params as key value Object
	 */
	_decodeUrlSegments(url_, matcher_) {
		let matched, encodedParams = null,
			segments,
			paramsArr, pkey, pvalue;
		if (!!url_ && matcher_ && typeof matcher_.exec === 'function' && url_.match(matcher_)) {
			matched = matcher_.exec(url_);
			// get url segments after the matching marker
			segments = url_.split(matched[0]);
			// remove leading and/or trailing slashes
			segments = segments[1].replace(/^\/|\/$/g, '');
			paramsArr = segments.split('/');
			// check if tuples of two exist
			if (paramsArr.length && paramsArr.length % 2 === 0) {
				encodedParams = {};
				while (paramsArr.length) {
					pkey = paramsArr.shift();
					pvalue = paramsArr.shift();
					encodedParams[pkey] = pvalue;
				}
			}
		}
		return encodedParams;
	}

	/**
	 * fetch mediadat from scs and return rendered HTML string
	 * @param {string} vehicleId_ - vehicle's id
	 * @param {string} prNumber_ - prNumber for the media item
	 * @return {Promise} htmlString wrapped in a Promise
	 */
	async _getMediaData(vehicleId_, prNumber_) {
		const vehicle = await vehiclesController.loadVehicleById(vehicleId_);
		const mediaData = (prNumber_ === MediatheInfolayerClass.defaults.TYRELABEL_FALLBACK_PR)
			? {}
			: await this._loadMediaData(vehicleId_, prNumber_).catch(err => console.log(err));

		return this._getRenderedData(vehicle, mediaData, vehicleId_, prNumber_);
	}

	/**
	 * getVmsExtension
	 * depending on screen size get the vms url extensions
	 * @param {Number} screenSize_ size of the users screen
	 * @return {string} returns vms extension string
	 */
	_getVmsExtension(screenSize_) {
		let vmsExtension = '_Content270p-Web.mp4';
		if (screenSize_ > 1281) {
			vmsExtension = '_Content1080p-Web.mp4';
		}
		else if (screenSize_ >= 890 && screenSize_ <= 1280) {
			vmsExtension = '_Content720p-Web.mp4';
		}
		else if (screenSize_ >= 705 && screenSize_ <= 889) {
			vmsExtension = '_Content500p-Web.mp4';
		}
		else if (screenSize_ >= 481 && screenSize_ <= 704) {
			vmsExtension = '_Content396p-Web.mp4';
		}
		return vmsExtension;
	}

	/**
	 * getVideo
	 * @param {Object} videoData_ videoData
	 * @returns {Object} videoObj return correct video object
	 */
	_getVideo(videoData_) {
		let videoObj = {};

		videoData_.map(function (video) {
			videoObj[video.key] = video;
		});
		return videoObj['vmrs_video'] || videoObj['big_video_mp4'] || videoObj['video_mp4'] || null;
	}

	/**
	 * getVmsVideoSrc
	 * @param {Object} vmsVideoObj vmsVideoObj
	 * @returns {string} returns vms video src
	 */
	_getVmsVideoSrc(vmsVideoObj) {
		let vmsExtension = this._getVmsExtension(screen.width);
		return vmsVideoObj.baseUrl + vmsVideoObj.videoId + '/' + vmsVideoObj.videoId + vmsExtension;
	}

	/**
	 * getPreparedVideoData
	 * @param {Object} videoData_ video metadata object
	 * @returns {Object} videoData returns video obj
	 */
	_getPreparedVideoData(videoData_) {
		let videoData = {},
			videoObj = this._getVideo(videoData_);

		if (videoObj.key === 'vmrs_video') {
			videoData.src = this._getVmsVideoSrc(videoObj.vms);
		}
		else {
			videoData.src = videoObj.url || '';
		}

		videoData.poster = videoObj.posterUrl || '';

		return videoData;
	}

	/**
	 * get rendered Layer as htmlString
	 *@param {Object} vehicle - data for rendering the media
	 *@param {Object} vehicleMediaData - data for rendering the media
	 *@param {string} vehicleid_ - id for the current vehicle
	 *@param {string} prNumber - prNumber
	 *@return {string} rendered template content
	 */
	_getRenderedData(vehicle, vehicleMediaData, vehicleid_, prNumber) { // eslint-disable-line complexity
		const mediaData = vehicleMediaData.media || {};
		const data = {
			id: vehicleid_ ? vehicleid_ : '',
			image: mediaData.small_hd_image ? mediaData.small_hd_image : '',
			headline: mediaData.ak_headline ? mediaData.ak_headline : '',
			intro: mediaData.intro ? mediaData.intro : '',
			description: mediaData.ak_text ? mediaData.ak_text : '',
			benefits: mediaData.benefits ? mediaData.benefits : '',
			packageContent: vehicleMediaData.packageContent ? vehicleMediaData.packageContent : '',
			video: mediaData.videos ? this._getPreparedVideoData(mediaData.videos) : null,
			mainTyres: [],
			additionalTyres: []
		};

		const {mainTyres, additionalTyres, tyreLabelCount} = this._getTyreLabels(vehicle, prNumber) || {};

		if (mainTyres) {
			data.mainTyres = mainTyres;
		}
		if (additionalTyres) {
			data.additionalTyres = additionalTyres;
		}
		data.showMultipleTyreLabelDisclaimer = tyreLabelCount > 1;

		return template.render(mediathekInfolayerTemplate, data);
	}

	/**
	 * getTyrelabels
	 * @param {Object} vehicleData - data for rendering the media
	 * @param {string} prNumber - prNumber
	 * @returns {object}:tyrelabels
	 */
	_getTyreLabels(vehicleData, prNumber="") {
		const tyreRegEx = /(RAD|REI)/gi;

		let tyreLabelCount = 0;

		if ((!prNumber.match(tyreRegEx) && prNumber!== MediatheInfolayerClass.defaults.TYRELABEL_FALLBACK_PR)|| !Array.isArray(vehicleData.tyreLabels) || !vehicleData.tyreLabels.length) {
			return null;
		}
		const tyreLabels = [...vehicleData.tyreLabels].filter(entry => !!entry.prNumber && (Array.isArray(entry.labels) && entry.labels.length));
		const mainTyres = tyreLabels.reduce((acc, curr) => {
			if (curr.prNumber.match(tyreRegEx)) {
				tyreLabelCount += curr.labels.length;
				return [...acc, ...curr.labels];
			}
			return acc;
		}, []);

		const additionalTyres = tyreLabels.reduce((acc, curr) => {
			if (!curr.prNumber.match(tyreRegEx)) {
				tyreLabelCount += curr.labels.length;
				return [...acc, ...curr.labels];
			}
			return acc;
		}, []);

		return {mainTyres, additionalTyres, tyreLabelCount};
	}

	/**
	 * load vehicle´s complete media data
	 * @param {string} vehicleId_ - vehicle´s id
	 * @param {string} prNumber_ - prNumber
	 * @returns {Promise} mediaResults-object wrapped in a Promise
	 */
	async _loadMediaData(vehicleId_, prNumber_) {
		try {
			const svd = await svdModel.getSvdVersion();
			const result = await RequestController.fetchVehicleMedia(vehicleId_, prNumber_, svd);
			return result;
		}
		catch (err) {
			throw new Error('could not load MediaData \'' + prNumber_ + '\'', err);
		}
	}
}

const scMediathekInfolayer = new MediatheInfolayerClass();
const callBackFn = scMediathekInfolayer._contentProviderCallback.bind(scMediathekInfolayer);
// register content provider callback for 'sc-mediathek-infolayer' links
CONTENT.registerContentProvider({
	regEx: MediatheInfolayerClass.defaults.JS_LINK_REGEXP,
	callbackPromise: callBackFn,
	name: 'sc-mediathek-infolayer'
});

export {scMediathekInfolayer};
